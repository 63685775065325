import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useInventoryStore = defineStore("inventory", {
    state: () => {
        return {
            items: new Map(),
            users: new Set(),
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchUserInventory(userId) {
            this.fetching = true
            try {
                let response = await dangPacksService.inventory.get(userId)
                let inventory = response.data
                for(let item of inventory){
                    this.items.set(item.itemId, item)
                }
                this.users.add(userId)
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async getUserInventory(userId, force_refresh = false) {
            if (!this.users.has(userId) || force_refresh) { await this.fetchUserInventory(userId) }
            return this.items.get(userId)
        },
        async useInventoryItem(itemId, itemType, cardId){
            this.fetching = true
            try {
                await dangPacksService.useInventoryItem(itemId, itemType, cardId)
                let item = this.items.get(itemId)
                item.used = true
                this.items.set(itemId, item)
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async grantInventoryItem(user, itemType, itemValue){
            this.fetching = true
            try {
                await dangPacksService.inventory.post(user, itemType, itemValue)
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        }
    },
    getters: {
        itemsByUserId: (state) => {
            return (userId) => [...state.items.values()].filter(item => item.user_id === userId)
        },
        unusedItemsByUserId: (state) => {
            return (userId) => state.itemsByUserId(userId).filter(item => item.used === false)
        },
    }
});