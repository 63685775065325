<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <SubHeader :header="`Series ${season} Cards`">
        <template #left>
          <Button @click="backToDashboard" icon="pi pi-chevron-left"></Button>
        </template>
        <template #right>
          <Button @click="seasonCardsStore.fetchSeasonCards(season)" icon="pi pi-refresh"></Button>
        </template>
      </SubHeader>
      <hr />
      <p
        v-if="seasonCardsStore.getMaxBlanks(season).length === 0"
      >Sorry, It appears no Series {{ season }} cards have been revealed yet.</p>
      <div class="cardGrid" v-else>
        <div v-for="(card, index) in seasonCardsStore.getMaxBlanks(season)" :key="index">
          <router-link :to="`/season/${card.season}/cards/${card.title}`">
            <DangCard :card="card" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSeasonCardsStore } from "../stores/season_cards";
import { mapStores } from 'pinia'
import Button from 'primevue/button'

import DangCard from "../components/DangCard.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import SubHeader from "../components/SubHeader.vue";

export default {
  name: "SeasonCards",
  components: {
    Button,
    DangCard,
    LoadingIndicator,
    SubHeader
  },
  data() {
    return {
      season: 1,
      loading: true,
    };
  },
  async created() {
    this.season = this.$route.params.seasonId;

    if (!this.seasonCardsStore.seasons.has(this.season)) {
      await this.seasonCardsStore.fetchSeasonCards(this.season)
    }
    this.loading = false
  },
  async mounted() { },
  computed: {
    ...mapStores(useSeasonCardsStore)
  },
  methods: {
    backToDashboard() {
      this.$router.push("/");
    },
  }
};
</script>

<style scoped>
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}
</style>
