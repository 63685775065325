<template>
  <div class="maintenance-wrapper">
    <img src="images/maintenance-fine.gif" />
    <div>Sire, our packs are very sick</div>
    <div>setl hodl plx</div>

    <div>
      <div class="dashboard-option"
        v-if="authStore.isLoggedIn && ['dan', 'admin', 'dev'].includes(authStore.profile.role)">
        <router-link :to="`/admin/controlcentre`">
            <h1 class="overlay-text">Admin Control Centre</h1>
        </router-link>
      </div>
    </div>
    <!-- <img src="images/maintenance-car.png" />
      <div>On the way to the new Factory</div>
      <div>We'll be back up soon!</div> -->
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";

import { mapStores } from 'pinia'
export default {
  name: "Maintenance",
  components: {
  },
  async created() { },
    computed: {
    ...mapStores(useAuthStore),
  },
};
</script>

<style scoped>
.maintenance-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>
