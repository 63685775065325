<template>
  <div class="sleeve-case">
    <img src="../assets/card-cases/sleeve-lc.png" class="sleeve" />
    <DangCardBase :card="card" />
  </div>
</template>
<script>

import DangCardBase from "./DangCardBase.vue";

export default {
  name: "DangCardSleeved",
  components: {
    DangCardBase
  },
  props: {
    card: Object,
  }
};
</script>

<style scoped>
.sleeve-case {
  position: relative;
  height: 430px;
  width: 315px;
  justify-content: center;
  align-items: center;
  display: flex;
  transform-style: preserve-3d;
}
.sleeve {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: translateZ(20px);
}
</style>