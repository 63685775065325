<template>
    <div class="dang-base-card-wrapper">
        <div
            v-if="card.season == 1"
            class="dang-base-card"
            :class="{ 'black-text': card?.rarity === 'gold', 'white-text': card?.rarity !== 'gold' }"
        >
            <div class="card-back"></div>
            <div class="card-front">
                <div class="card-bg-wrapper-s1">
                    <div class="card-bg" :style="{ 'background-image': `url('${cardBackPath}')` }"></div>
                </div>
                <div class="card-bg-wrapper-s1">
                    <div class="card-bg" :style="{ 'background-image': `url('${imagePath}')` }"></div>
                </div>
                <div class="card-template-wrapper" v-if="!isFullArt">
                    <div
                        class="card-template"
                        :style="{ 'background-image': `url('${templatePath}')` }"
                    ></div>
                </div>

                <div v-if="!isFullArt" class="card-title-text">
                    <div
                        v-for="(word,index) in card.title.split(' ')"
                        :key="`${word}-${index}`"
                        class="title-word"
                    >{{ `${word}` }}</div>
                </div>

                <div v-if="!isFullArt" class="card-quote">
                    <div
                        class="card-flavor-text-quote card-flavor-text"
                    >{{ `${card.flavor? `"${card.flavor}"`: ''}` }}</div>
                    <div
                        class="card-flavor-text-author card-flavor-text"
                        v-if="card.author"
                    >{{ `-${card.author}` }}</div>
                </div>

                <div
                    v-if="card.stamped"
                    class="card-wax-stamp"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/stamped.png`)}')` }"
                ></div>

                <div
                    v-if="card.rarity === 'promo'"
                    class="card-signed"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/signed.png`)}')` }"
                ></div>

                <div class="card-footer">
                    <div class="card-footer-text">{{ `${card.minter ? card.minter : ''}` }}</div>
                    <div
                        class="card-footer-text"
                    >{{ `${card.cardnum ? card.cardnum : '0'}/${card.seriesmax}` }}</div>
                </div>
            </div>
        </div>
        <div
            v-if="card.season == 2"
            class="dang-base-card"
            :class="{ 'black-text': card?.rarity === 'gold', 'white-text': card?.rarity !== 'gold' }"
        >
            <div class="card-back"></div>
            <div class="card-front">
                <div
                    class="card-bg-wrapper"
                    :class="{ 'card-bg-padding': card.series !== 'ultra' }"
                >
                    <div class="card-bg" :style="{ 'background-image': `url('${imagePath}')` }"></div>
                </div>
                <div class="card-template-wrapper" v-if="!isFullArt">
                    <div
                        class="card-template"
                        :style="{ 'background-image': `url('${templatePath}')` }"
                    ></div>
                </div>

                <div v-if="!isFullArt" class="card-title-text">
                    <div
                        v-for="(word,index) in card.title.split(' ')"
                        :key="`${word}-${index}`"
                        class="title-word"
                    >{{ `${word}` }}</div>
                </div>

                <div v-if="!isFullArt" class="card-quote">
                    <div
                        class="card-flavor-text-quote card-flavor-text"
                    >{{ `${card.flavor? `"${card.flavor}"`: ''}` }}</div>
                    <div
                        class="card-flavor-text-author card-flavor-text"
                        v-if="card.author"
                    >{{ `-${card.author}` }}</div>
                </div>

                <div
                    v-if="card.stamped"
                    class="card-wax-stamp"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/stamped.png`)}')` }"
                ></div>
                <div
                    v-if="card.rarity === 'promo'"
                    class="card-signed"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/signed.png`)}')` }"
                ></div>

                <div class="card-footer">
                    <div class="card-footer-text">{{ `${card.minter ? card.minter : ''}` }}</div>
                    <div
                        class="card-footer-text"
                    >{{ `${card.cardnum ? card.cardnum : '0'}/${card.seriesmax}` }}</div>
                </div>
            </div>
        </div>
        <div
            v-if="card.season == 2.5"
            class="dang-base-card"
            :class="{ 'black-text': card?.rarity === 'gold', 'white-text': card?.rarity !== 'gold' }"
        >
            <div class="card-back"></div>
            <div class="card-front">
                <div
                    class="card-bg-wrapper"
                    :class="{ 'card-bg-padding': card.series !== 'ultra' }"
                >
                    <div class="card-bg" :style="{ 'background-image': `url('${imagePath}')` }"></div>
                </div>
                <div class="card-template-wrapper" v-if="!isFullArt">
                    <div
                        class="card-template"
                        :style="{ 'background-image': `url('${templatePath}')` }"
                    ></div>
                </div>

                <div v-if="!isFullArt" class="card-title-text">
                    <div
                        v-for="(word,index) in card.title.split(' ')"
                        :key="`${word}-${index}`"
                        class="title-word"
                    >{{ `${word}` }}</div>
                </div>

                <div v-if="!isFullArt" class="card-quote">
                    <div
                        class="card-flavor-text-quote card-flavor-text"
                    >{{ `${card.flavor? `"${card.flavor}"`: ''}` }}</div>
                    <div
                        class="card-flavor-text-author card-flavor-text"
                        :style="checkForColorAuthor(card.author_id)"
                        v-if="card.author"
                    >{{ `-${card.author}` }}</div>
                </div>

                <div
                    v-if="card.stamped"
                    class="card-wax-stamp"
                    :style="{ 'background-image': `url('${getCorrectStamp(card.stamped)}')` }"
                ></div>

                <div
                    v-if="!isFullArt"
                    class="badge-one"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/vsSeriesBadge.png`)}')` }"
                ></div>

                <div
                    v-if="card.rarity === 'promo'"
                    class="card-signed"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/signed.png`)}')` }"
                ></div>

                <div class="card-footer">
                    <div class="card-footer-text">{{ `${card.minter ? card.minter : ''}` }}</div>
                    <div
                        class="card-footer-text"
                    >{{ `${card.cardnum ? card.cardnum : '0'}/${card.seriesmax}` }}</div>
                </div>
            </div>
        </div>
        <div
            v-if="card.season === null"
            class="dang-base-card"
            :class="{ 'black-text': card?.rarity === 'gold', 'white-text': card?.rarity !== 'gold' }"
        >
            <div class="card-back"></div>
            <div class="card-front">
                <div
                    class="card-bg-wrapper"
                    :class="{ 'card-bg-padding': card.series !== 'ultra' }"
                >
                    <div class="card-bg" :style="{ 'background-image': `url(${imagePath})` }"></div>
                </div>
                <div class="card-template-wrapper" v-if="!isFullArt">
                    <div
                        class="card-template"
                        :style="{ 'background-image': `url('${templatePath}')` }"
                    ></div>
                </div>

                <div v-if="!isFullArt" class="card-title-text">
                    <div
                        v-for="(word,index) in card.title.split(' ')"
                        :key="`${word}-${index}`"
                        class="title-word"
                    >{{ `${word}` }}</div>
                </div>

                <div v-if="!isFullArt" class="card-quote">
                    <div
                        class="card-flavor-text-quote card-flavor-text"
                    >{{ `${card.flavor? `"${card.flavor}"`: ''}` }}</div>
                    <div
                        class="card-flavor-text-author card-flavor-text"
                        v-if="card.author"
                    >{{ `-${card.author}` }}</div>
                </div>

                <div
                    v-if="card.stamped !== null"
                    class="card-wax-stamp"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/stamped.png`)}')` }"
                ></div>

                <div
                    v-if="card.rarity === 'promo'"
                    class="card-signed"
                    :style="{ 'background-image': `url('${require(`@/assets/card-misc/signed.png`)}')` }"
                ></div>

                <div class="card-footer">
                    <div class="card-footer-text">{{ `${card.minter ? card.minter : ''}` }}</div>
                    <div
                        class="card-footer-text"
                    >{{ `${card.cardnum ? card.cardnum : '0'}/${card.seriesmax}` }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DangCardBase",
    components: {

    },
    props: {
        card: Object
    },
    data: () => ({    }),
    created() {
    },
    methods: {
    checkForColorAuthor(author) {
        const dan = ["30923466"]
        const devs = ["64860951", "42544020", "53332753"]
        const mods = ["86754190", "135678202", "57045503", "89990192", "23817586", "38358087", "58652271"]
        const vips = ["44814476", "31736255", "39631879", "52328455", "14371185", "232505597", "175400122", "95416766", "487537092", "164284799", "100135110", "437974962", "15414963", "114602053", "81832485", "39029962", "41721716", "644308938", "791737127", "40669301", "39298218", "87803283", "12731745"]
        if(devs.includes(author.toLowerCase())) {
            return "color: #9147ff;"
        } else if (mods.includes(author.toLowerCase())){
            return "color: #00ad03;"
        } else if(vips.includes(author.toLowerCase())){
            return "color: #e005b9;"
        } else if (dan.includes(author.toLowerCase())) {
            return "color: #ffd700;"
        }
    },
    getCorrectStamp(stamp) {
        if(stamp === 'red'){
            return require(`@/assets/card-misc/redStamp.png`)
        } else if (stamp === 'blue') {
            return require(`@/assets/card-misc/blueStamp.png`)
        } else if (stamp === 'gold'){
            return require(`@/assets/card-misc/stamped.png`)
        }
    }
    },
    computed: {
        imagePath() {
            if (this.card.season == 1) { return require(`../assets/season${this.card.season}/${this.card.image}`) }
            else if (this.card.season == 2) { return require(`../assets/season${this.card.season}/${this.card.image}`) }
            else if (this.card.season == 2.5) { return require(`../assets/season${this.card.season}/${this.card.image}`) }
            else if (this.card.season == null) { return this.card.image }
            else { return null }
        },
        templatePath() {
            if (!this.isFullArt) { return require(`@/assets/card-templates/${this.card.rarity}.png`) }
            else { return null }
        },
        cardBackPath(){
            if(this.card.season == 1){ return require(`@/assets/card-backs/${this.card.rarity}.png`)}
            else{return null}
        },
        isFullArt(){
            return this.card.rarity === 'fullart'
        }
    }
};
</script>

<style scoped>

.dang-base-card-wrapper {
    position: relative;
    height: 430px;
    width: 315px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}
.dang-base-card {
    position: relative;
    height: 430px;
    width: 315px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.card-back {
    transform: rotateY(180deg);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 11px 11px 10px 11px;
    background-image: url("../assets/CardBack.png");
    backface-visibility: hidden;
}
.card-front {
    backface-visibility: hidden;
}

.card-bg-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.card-bg-wrapper-s1 {
    position: absolute;
    width: 240px;
    height: 204px;
    top: 75px;
    left: 38px;
}

.card-bg {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card-bg-padding {
    padding: 11px 11px 10px 11px;
}

.card-template-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.card-template {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card-title-text {
    position: absolute;
    top: 48px;
    left: 0px;
    width: 100%;
    font-family: GentiumBookBasic;
    text-transform: uppercase;
    text-align: left;
    padding: 0px 0px 0px 45px;
    text-shadow: 1px 1px black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.title-word {
    font-size: 17px;
    margin-right: 3px;
    /* This hack is required for Firefox - see Keyframe declariation for more details */
    animation: fix 0.00000001s;
}
.title-word:first-letter {
    font-size: 24px;
}

.card-wax-stamp {
    position: absolute;
    top: 38px;
    right: 35px;
    height: 45px;
    width: 45px;
    background-size: cover;
}

.badge-one {
    position: absolute;
    bottom: 125px;
    right: 24px;
    height: 14px;
    width: 14px;
    background-size: cover;
}

.card-quote {
    position: absolute;
    top: 293px;
    left: 0;
    width: 100%;
    height: 96px;
    text-align: center;
    padding: 0px 48px 0 48px;
    line-height: 1.2;
}
.card-flavor-text {
    font-family: "Sriracha";
    font-size: 16px;
}

.card-flavor-text-author {
    line-height: 1;
    font-size: 17px;
}

.card-signed {
    position: absolute;
    bottom: 60px;
    right: 55px;
    height: 40px;
    width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.card-footer {
    position: absolute;
    bottom: 21px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 65px 0px 65px;
}

.card-footer-text {
    font-family: GentiumBookBasic;
    font-size: 14px;
}

.black-text {
    color: black;
}
.white-text {
    color: white;
}

/* This is a very stupid fix for a bug with Firefox's implementation of First Letter sudo selector
https://stackoverflow.com/questions/41425083/css-first-letter-glitch-in-firefox

*/
@keyframes fix {
    from {
        padding-right: 1px;
    }
    to {
        padding-right: 0;
    }
}
</style>

