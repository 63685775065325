import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useCardsStore = defineStore("cards", {
    state: () => {
        return {
            cards: new Map(),
            usersMinted: new Set(),
            usersOwned: new Set(),
            usersAuthored: new Set(),
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchUserCards(userId, type = 'owner') {
            this.fetching = true
            try {
                // Fetch the data
                let response = await dangPacksService.cards.byUser(userId, type)

                if (type === 'owner') {
                    let clearCards = [...this.cards.values()].filter(card => card.owner_id === userId)
                    for (let card of clearCards) {
                        this.cards.delete(card.cardId)
                    }
                }

                let cards = response.data
                for (let card of cards) {
                    this.cards.set(card.cardId, card)
                }

                if (type === 'owner') { this.usersOwned.add(userId) }
                else if (type === 'author') { this.usersAuthored.add(userId) }
                else if (type === 'minter') { this.usersMinted.add(userId) }
            } catch (error) {
                // Set and Log Errors
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async getUserCards(userId, type = 'owner', force_refresh = false) {
            if ((type === 'minter' && !this.usersMinted.has(userId)) ||
                (type === 'owner' && !this.usersOwned.has(userId)) ||
                ((type === 'author' && !this.usersAuthored.has(userId)) ||
                    force_refresh)) { await this.fetchUserCards(userId, type) }
            if (type === 'minter') { return [...this.cards.values()].filter(card => card.minter_id === userId) }
            else if (type === 'owner') { return [...this.cards.values()].filter(card => card.owner_id === userId) }
            else if (type === 'author') { return [...this.cards.values()].filter(card => card.author_id === userId) }
            return []
        },
    },
    getters: {
        ownedCardsByUserId: (state) => {
            return (userId) => [...state.cards.values()].filter(card => card.owner_id === userId).sort((a, b) => b.rarerank - a.rarerank)
        },
        mintedCardsByUserId: (state) => {
            return (userId) => [...state.cards.values()].filter(card => card.minter_id === userId).sort((a, b) => b.rarerank - a.rarerank)
        },
        authoredCardsByUserId: (state) => {
            return (userId) => [...state.cards.values()].filter(card => card.author_id === userId).sort((a, b) => b.rarerank - a.rarerank)
        },
    }
});