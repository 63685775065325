import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useCardMintsStore = defineStore("cardMints", {
    state: () => {
        return {
            cards: new Map(),
            seasons: new Set(),
            cardTitles: new Set(),
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchCardMints(season = 1, cardTitle) {
            this.fetching = true

            try {
                // Fetch the data
                let response = await dangPacksService.getSeasonCardPrints(season, cardTitle)

                // Set the cards in state
                if (!this.cards.has(season)) { this.cards.set(season, new Map()) }
                this.cards.get(season).set(cardTitle, response.data)

                // Push completed season to list
                this.seasons.add(season)
                this.cardTitles.add(cardTitle)
            } catch (error) {
                // Set and Log Errors
                this.error = error
                console.log(error)
            } finally {
                this.fetching = false
            }
        },
    },
    getters: {
        getRaritiesMap(state){
            return (season, cardTitle) => state.cards.get(season).get(cardTitle).reduce((map, print) => {
                if (!map.has(print.rarerank)) {
                    if(print.series=== 'ultra' && print.season === 2){
                        let init_rank = {
                            title: print.rarity,
                            rarerank: print.rarerank,
                            max: 5,
                            found: 0,
                            cards: Array(5).fill(null)
                        }
                        map.set(print.rarerank, init_rank)
                    } else {
                        let init_rank = {
                            title: print.rarity,
                            rarerank: print.rarerank,
                            max: print.seriesmax,
                            found: 0,
                            cards: Array(print.seriesmax).fill(null)
                        }
                        map.set(print.rarerank, init_rank)
                    }
                    
                }

                if(print.series === 'ultra' && print.season === 2){
                    let rank = map.get(print.rarerank)
                    rank.cards[print.cardnum + rank.found - 1] = print
                    rank.found += 1
                    map.set(print.rarerank, rank)
                    return map
                } else {
                    let rank = map.get(print.rarerank)
                    rank.found += 1
                    rank.cards[print.cardnum - 1] = print
                    map.set(print.rarerank, rank)
                    return map
                }

                
            }, new Map())
        },
        getRaritiesArray() {
            return (season, cardTitle) => Array.from(this.getRaritiesMap(season, cardTitle).values())
        },
        getCardPrints(state){
            return (season, cardTitle) => state.cards.get(season).get(cardTitle)
        }
    }
});