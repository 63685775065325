<template>
    <div class="faqContent">
        <div class="embed-youtube">
        <iframe
          width="800"
          height="450"
          src="https://www.youtube-nocookie.com/embed/ntMf5zapZq0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
        <div v-for="qAndA in questionsAndAnswers" :key="qAndA.question" class='qAndA'>
            <div class='question'>{{qAndA.question}}</div>
            <div class='answer'>{{qAndA.answer}}</div>
        </div>
    </div>
</template>

<script>

const questionsAndAnswers = [
    {question: 'What are dangPacks?', answer: 'A unique and fun reward for watching the stream and supporting the community! Dan, along with the dev team, have created a totally new set of digital trading cards that can all be opened and collected on dangpacks.com. Right now you can even sleeve, grade, centerpiece, and trade your cards with other members of the 596. As of 07/23/22 we have opened all packs from season 2 and plan on continuing with a season 3 and beyond with even more [REDACTED].'},
    {question: 'How do I get a dangPack?', answer: 'Packs can be acquired a few different ways.\n\nGifting subs - you will receive one dangPack for each 5 subs gifted at the same time. (ex. 5 gifted subs = 1 pack, 10 gifted subs = 2 packs, etc. and individual gift subs do not count towards them at this time.) \n\nChannel Points aka dangCoin - you can redeem 250,596 channel points for one dangPack.\n\nOccasionally, generous members of the community will choose to redistribute their packs to others or Dan will gift bomb packs to random members in chat - so you always have a chance to get a pack without spending any dangCoin or IRL money!'},
    {question: 'When are packs opened?', answer: 'Packs are typically opened by Dan at the beginning of the live show which starts at 9:30am EDT, Monday through Friday.'},
    {question: 'How can I see my packs/cards/etc.?', answer: 'You can view your card library, grade/sleeve/centerpiece your cards, view others\' cards, and more at dangpacks.com.'},
    {question: 'What is a sleeve?', answer: 'You can add a sleeve to any one of your cards for 25,000 channel points. It will add a protective sleeve with a red D* logo in the bottom right corner.\n(ex. gold Cerso of Yarntown on https://dangpacks.com/users/DanGheesling?view=owned&layout=flat)'},
    {question: 'What is a centerpiece?', answer: 'You can centerpiece any one of your cards for 50,000 channel points. It will add an authentic wood frame with gold name plating.\n(ex. rainbow Cerso the Silent on https://dangpacks.com/users/DanGheesling?view=owned&layout=flat)'},
    {question: 'What does grading my card do?', answer: 'For 100,000 channel points you can have one card graded by Dang Packs Official Grading (DPOG). They will review your card\'s Centering, Surface, Corners, and Edges quality then place the card in a protective casing with its grade. Will you get a 10?\n(ex. rainbow Cerso of Bullet Town on https://dangpacks.com/users/DanGheesling?view=owned&layout=flat)'},
    {question: 'How do I get a sleeve, centerpiece, or grading?', answer: 'You\'ll first need to make sure you have the dangCoin (aka channel points)! Once you have enough (costs listed above), redeem your channel points for the item you want. This can be found just below Twitch chat by clicking on your dangCoin. Once this is redeemed, head on over to dangpacks.com and click on Inventory. Here you will see all of your available items. Click on Use for the item you would like to use and you will be taken to a new page that displays your card collection. Click on the card you would like to apply the item to, then click Yes on the popup to confirm.'},
    {question: 'How do I trade?', answer: 'Go to dangpacks.com and after logging in, click on Trade. Here, you click on Start Trade which will take you to a new screen. Choose the card(s) you would like to offer. If you change your mind, click the card again to remove it from the offer. Enter the person you\'d like to trade with in Search User and click Show Cards. Click their card(s) you\'d like to trade for. When you are done selecting cards, click Trade. This will send a request to the person so they can choose to accept or decline your offer. You can also review your Incoming and Outgoing trades by going to dangpacks.com and clicking Trade. We encourage you to visit the #dang-packs-trading channel in the Discord to talk trades!'},
    {question: 'What does minting mean?', answer: 'Minting a card refers to acquiring a pack of cards. The “minter” is listed at the very bottom of the card and is the original owner of the card.'},
    {question: 'What does authoring a card mean? How do I author a card?', answer: 'Authoring a card means your name is shown as the signer for the quote on the card. Anyone has a chance to author a card by being in chat when the pack is minted.'},
    {question: 'What is a cosmic brownie?', answer: 'A cosmic brownie pack refers to a pack of cards that contains all commons.'},
    {question: 'What is a cosmic rare?', answer: 'A cosmic rare pack refers to a pack of card that contains all rares (silver or higher rarities).'},
    {question: 'What is an airdrop?', answer: 'An airdop is a unique rarity card that can only be obtained by being in chat during special unannounced events.'},
    {question: 'How can I see if I have any unopened packs?', answer: 'After logging in to dangpacks.com, click on Inventory. You will see “Unopened Packs:” in the upper left corner with the number of unopened packs you have remaining.'},
    {question: 'My pack is missing, what do I do?', answer: 'Please be sure to check your unopened packs count (directions above). If the amount still seems incorrect, please make a post in the #missed-packs channel in Discord. If you do not have Discord, send a DM to MelfinaStarwind on Twitch. Make sure to include your Twitch name and as much information as possible - including if the pack was acquired through gifting subs or channel points, how many there should be, and approximate dates of redemption. One of our devs will research and get your issue resolved.'},
    {question: 'My airdrop is missing, what do I do?', answer: "As of 07/21/22, the following airdrops have been released: Pace Picante I, Goldvo, Malenia Queen Of Piece, & Cerso Lord Of No Vig.\n\nFor a few of the original airdops, you had to not only be in chat but also fill out a form with your exact Twitch name and submit it before a timer ran out. If you're missing one of these airdops it's possible the form was not filled out, filled out correctly, or if you hit 'submit' after the timer ran out it would not have gone through.\n\nCurrently, the process for getting an airdrop has been simplified! You will only need to type the special prompt in chat during the event to be eligible to receive the airdrop when it is announced. Keep an eye out for the message from dangpacks_bot in chat to know what the prompt is."},
    {question: 'I changed my Twitch name, and now I can\'t see my old cards. Can I have all of my cards on my new name?', answer: 'Yes! Please post the following in the #missed-packs channel in the Discord:\nOld Twitch Name:\nNew Twitch Name:\nneed to link to new name'},
    {question: 'I think feature, card, website idea, etc. would be cool, can I make a suggestion?', answer: 'Yes! Please post your suggestion in the #packs-suggestions channel in the Discord.'},
    {question: 'Who is the dangPacks dev team?', answer: 'Currently, the core dev team consists of DecoyCodes, catsensei, & th3mcnuggetz. We have a few other dev contributors: ElektrikOnion & thefurmanator and some working on their first contribution: KyleTheScientist, and cderhammer.'},
    {question: 'I\'m a dev or artist, how can I join the team?', answer: 'We\'re always looking for talented folks in the community! Interested developers can DM the dev team for consideration. Artists are encouraged to post in the #community-art channel in the Discord with your art and note if you\'re interested.'},
]

export default {
  name: "FAQ",
  components: {
  },
  data() {
    return {
      questionsAndAnswers: questionsAndAnswers
    };
  },
  async created() { },
};
</script>

<style scoped>
.faqContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.qAndA {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0px;
    width: 60%;
}
.question {
    font-size: 40px;
    border-bottom: 1px solid white;
    width: fit-content;
    text-align: center;
    margin-bottom: 10px;
}
.answer {
    font-size: 24px;
    white-space: pre-line;
}
.embed-youtube {
  padding: 30px;
}
</style>
