import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useUsersStore = defineStore("users", {
    state: () => {
        return {
            usersById: new Map(),
            usersByName: new Map(),
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchUser(user) {
            this.fetching = true
            try {
                let response = await dangPacksService.users.get(user)
                let users = response.data
                for (let user of users) {
                    this.usersById.set(user.id, user)
                    this.usersByName.set(user.display_name.toLowerCase(), user)
                }
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async getUserByName(username, force_refresh = false) {
            if (!this.usersByName.has(username.toLowerCase()) || force_refresh) { await this.fetchUser(username) }
            return this.usersByName.get(username.toLowerCase())
        },
        async getUserById(userId, force_refresh = false) {
            if (!this.usersById.has(userId) || force_refresh) { await this.fetchUser(userId) }
            return this.usersById.get(userId)
        },
    },
    getters: {}
});