import axios from 'axios'

class TwitchService {
  constructor(baseUrl, clientId, callbackUrl) {
    this.baseUrl = baseUrl
    this.clientId = clientId
    this.callbackUrl = callbackUrl
    this.scopes = ['openid', 'user:read:email']
    this.claims = { "id_token": { "email": "", "email_verified": "", "preferred_username": "" }, "userinfo": { "picture": "" } }
  }

  login() {
    const url = `https://id.twitch.tv/oauth2/authorize?client_id=${this.clientId}&redirect_uri=${this.callbackUrl}&response_type=code&scope=${this.scopes.join(' ')}&claims=${JSON.stringify(this.claims)}`;
    window.location = url;
  }

  fetchToken(code) {
    return axios.post(`${this.baseUrl}/auth/getToken`, { code });
  }

  fetchTwitchInfo() {
    return axios.get("https://id.twitch.tv/oauth2/userinfo");
  }

  fetchProfile() {
    return axios.get(`${this.baseUrl}/auth/profile`)
  }

}

const twitchApiBaseUrl = process.env.VUE_APP_TWITCH_API_URL;
const twitchClientId = process.env.VUE_APP_TWITCH_CLIENT_ID
const twitchCallbackUrl = process.env.VUE_APP_TWITCH_CALLBACK_URL
const twitchService = new TwitchService(twitchApiBaseUrl, twitchClientId, twitchCallbackUrl);

export { twitchService, TwitchService }