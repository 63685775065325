<template>
  <div>
    <div class="leaderboards">
      <LeaderboardsSeasonCardsSummary
        :cards="leaderboardsStore.cards.sort((a,b)=>b.season-a.season)"
        :loading="loading.cards"
        class="leaderboard-item"
      />
      <LeaderboardsMintersSummary
        :owners="leaderboardsStore.owners"
        :loading="loading.owners"
        class="leaderboard-item"
      />
      <LeaderboardsAuthorsSummary
        :authors="leaderboardsStore.filteredAuthors"
        :loading="loading.authors"
        class="leaderboard-item"
      />
      <LeaderboardsCosmicBrowniesSummary
        :cosmicBrownies="leaderboardsStore.cosmicBrownies"
        :loading="loading.cosmicBrownies"
        class="leaderboard-item"
      />
      <LeaderboardsCosmicRaresSummary
        :cosmicRares="leaderboardsStore.cosmicRares"
        :loading="loading.cosmicRares"
        class="leaderboard-item"
        v-if="featureFlagsStore.cosmicRare"
      />
      <LeaderboardsCounterfeitersSummary
        :counterfeiters="leaderboardsStore.counterfeiters"
        :loading="loading.counterfeiters"
        class="leaderboard-item"
      />
    </div>
  </div>
</template>

<script>
import { useLeaderboardsStore } from "../stores/leaderboards";
import { useFeatureFlagsStore } from "../stores/feature_flags"
import { mapStores } from 'pinia'

import { dangPacksService } from "../services/DangPacksService";
import LeaderboardsMintersSummary from "../components/LeaderboardsMintersSummary.vue";
import LeaderboardsAuthorsSummary from "../components/LeaderboardsAuthorsSummary.vue";
import LeaderboardsCosmicBrowniesSummary from "../components/LeaderboardsCosmicBrowniesSummary.vue";
import LeaderboardsCosmicRaresSummary from "../components/LeaderboardsCosmicRaresSummary.vue";
import LeaderboardsCounterfeitersSummary from "../components/LeaderboardsCounterfeitersSummary.vue";
import LeaderboardsSeasonCardsSummary from "../components/LeaderboardsSeasonCardsSummary.vue";

export default {
  name: "Dashboard",
  components: {
    LeaderboardsMintersSummary,
    LeaderboardsAuthorsSummary,
    LeaderboardsCosmicBrowniesSummary,
    LeaderboardsCosmicRaresSummary,
    LeaderboardsCounterfeitersSummary,
    LeaderboardsSeasonCardsSummary
  },
  data() {
    return {
      loading: {
        cards: true,
        owners: true,
        authors: true,
        counterfeiters: true,
        cosmicBrownies: true,
        cosmicRares: true,
      },
    };
  },
  async created() {
    this.dangPacksService = dangPacksService;

    this.leaderboardsStore.fetchSeasonCardsSummary().then(() => { this.loading.cards = false; });
    this.leaderboardsStore.fetchSeasonOwnerSummary().then(() => { this.loading.owners = false; });
    this.leaderboardsStore.fetchSeasonAuthorSummary().then(() => { this.loading.authors = false; });
    this.leaderboardsStore.fetchCosmicBrownies().then(() => { this.loading.cosmicBrownies = false; });
    this.leaderboardsStore.fetchCounterfeiters().then(() => { this.loading.counterfeiters = false; });
    
    if(this.featureFlagsStore.cosmicRare){
      this.leaderboardsStore.fetchCosmicRares().then(() => { this.loading.cosmicRares = false; });
    }
    

  },
  computed: {
    ...mapStores(useLeaderboardsStore),
    ...mapStores(useFeatureFlagsStore),
  },
};
</script>

<style scoped>
.leaderboards {
  margin: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(510px, 1fr));
  justify-content: center;
  gap: 50px;
  align-items: baseline;
  padding-bottom: 50px;
}

.leaderboard-item {
  display: grid;
  grid-template-rows: 150px 1fr;
}
</style>
