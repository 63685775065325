<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else class="user-cards">
      <SubHeader :header="`${user.display_name}'s Collection`">
        <template #left>
          <Button @click="backToProfile" icon="pi pi-chevron-left"></Button>
        </template>
        <template #right>
          <Button @click="refreshCards" icon="pi pi-refresh"></Button>
        </template>
      </SubHeader>
      <hr />
      <div class="filters">
        <SelectButton
          v-model="view"
          :modelValue="view"
          :options="viewOptions"
          @change="switchType"
          optionLabel="name"
        />
        <div class="subFilters">
          <OverlayPanel ref="rarityOp">
            <div class="overlayPanel">
              <img
                v-for="option in filterOptions"
                :key="option.id"
                :class="[filter.id === option.id ? 'selected' : '']"
                class="overlaySelection"
                :src="getRarityImage(option.id)"
                @click="updateUrl(option.id)"
                v-tooltip="option.label"
              />
            </div>
          </OverlayPanel>
          <div 
            :class="`rarityFilterButton${filtersDisabled ? ' disabled' : ''}`" 
            @click="toggle" 
            v-tooltip.top="{value: 'Filter By Rarity', disabled: filtersDisabled}"
          >
            <img class="rarityFilterImage" :src="getRarityImage(filter.id)"/>
          </div>
          <MultiSelect
            v-model="seasonFilter"
            :options="seasonFilterOptions"
            optionLabel="season"
            placeholder="Seasons"
            display="chip"
            :showToggleAll="false"
            @change="seasonFilterChanged"
            :disabled="filtersDisabled"
          />
          <span class="p-input-icon-left search">
            <i class="pi pi-search" />
            <InputText 
              class="p-inputtext-lg search" 
              type="text"
              v-model="searchedCard" 
              @blur="updateUrl()" 
              :disabled="filtersDisabled"
            />
          </span>
        </div>
      </div>

      <LoadingIndicator v-if="cardsStore.fetching" />
      <div v-else class="display-area">
        <h2 v-if="view.id !== 'packs'">{{ `(${filteredCards.length} ${filteredCards.length === 1 ? 'card' : 'cards'})` }}</h2>
        <div v-if="filteredCards.length === 0">
          <div>
            It seems you
            {{
              view.id === 'owned' ? `don't own any cards` :
              view.id === 'authored' ? `haven't authored any cards` :
              view.id === 'packs' ? `haven't opened any packs` :
              view.id === 'minted' ? `haven't minted any cards` : 'are hacking'
            }}{{anyFilterSelected ? ' with the selected filters' : ''}}.
            <br />Gift 5 subs or Redeem Channel Points for Packs @
            <a
              href="https://www.twitch.tv/dangheesling"
              target="_blank"
              rel="noopener noreferrer"
            >twitch.tv/dangheesling</a>!
          </div>
        </div>
        <div v-else>
          <UserCardsPacks v-if="view.id === 'packs'" :allPacks="packsStore.packs" :cards="selectedCards.filter(c => c.rarity !== 'promo')" />
          <UserCardsFlat v-else :cards="filteredCards" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCardsStore } from '../stores/cards';
import { useUsersStore } from '../stores/users';
import { mapStores } from 'pinia'

import { getRarityImage, rarityLabels } from "@/services/RarityService";

import UserCardsFlat from "../components/UserCardsFlat.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import SubHeader from "../components/SubHeader.vue";

import SelectButton from "primevue/selectbutton";
import Button from "primevue/button";
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import UserCardsPacks from '../components/UserCardsPacks.vue';
import { usePacksStore } from '../stores/packs';

export default {
  name: "UserCards",
  components: {
    UserCardsFlat,
    SelectButton,
    LoadingIndicator,
    SubHeader,
    Button,
    OverlayPanel,
    MultiSelect,
    InputText,
    UserCardsPacks
},
  data() {
    return {

      user: null,

      viewOptions: [
        { name: "Owned", id: "owned" },
        { name: "Authored", id: "authored" },
        { name: "Minted", id: "minted" },
        { name: "Packs", id: "packs" },
      ],
      view: { name: "Owned", id: "owned" },

      filterOptions: [
        { id: "all", rarity: "All", label: "All" },
        { id: "base", rarity: "Common", label: rarityLabels.base },
        { id: "silver", rarity: "Silver", label: rarityLabels.silver },
        { id: "red", rarity: "Ruby", label: rarityLabels.red },
        { id: "gold", rarity: "Gold", label: rarityLabels.gold },
        { id: "rainbow", rarity: "Rainbow", label: rarityLabels.rainbow },
        { id: "black", rarity: "Black", label: rarityLabels.black },
        { id: "fullart", rarity: "Fullart", label: rarityLabels.fullart },
        { id: "promo", rarity: "Airdrop", label: rarityLabels.promo },
      ],
      filter: { id: "all", rarity: "All" },

      seasonFilter: null,
      seasonFilterOptions: [
        { id: '1', season: "Season 1" },
        { id: '2', season: "Season 2", },
        { id: '2.5', season: "Season 2.5", },
      ],

      searchedCard: null,

      loading: true,
    };
  },
  async created() {
    await this.$router.isReady();

    //Get Username
    this.user = await this.usersStore.getUserByName(this.$route.params.username);

    await Promise.all([
      this.cardsStore.getUserCards(this.user.id, 'owner'),
      this.cardsStore.getUserCards(this.user.id, 'author'),
      this.cardsStore.getUserCards(this.user.id, 'minter'),
      this.packsStore.getUserPacks(this.user.id)
    ])

    const query = this.$route.query;

    // Assign View or Default
    if (query.view) {
      const view = this.viewOptions.find((option) => option.id === query.view);
      if (view) this.view = view;
    }

    // Assign Layout or Default
    if (query.filter) {
      const filter = this.filterOptions.find(
        (option) => option.id === query.filter
      );
      if (filter) this.filter = filter;
    }

    if (query.seasons) {
      const seasonsFromUrl = query.seasons.split(',');
      const selectedSeasons = this.seasonFilterOptions.filter(
        (option) => seasonsFromUrl?.includes(option.id)
      );
      if (selectedSeasons) this.seasonFilter = selectedSeasons;
    }

    if (query.q) {
      this.searchedCard = query.q;
    }

    //run this once to set selected cards
    this.switchType();

    this.loading = false;
  },
  computed: {
    ...mapStores(useUsersStore),
    ...mapStores(useCardsStore),
    ...mapStores(usePacksStore),
    selectedCards: (state) => {
      if (state.view.id === "owned") {
        return state.cardsStore.ownedCardsByUserId(state.user.id)
      } else if (state.view.id === "authored") {
        return state.cardsStore.authoredCardsByUserId(state.user.id)
      } else if (state.view.id === "minted" || state.view.id === "packs") {
        return state.cardsStore.mintedCardsByUserId(state.user.id)
      }
    },
    filteredCards: (state) => {
      let filteredCards = state.selectedCards;
      if (state.filter.id !== "all") {
        filteredCards = filteredCards
          .filter(card => (card.rarity === state.filter.id) || state.filter.id === 'all');
      }
      if (state.seasonFilter) {
        filteredCards = filteredCards
          .filter(card => state.seasonFilter.some(season => season.id === card.season));
      }
      if (state.searchedCard) {
        const inputSanitized = state.searchedCard.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        filteredCards = filteredCards
          .filter(card => card.title.match(RegExp(inputSanitized, 'i')));
      }
      if (state.view.id === 'packs') {
        filteredCards = filteredCards.filter(c => c.rarity !== 'promo');
      }
      return filteredCards;
    },
    anyFilterSelected: (state) => {
      return state.seasonFilter?.length > 0 || state.filter.id !== 'all';
    },
    filtersDisabled: (state) => {
      return state.view.id === 'packs';
    }
  },
  methods: {
    toggle(event) {
      if (!this.filtersDisabled) this.$refs.rarityOp.toggle(event);
    },
    switchType() {
      this.updateUrl();
    },
    updateUrl(filterId = null) {
      const view = this.view.id;
      const filter = filterId || this.filter.id;
      const seasons = this.seasonFilter?.map(season => season.id).join(',') ?? undefined;
      const q = this.searchedCard ?? undefined;

      if (view === 'packs') {
        this.setPacksFilter();
        return;
      }

      this.$router.replace({
        query: {
          ...this.$route.query,
          view,
          filter,
          seasons,
          q,
        }
      })
    },
    setPacksFilter() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          view: 'packs',
        }
      });
    },
    seasonFilterChanged(event) {
      this.seasonFilter = event.value;
      this.updateUrl();
    },
    backToProfile() {
      this.$router.push(`/users/${this.user.display_name}`)
    },
    async refreshCards() {
      if (this.view.id === "owned") {
        await this.cardsStore.fetchUserCards(this.user.id, 'owner')
        this.selectedCards = this.cardsStore.ownedCardsByUserId(this.user.id)

      } else if (this.view.id === "authored") {
        await this.cardsStore.fetchUserCards(this.user.id, 'author')
        this.selectedCards = this.cardsStore.authoredCardsByUserId(this.user.id)

      } else if (this.view.id === "minted") {
        await this.cardsStore.fetchUserCards(this.user.id, 'minter')
        this.selectedCards = this.cardsStore.mintedCardsByUserId(this.user.id)

      }
    },
    getRarityImage,
  }
};
</script>

<style scoped>
.user-cards {
  height: 100%;
}

.filters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 75px;
}

.display-area {
  height: 100%;
}

.overlayPanel {
  display: flex;
  width: 240px;
  flex-wrap: wrap;
}

.overlaySelection {
  width: 60px;
  margin: 10px;
  padding: 10px;
}

.rarityFilterImage {
  width: 40px;
}

.overlaySelection:hover {
  background-color: #b052c0;
  cursor: pointer;
}

.rarityFilterButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.rarityFilterButton:hover {
  background-color: #b052c0;
  cursor: pointer;
}
  
.rarityFilterButton.disabled {
  cursor: unset;
  opacity: 0.5;
}

.rarityFilterButton.disabled:hover {
  background-color: unset;
}

.subFilters {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.search {
  max-width: 150px;
}
</style>
