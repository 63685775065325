import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const usePacksStore = defineStore("packs", {
    state: () => {
        return {
            packs: new Map(),
            users: new Set(),
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchUserPacks(userId) {
            this.fetching = true
            try {
                let response = await dangPacksService.packs.forUser(userId);
                let packs = response.data;
                for (let pack of packs) {
                    this.packs.set(pack.packId, pack)
                }
                this.users.add(userId)
            } catch (error) {
                this.error = error
                console.log(error)
            } finally {
                this.fetching = false
            }
        },
        async getUserPacks(userId, force_refresh = false) {
            if (!this.users.has(userId) || force_refresh) { await this.fetchUserPacks(userId) }
            return [...this.packs.values()].filter(pack => pack.minter_id === userId)
        },
    },
    getters: {
        packsByUserId: (state) => {
            return (userId) => [...state.packs.values()].filter(pack => pack.minter_id === userId)
        },
        sealedPacksByUserId: (state) => {
            return (userId) => state.packsByUserId(userId).filter(pack => pack.open === 0)
        },
    }
});