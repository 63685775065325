<template>
  <Button
    icon="pi pi-comment"
    label="Messages"
    :badge="newMessages > 0 ? newMessages.toString() : null"
    class="p-button-outlined p-button-primary"
    badgeClass="p-badge-danger"
    @click="toggle"
  />
  <OverlayPanel ref="op">
    <div class="message-header">
      <Button
        @click="toggle"
        icon="pi pi-times"
        class="p-button-rounded p-button-outlined"
      ></Button>
      <h1>Messages</h1>
      <Button
        @click="refresh"
        icon="pi pi-refresh"
        class="p-button-rounded p-button-outlined"
      ></Button>
    </div>
    <div class="message-tray loading" v-if="loading || authStore.fetching">
      <LoadingIndicator />
    </div>
    <div class="message-tray" v-else>
      <div class="message help" v-if="showHelpMessage">Happy trading!</div>
      <div class="message help" v-if="showHelpMessage">
        Please keep in mind <strong>anyone can view these messages</strong>, not
        just those involved in the trade.
      </div>
      <div class="message help" v-if="showHelpMessage">
        Welcome to trade messages! Please remember <strong>!goals</strong>, and
        to treat each other respectfully! =)
      </div>
      <TransitionGroup name="list" tag="div">
        <div
          :class="`message-container ${
            message.from === from?.id ? 'from' : 'to'
          }`"
          v-for="(message, index) in messages"
          :key="message.date"
        >
          <i v-if="message.pending" class="pi pi-spin pi-spinner" />
          <Button
            v-if="message.error"
            icon="pi pi-times-circle"
            class="p-button-rounded p-button-danger p-button-text error-button"
            v-tooltip="'Failed to send message. Click to retry.'"
            @click="resendMessage(index)"
          />
          <div
            :class="`message ${message.from === from?.id ? 'from' : 'to'}`"
            v-tooltip.top="getDateString(message.date)"
          >
            {{ message.message }}
          </div>
          <Avatar
            :image="message.from === from?.id ? from.image : to.image"
            shape="circle"
          />
        </div>
      </TransitionGroup>
    </div>
    <div class="message-send">
      <div
        class="message-send-input"
        v-tooltip.top="{
          value: authStore.profile
            ? readOnly
              ? 'Trade has been completed, please open a new one to send messages.'
              : 'Only users involved in the trade can send messages.'
            : 'You must be logged in to send messages.',
          disabled: canSendMessage,
        }"
      >
        <InputText
          class="message-input"
          v-model="message"
          placeholder="Type your message here..."
          :disabled="!canSendMessage"
          maxlength="500"
          @keyup.enter="sendMessage"
        />
      </div>
      <div class="message-send-button">
        <Button
          icon="pi pi-send"
          class="p-button-rounded p-button-secondary"
          :disabled="!canSendMessage || !message"
          @click="sendMessage"
        />
      </div>
    </div>
  </OverlayPanel>
</template>

<script>
import { mapStores } from "pinia";

import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";

import { useAuthStore } from "@/stores/auth";
import LoadingIndicator from "./LoadingIndicator.vue";

export default {
  name: "Chat",
  components: {
    Button,
    OverlayPanel,
    InputText,
    Avatar,
    LoadingIndicator,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    from: {
      type: Object,
    },
    to: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    chatId: {
      type: Number,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-message", "on-resend", "refresh"],
  async created() {
    const readChatsRaw = localStorage.getItem("readChats");
    const readChats = readChatsRaw ? JSON.parse(readChatsRaw) : {};
    const lastReadThisChat = readChats[this.chatId];

    const theirMessages = this.messages?.filter(
      (m) => m.from !== this.authStore.profile?.id
    );

    if (!lastReadThisChat) {
      this.newMessages = theirMessages?.length ?? 0;
      return;
    }

    this.newMessages =
      theirMessages?.filter((m) => m.date > lastReadThisChat).length ?? 0;
  },
  data() {
    return {
      newMessages: 0,
      message: "",
    };
  },
  computed: {
    canSendMessage() {
      if (this.readOnly) return false;
      if (!this.authStore.profile) return false;
      return (
        this.authStore.profile.id === this.to?.id ||
        this.authStore.profile.id === this.from?.id
      );
    },
    showHelpMessage() {
      if (!this.authStore.profile) return false;
      if (this.loading) return false;

      const userHasSentMessage = this.messages?.some((message) => {
        return message.from === this.authStore.profile?.id;
      });

      return !userHasSentMessage;
    },
    ...mapStores(useAuthStore),
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);

      if (!this.chatId) return;

      const readChatsRaw = localStorage.getItem("readChats");
      const readChats = readChatsRaw ? JSON.parse(readChatsRaw) : {};

      const lastReadThisChat = new Date();
      readChats[this.chatId] = lastReadThisChat;
      localStorage.setItem("readChats", JSON.stringify(readChats));

      this.newMessages =
        this.messages?.filter((m) => m.date > lastReadThisChat).length ?? 0;
    },
    sendMessage() {
      if (!this.message) return;
      this.$emit("on-message", this.message);
      this.message = "";
    },
    resendMessage(messageIndex) {
      this.$emit("on-resend", messageIndex);
    },
    refresh() {
      this.$emit("refresh");
    },
    getDateString(rawDate) {
      const currentDate = new Date();
      const date = new Date(rawDate);
      const isSameDate = date.getDate() === currentDate.getDate();
      const isSameYear = date.getFullYear() === currentDate.getFullYear();
      return date.toLocaleString(undefined, {
        day: isSameDate ? undefined : "numeric",
        month: isSameDate ? undefined : "short",
        year: isSameYear ? undefined : "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.message-header {
  display: flex;
  justify-content: space-between;
}
.message-tray {
  height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  width: 350px;
  margin: 15px -5px 15px 0;
  padding-right: 5px;
}

.message-tray::-webkit-scrollbar {
  width: 5px;
}

.message-tray::-webkit-scrollbar-track {
  background-color: transparent;
}

.message-tray::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
  width: 5px;
  background-clip: content-box;
}

.message-send {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-send-input {
  flex: 1;
}

.message-input {
  width: 100%;
  height: 100%;
}

.message-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.message-container.from {
  flex-direction: row-reverse;
}

.message-container.to {
  flex-direction: row;
  right: calc(1rem + 5px);
}

.message {
  border-radius: 20px;
  max-width: 80%;
  width: max-content;
  height: max-content;
  padding: 5px 15px;
  margin: 5px;
  text-align: left;
  font-size: 1.2rem;
  overflow-wrap: break-word;
  word-break: break-word;
}

.message.from {
  background-color: #81d4fa;
  color: #121212;
}

.message.to {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.message.help {
  font-size: 1rem;
  max-width: 95%;
  font-style: italic;
}

.message.help:last-of-type {
  margin-top: 20px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.list-leave-active {
  position: absolute;
}

.button {
  overflow: visible;
}
</style>
