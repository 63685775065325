export function getRarityImage(rarity) {
    switch (rarity?.toLowerCase()) {
        case "all":
            return require("@/assets/card-templates/all.png");
        case "base":
            return require("@/assets/card-templates/base.png");
        case "silver":
            return require("@/assets/card-templates/silver.png");
        case "red":
            return require("@/assets/card-templates/red.png");
        case "gold":
            return require("@/assets/card-templates/gold.png");
        case "rainbow":
            return require("@/assets/card-templates/rainbow.png");
        case "black":
            return require("@/assets/card-templates/black.png");
        case "promo":
            return require("@/assets/card-templates/promo.png");
        case "fullart":
            return require("@/assets/card-templates/fullart.png");
    }
}

export const rarityLabels = {
    base: "Common",
    silver: "Silver",
    red: "Ruby",
    gold: "Gold",
    rainbow: "Rainbow",
    black: "Black",
    promo: "Promo",
    fullart: "Full Art",
};
