import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useNotificationsStore = defineStore("notifications", {
    state: () => {
        return {
            notifications: [],
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchUserNotifications(userId, read) {
            this.fetching = true
            try {
                let response = await dangPacksService.notifications.forUser(userId, read)

                let newNotifications = response.data.filter(notification => !this.ids.includes(notification.id))
                this.notifications = newNotifications.concat(this.notifications)

                return newNotifications
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async readAll() {
            try {
                this.notifications.forEach(notification => notification.read = true)
                await dangPacksService.notifications.deleteAll()
            } catch (error) {
                console.log(error)
                this.error = error

            }
        },
        async readOne(notificationId) {
            try {
                let notificationIndex = this.notifications.findIndex(notification => notification.id === notificationId)
                this.notifications[notificationIndex].read = true
                await dangPacksService.notifications.deleteById(this.notifications[notificationIndex].id)
            } catch (error) {
                console.log(error)
                this.error = error
            }
        },
    },
    getters: {
        unread: (state) => state.notifications.filter(notification => !notification.read),
        read: (state) => state.notifications.filter(notification => notification.read),
        ids: (state) => state.notifications.map(notification => notification.id),

    }
});