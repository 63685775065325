<template>
  <div class="app">
    <Toast />
    <Toast
      position="top-center"
      group="notifications"
      class="achievement-toast"
      v-if="$route.name !== 'adminPackOpening'"
    >
      <template #message="slotProps">
        <div
          style="display:flex; flex-direction:column; gap:10px; width: 100%;"
          v-if="slotProps.message.type === 'achievement'"
        >
          <h3 style="margin:0;">{{ slotProps.message.summary }}</h3>
          <div class="achievement-layout">
            <img :src="`/images/achievements/${slotProps.message.image}.png`" class="achievement-image" />
            <div>
              <h4 style="margin:0;">{{ slotProps.message.detail }}</h4>
            </div>
          </div>
        </div>
        <div
          style="display:flex; flex-direction:column; gap:10px; width: 100%;"
          v-if="slotProps.message.type === 'trade' || slotProps.message.type === 'tradeMessage'"
        >
          <h3 style="margin:0;">{{ slotProps.message.summary }}</h3>
          <div class="achievement-layout">
            <div>
              <h4 style="margin:0;">{{ slotProps.message.detail }}</h4>
            </div>
          </div>
        </div>
      </template>
    </Toast>

    <Layout>
      <router-view class="fill-page" :key="$route.fullPath" />
    </Layout>
  </div>
</template>

<script>
import { useAuthStore } from "./stores/auth";
import { useNotificationsStore } from "./stores/notifications";
import { useFeatureFlagsStore } from "./stores/feature_flags";
import { mapStores } from 'pinia'

import Toast from 'primevue/toast';

import Layout from "@/components/Layout.vue";
import { usePreferencesStore } from './stores/preferences';
import { useAchievementsStore } from './stores/achievements';
import { useTradesStore } from './stores/trades';
import { useCardsStore } from './stores/cards';

export default {
  name: "app",
  components: {
    Toast,
    Layout,
  },
  async created() {
    await this.initAuthFlow()
    this.preferencesStore.setup()
  },
  data() {
    return {};
  },
  methods: {
    async initAuthFlow() {
      //Auth Flow
      await this.$router.isReady();

      // Grab Code if exists
      let code = this.$route.query?.code

      // Clean up any url noise created by logging in
      this.$router.replace({
        query: {
          ...this.$route.query,
          code: undefined,
          scope: undefined,
        },
      })

      if (code) {
        this.redirectToLoginFrom()
        await this.authStore.fetchToken(code)
        await this.authStore.fetchProfile()
        this.$toast.add({ severity: 'success', summary: 'Success', detail: `${this.authStore.profile.display_name} Logged In!`, life: 3000 });
        this.notificationsStore.fetchUserNotifications(this.authStore.profile.id, false)
        this.startPoll()
      }
    },
    startPoll() { // move to vue and trigger notifications?:
      setInterval(this.fetchAndToast.bind(this), 1000 * 30);
    },
    async fetchAndToast() {
      let newNotifications = await this.notificationsStore.fetchUserNotifications(this.authStore.profile.id, false)
      if(!newNotifications){return;}
      const newNotificationTypes = new Set(newNotifications?.map(n => n.type) ?? [])

      if (newNotificationTypes.has('achievement')) {
        this.preferencesStore.playSound('/audio/trophy-sound.mp3')
        this.achievementsStore.fetchUserAchievements(this.authStore.profile.id)
      } else if (newNotificationTypes.has('trade') || newNotificationTypes.has('tradeMessage')) {
        this.preferencesStore.playSound('/audio/trophy-sound.mp3')
        this.tradesStore.fetchUserTrades(this.authStore.profile.id)
        this.cardsStore.fetchUserCards(this.authStore.profile.id)
      } else if (newNotifications?.length > 0) {
        this.preferencesStore.playSound('/audio/notification-sound.wav', .8)
      }

      for (let notification of newNotifications) {
        if (notification.type === 'tradeMessage') {
          const fromUser = this.authStore.profile.id === notification.data.from ? notification.data.to_display_name : notification.data.from_display_name
          this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'New trade message!', detail: `${fromUser} sent you a message!` });
        } else if (notification.type === 'achievement') {
          this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'Achievement Earned!', detail: notification.data.title, image: notification.data.image });
        } else if (notification.type === 'trade') {
          if (notification.data.to === this.authStore.profile.id && notification.data.status === 'pending') {
            this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'New Trade Request!', detail: `Incoming trade request from ${notification.data.from_display_name}!` });
          } else if (notification.data.to === this.authStore.profile.id && notification.data.status === 'withdrawn') {
            this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'Trade offer Withdrawn!', detail: `Trade request from ${notification.data.from_display_name} was withdrawn!` });
          } else if (notification.data.from === this.authStore.profile.id && notification.data.status === 'declined') {
            this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'Trade offer Declined!', detail: `Trade request to ${notification.data.to_display_name} was declined!` });
          } else if (notification.data.from === this.authStore.profile.id && notification.data.status === 'canceled') {
            this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'Trade offer Canceled!', detail: `Trade request with ${notification.data.to_display_name} was canceled!` });
          } else if (notification.data.to === this.authStore.profile.id && notification.data.status === 'canceled') {
            this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'Trade offer Canceled!', detail: `Trade request with ${notification.data.from_display_name} was canceled!` });
          } else if (notification.data.from === this.authStore.profile.id && notification.data.status === 'accepted') {
            this.$toast.add({ severity: 'success', type: notification.type, group: 'notifications', life: 3000, summary: 'Trade offer Accepted!', detail: `Trade request to ${notification.data.to_display_name} was accepted!` });
          }
        }
      }
    },
    redirectToLoginFrom(){
      if("loginFrom" in localStorage){
        let loginFrom = localStorage.loginFrom
        delete localStorage.loginFrom
        this.$router.replace({path:loginFrom })
      }
    }
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useNotificationsStore),
    ...mapStores(useFeatureFlagsStore),
    ...mapStores(usePreferencesStore),
    ...mapStores(useAchievementsStore),
    ...mapStores(useTradesStore),
    ...mapStores(useCardsStore),

  },
};
</script>

<style>
@import url("./assets/base.css");
</style>
