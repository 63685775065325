<template>
    <div class="loading-indicator">
        <img v-if="image === 'dance'" src="/emotes/dangdance.gif" />
        <img v-if="image === 'dangg'" src="/emotes/dangg.webp" />
        <i v-if="image === 'spinner'" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>

        <img v-else src="/emotes/dangdance.gif" />
    </div>
</template>

<script>
export default {
      name: "LoadingIndicator",
      props:{
          image:String
      }
}
</script>

<style scoped>
.loading-indicator {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
</style>