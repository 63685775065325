<template>
  <LoadingIndicator v-if="loading" />
  <div class="trade" v-else>
    <div>
      <h1>Status: {{ trade.status }}</h1>
    </div>
    <div class="tradingBlock">
      <div class="cardBlock">
        <div class="cardPalet">
          <div class="detailsButton"><CardSummary :cards="trade.offer" icon="pi-info-circle" tooltip="Trade summary" /></div>
          <div v-if="trade.offer.length !== 0" class="card-line">
            <div v-for="card in trade.offer" :key="card.cardId" class="card-container">
              <div class="card">
                <DangCard :card="card" :showActions="false" />
              </div>
              <DuplicateIndicator :duplicates="getDuplicateRequestorCards(card)"  v-if="isPending" />
            </div>
          </div>
          <div v-else class="noCards">For Free!</div>
          <Button 
            class="p-button-rounded p-button-text duplicate-settings-button"
            icon="pi pi-cog"
            @click="(e) => $refs.fromDuplicateSettingsOP.toggle(e)"
            v-if="isPending"
          />
          <OverlayPanel ref="fromDuplicateSettingsOP">
            <h5 class="settings-label">Show duplicates for:</h5>
            <SelectButton v-model="fromSelectedDuplicatesUser" :options="[trade.from_display_name, trade.to_display_name]" />
          </OverlayPanel>
        </div>
        <Button
          class="user-button p-button-link"
          :label="trade.from_display_name"
          @click="() => $router.push(`/users/${trade.from_display_name}`)"
        />
        <Button
          class="p-button-info show-cards-button" 
          @click="() => showCards(selectedUserIndex === 0 ? null : 0)"
        >
          {{selectedUserIndex === 0 ? 'Hide' : 'Show'}} Cards
        </Button>
      </div>

      <div class="cardBlock">
        <div class="cardPalet">
          <div class="detailsButton"><CardSummary :cards="trade.request" icon="pi-info-circle" tooltip="Trade summary" /></div>
          <div v-if="trade.request.length !== 0" class="card-line">
            <div v-for="card in trade.request" :key="card.cardId" class="card-container">
              <div class="card">
                <DangCard :card="card" :showActions="false" />
              </div>
              <DuplicateIndicator :duplicates="getDuplicateReceiverCards(card)" v-if="isPending" />
            </div>
          </div>
          <div v-else class="noCards">For Free!</div>
          <Button 
            class="p-button-rounded p-button-text duplicate-settings-button"
            icon="pi pi-cog" 
            @click="(e) => $refs.toDuplicateSettingsOP.toggle(e)"
            v-if="isPending"
          />
          <OverlayPanel ref="toDuplicateSettingsOP">
            <h5 class="settings-label">Show duplicates for:</h5>
            <SelectButton v-model="toSelectedDuplicatesUser" :options="[trade.from_display_name, trade.to_display_name]" />
          </OverlayPanel>
        </div>
        <Button
          class="user-button p-button-link"
          :label="trade.to_display_name"
          @click="() => $router.push(`/users/${trade.to_display_name}`)"
        />
        <Button 
          class="p-button-info show-cards-button" 
          @click="() => showCards(selectedUserIndex === 1 ? null : 1)"
        >
          {{selectedUserIndex === 1 ? 'Hide' : 'Show'}} Cards
        </Button>
      </div>
    </div>

    <div class="buttons">
      <div v-if="isPending && isFrom" class="action-btns">
        <Button  @click="completeTrade('withdrawn')" class="p-button-danger">Withdraw</Button>
      </div>
      <div v-else-if="isPending && isTo" class="action-btns">
        <Button @click="completeTrade('accepted')" class="p-button-danger">Accept</Button>
        <Button @click="completeTrade('declined')" class="p-button-warning">Decline</Button>
      </div>

      <Chat 
        v-if="(isPending || trade.messages?.length > 0) && configStore.getConfig('ff_trade_messages', true)"
        :messages="trade.messages"
        :to="toUser"
        :from="fromUser"
        :loading="messagesLoading"
        :chatId="trade.tradeId"
        :readOnly="!isPending"
        @on-message="sendMessage"
        @on-resend="resendMessage"
        @refresh="refreshTrade"
      />
    </div>
    
    <div class="cards" v-if="selectedUserIndex !== null">
      <div class="cardsTitle">{{ selectedUserIndex === 0 ? trade.from_display_name : trade.to_display_name }}'s Cards</div>
      <LoadingIndicator v-if="cardsLoading" />
      <UserCardsFlat :cards="selectedUserIndex === 0 ? requesterCards : receiverCards" v-else />
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import Button from "primevue/button";
import SelectButton from 'primevue/selectbutton';
import OverlayPanel from 'primevue/overlaypanel';

import { useAuthStore } from "@/stores/auth";
import { useUsersStore } from '@/stores/users';
import { useTradesStore } from "@/stores/trades";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import DangCard from "@/components/DangCard.vue";
import DuplicateIndicator from "@/components/DuplicateIndicator.vue";
import CardSummary from "@/components/CardSummary.vue";
import { useCardsStore } from '../../stores/cards';
import UserCardsFlat from '@/components/UserCardsFlat.vue';
import Chat from '../../components/Chat.vue';
import { useConfigStore } from '../../stores/config';

export default {
  name: "ReviewTrade",
  components: {
    DangCard,
    Button,
    SelectButton,
    OverlayPanel,
    LoadingIndicator,
    CardSummary,
    DuplicateIndicator,
    UserCardsFlat,
    Chat
},
  data: () => ({
    user: null,
    trade: null,
    isPending: false,
    loading: true,
    requesterCards: [],
    receiverCards: [],
    cardsLoading: false,
    selectedUserIndex: null,
    toSelectedDuplicatesUser: null,
    fromSelectedDuplicatesUser: null,
    messagesLoading: false,
  }),
  async created() {
    await this.$router.isReady();

    const [user, trade] = await Promise.all([
      this.usersStore.getUserByName(this.$route.params.username),
      this.tradesStore.getTrade(parseInt(this.$route.params.tradeId)),
    ]);

    this.user = user;
    this.trade = trade;

    this.isPending = this.trade.status === 'pending'
    this.toSelectedDuplicatesUser = this.trade.to_display_name
    this.fromSelectedDuplicatesUser = this.trade.from_display_name

    if (this.isPending) {
      [this.requesterCards, this.receiverCards] = await Promise.all([
        this.cardsStore.getUserCards(this.trade.from_id, 'owner', true),
        this.cardsStore.getUserCards(this.trade.to_id, 'owner', true),
      ]);
    }
    
    this.loading = false
  },
  computed: {
    ...mapStores(useConfigStore),
    ...mapStores(useAuthStore),
    ...mapStores(useUsersStore),
    ...mapStores(useTradesStore),
    ...mapStores(useCardsStore),

    // switch chat perspectives if the logged-in user is the one who initiated the trade
    toUser() {
      if (this.authStore.profile?.id === this.trade.from_id) {
        return {
          id: this.trade.from_id,
          image: this.trade.from_profile_image_url,
        }
      }

      return {
        id: this.trade.to_id,
        image: this.trade.to_profile_image_url,
      }
    },
    fromUser() {
      if (this.authStore.profile?.id === this.trade.from_id) {
        return {
          id: this.trade.to_id,
          image: this.trade.to_display_name,
        }
      }

      return {
        id: this.trade.from_id,
        image: this.trade.from_profile_image_url,
      }
    },
    isFrom() {
      return this.authStore.profile && this.authStore.profile.id === this.trade.from_id;
    },
    isTo() {
      return this.authStore.profile && this.authStore.profile.id === this.trade.to_id;
    }
  },
  methods: {
    async completeTrade(status) {
      this.loading = true
      await this.tradesStore.completeTrade(this.trade.tradeId, status)
      this.loading = false
      this.$router.push(`/users/${this.$route.params.username}/trades`)
    },
    getDuplicateRequestorCards(card) {
      const cards = this.fromSelectedDuplicatesUser ===
        this.trade.from_display_name ? this.requesterCards : this.receiverCards;

      return cards.filter(c => {
        const isSameCard = c.title === card.title;
        const isUsedInTrade = this.trade.offer.some(r => r.cardId === c.cardId);

        return isSameCard && !isUsedInTrade;
      });
    },
    getDuplicateReceiverCards(card) {
      const cards = this.toSelectedDuplicatesUser ===
        this.trade.to_display_name ? this.receiverCards : this.requesterCards;

      return cards.filter(c => {
        const isSameCard = c.title === card.title;
        const isUsedInTrade = this.trade.request.some(r => r.cardId === c.cardId);

        return isSameCard && !isUsedInTrade;
      });
    },
    showCards(selectedUserIndex) {
      this.selectedUserIndex = selectedUserIndex;

      if (selectedUserIndex === 0 && this.requesterCards?.length === 0) {
        this.cardsLoading = true;
        this.cardsStore.getUserCards(this.trade.from_id, 'owner', true).then(cards => {
          this.requesterCards = cards;
          this.cardsLoading = false;
        });
      }

      if (selectedUserIndex === 1 && this.receiverCards?.length === 0) {
        this.cardsLoading = true;
        this.cardsStore.getUserCards(this.trade.to_id, 'owner', true).then(cards => {
          this.receiverCards = cards;
          this.cardsLoading = false;
        });
      }
    },
    async sendMessage(message) {
      if (!this.trade.messages) {
        this.trade.messages = [];
      }

      const newMessageIndex = this.trade.messages.length;
      
      this.trade.messages.push({ message, from: this.authStore.profile.id, date: new Date(), pending: true });

      try {
        await this.tradesStore.sendMessage(this.trade.tradeId, message);
      } catch (e) {
        this.trade.messages[newMessageIndex].error = true;
      } finally {
        this.trade.messages[newMessageIndex].pending = false;
      }
    },
    resendMessage(messageIndex) {
      const message = this.trade.messages[messageIndex].message;
      this.trade.messages.splice(messageIndex, 1);
      this.sendMessage(message);
    },
    refreshTrade() {
      this.messagesLoading = true;
      this.tradesStore.getTrade(this.trade.tradeId, true).then(trade => {
        this.trade = trade;
        this.messagesLoading = false;
      });
    }
  },
};
</script>

<style scoped>
.trade {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
}

.tradingBlock {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.cardBlock {
  display: flex;
  width: 45%;
  min-width: 370px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardPalet {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  border: 1px solid var(--surface-border);
  border-radius: 5px;
  padding: 80px 20px;
  background-color: var(--surface-b);
  position: relative;
}

.card-line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 25px;
}

.action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.noCards {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detailsButton {
  position: absolute;
  top: 5px;
  left: 5px;
}

.card-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.card {
  z-index: 2;
}

.user-button {
  margin: 20px;
  padding: 5px;
  color: var(--text-color) !important;
}

.show-cards-button {
  flex-basis: 0;
  padding: 20px;
  margin-bottom: 20px;
}

.cards {
  width: 100%;
}

.cardsTitle {
  margin-top: 60px;
  margin-bottom: 10px;
  font-size: 40px;
  border-bottom: 1px solid lightgray;
}

.duplicate-settings-button {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.settings-label {
  margin: 0.5rem 0;
  text-align: left;
  font-weight: normal;
}

.buttons {
  margin-bottom: 20px;
}

</style>
