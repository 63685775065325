import axios from 'axios'

class DangPacksService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.serviceConfig = { baseUrl }

    this.config = {
      get: () => {
        return axios.get(`${this.baseUrl}/config`)
      },
      update: (propName, value, params) => {
        let data = {}
        data[propName] = value

        return axios.post(`${this.baseUrl}/config`, data, { params })
      },
    }

    this.cards = {
      byUser: (userId, type = 'owner', offset = 0, limit = 1000) => {
        let params = new URLSearchParams({ offset, limit, type });
        return axios.get(`${this.baseUrl}/users/${userId}/cards`, { params })
      }
    }

    this.notifications = {
      forUser: (userId, read, offset = 0, limit = 100) => {
        let params = new URLSearchParams({ offset, limit });
        if (typeof read === 'boolean') { params.append('read', read) }
        if (userId) { params.append('userId', userId) }
        return axios.get(`${this.serviceConfig.baseUrl}/notifications`, { params })
      },
      deleteAll: () => axios.delete(`${this.serviceConfig.baseUrl}/notifications`),
      deleteById: (notificationId) => axios.delete(`${this.serviceConfig.baseUrl}/notifications/${notificationId}`)
    }

    this.trades = {
      get: (id, to, from, status, offset = 0, limit = 1000) => {
        let params = new URLSearchParams({ offset, limit });
        if (id) { params.append('id', id) }
        if (to) { params.append('to', to) }
        if (from) { params.append('from', from) }
        if (status) { params.append('status', status) }

        return axios.get(`${this.baseUrl}/trades`, { params })
      },
      send: (from, to, offer, request, messages, params) => {
        const data = { from, to, offer, request, messages }
        return axios.post(`${this.baseUrl}/trades`, data, { params })
      },
      complete: (tradeId, status) => {
        let params = new URLSearchParams({ status });
        return axios.post(`${this.baseUrl}/trades/${tradeId}`, null, { params })
      },
      sendMessage: (tradeId, message) => {
        return axios.post(`${this.baseUrl}/trades/message`, { message, tradeId })
      }
    }

    this.users = {
      get: (user, offset = 0, limit = 10) => {
        let params = new URLSearchParams({ user, offset, limit });
        return axios.get(`${this.baseUrl}/users`, { params })
      }
    }

    this.inventory = {
      get: (user, offset = 0, limit = 1000) => {
        let params = new URLSearchParams({ offset, limit });
        return axios.get(`${this.baseUrl}/users/${user}/inventory`, { params })
      },
      post: (user_id, type, value) => {
        return axios.post(`${this.baseUrl}/inventory`, { user_id, type, value })
      }
    }

    this.achievements = {
      getAll: () => {
        return axios.get(`${this.baseUrl}/achievements`)
      },
      get: (user, offset = 0, limit = 1000) => {
        let params = new URLSearchParams({ offset, limit });
        return axios.get(`${this.baseUrl}/users/${user}/achievements`, { params })
      }
    }

    this.packs = {
      forUser: (user, params) => {
        return axios.get(`${this.baseUrl}/users/${user}/packs`, { params });
      }
    }
  }

  getSeasonCardPrints(season, title, params) {
    return axios.post(`${this.baseUrl}/cards/596-packs-prints`, { season, title }, { params })
  }

  getSeasonCardPreviewPrints(params) {
    return axios.get(`${this.baseUrl}/cards/596-packs-s2-gen`, { params })
  }

  // Leaderboards
  getSeasonCardsSummary(season) {
    let params = new URLSearchParams({ season });
    return axios.get(`${this.baseUrl}/cards/596-packs-minted`, { params })
  }

  getSeasonOwnerSummary(season, offset = 0, limit = 100) {
    let params = new URLSearchParams({ season, offset, limit });
    return axios.get(`${this.baseUrl}/cards/596-packs-owner-leader`, { params })
  }

  getSeasonAuthorSummary(season, offset = 0, limit = 100) {
    let params = new URLSearchParams({ season, offset, limit });
    return axios.get(`${this.baseUrl}/cards/596-packs-author-leader`, { params })
  }

  getCounterfeiters(season, offset = 0, limit = 100) {
    let params = new URLSearchParams({ season, offset, limit });
    return axios.get(`${this.baseUrl}/cards/596-packs-counterfeiters`, { params })
  }

  getCosmicBrownies(season, offset = 0, limit = 100) {
    let params = new URLSearchParams({ season, offset, limit });
    let username = '*'
    return axios.post(`${this.baseUrl}/cards/getbrownies`, { username }, params)
  }

  getCosmicRares(season, offset = 0, limit = 100) {
    let params = new URLSearchParams({ season, offset, limit });
    let username = '*'
    return axios.post(`${this.baseUrl}/cards/getcosmicrares`, { username }, params)
  }

  // Card Data
  getSeasonCards(season = 1, params) {
    return axios.get(`${this.baseUrl}/seasons/${season}`, { params })
  }

  // User data 
  searchUsers(user, offset = 0, limit = 10) {
    let params = new URLSearchParams({ user, offset, limit });
    return axios.get(`${this.baseUrl}/users`, { params })
  }

  getUserCards(user, type = 'owner', offset = 0, limit = 1000) {
    let params = new URLSearchParams({ offset, limit, type });
    return axios.get(`${this.baseUrl}/users/${user}/cards`, { params })
  }

  getUserInventory(user, offset = 0, limit = 1000) {
    let params = new URLSearchParams({ offset, limit });
    return axios.get(`${this.baseUrl}/users/${user}/inventory`, { params })
  }

  getUserAchievements(user, offset = 0, limit = 1000) {
    let params = new URLSearchParams({ offset, limit });
    return axios.get(`${this.baseUrl}/users/${user}/achievements`, { params })
  }

  //Inventory services
  useInventoryItem(itemId, type, target, params) {
    const data = { itemId, type, target }
    return axios.post(`${this.baseUrl}/inventory/use`, data, { params })
  }


  //pack opening
  openPack(packId, params) {
    const pData = { pack: packId };
    return axios.post(`${this.baseUrl}/cards/openpack`, pData, { params });
  }

  //packs to open
  getPacksToOpen(params) {
    return axios.get(`${this.baseUrl}/cards/packstoopen`, { params });
  }

  //Packs for a user
  getUserPacks(user, params) {
    return axios.get(`${this.baseUrl}/users/${user}/packs`, { params });
  }
}

const apiBaseUrl = process.env.VUE_APP_API_URL || null;
const dangPacksService = new DangPacksService(apiBaseUrl);

export { DangPacksService, dangPacksService }