import { defineStore } from "pinia";

export const usePreferencesStore = defineStore("preferences", {
    state: () => {
        let darkmode = true
        if (localStorage.darkMode) { darkmode = localStorage.darkMode === "true" } 
        else { darkmode = window.matchMedia("(prefers-color-scheme: dark)").matches }

        let muted = true
        if (localStorage.muted) { muted = localStorage.muted === "true" } 
        
        let audioHandle = new Audio()
        audioHandle.addEventListener('ended', ()=>audioHandle.volume=0);

        return {
            darkMode: darkmode,
            muted: muted,
            audioHandle: audioHandle,
        }
    },
    actions: {
        setup(){
            this.setTheme()
        },
        toggleTheme() {
            this.darkMode = !this.darkMode
            document.documentElement.className = this.darkMode
                ? "dark-theme"
                : "light-theme";

            document.getElementById("theme-link").href = this.darkMode
                ? `/themes/arya-purple/theme.css`
                : "/themes/saga-purple/theme.css";

            localStorage.darkMode = this.darkMode;
        },
        setTheme(){
            document.documentElement.className = this.darkMode
                ? "dark-theme"
                : "light-theme";

            document.getElementById("theme-link").href = this.darkMode
                ? `/themes/arya-purple/theme.css`
                : "/themes/saga-purple/theme.css";
        },
        toggleAudio() {
            this.muted = !this.muted
            localStorage.muted = this.muted;

            if (!this.muted) {
                this.playSound('/audio/toggle.mp3')
            }
        },
        playSound(file, volume = 1, forcePlay = false){
            if(!this.muted || forcePlay){
                this.audioHandle.src = file
                this.audioHandle.volume = volume
                this.audioHandle.play()

            }
        }
    },
    getters: {

    }
});