<template>
  <div class="centerpiece-wrapper">
    <img src="../assets/card-cases/centerpiece.png" class="centerpiece" />
    <div class="base-card-wrapper">
      <DangCardBase :card="card" />
    </div>
    <div class="nameplate">
      <div class="nameplate-title">{{ card.title }}</div>
    </div>
  </div>
</template>
<script>

import DangCardBase from "./DangCardBase.vue";

export default {
  name: "DangCardCenterpiece",
  components: {
    DangCardBase
  },
  props: {
    card: Object,
  },
};
</script>

<style scoped>
.centerpiece-wrapper {
  position: relative;
  padding-top: 30px;
  width: 380px;
  height: 600px;
  transform-style: preserve-3d;
}
.centerpiece {
  position: absolute;
  left: 0;
  top: 0;
}

.nameplate {
  position: absolute;
  left: 32px;
  bottom: 39px;
  width: 314px;
  height: 83px;
  transform: translateZ(20px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameplate-title {
  color: #000000;
  font-size: 1.7rem;
  /* font-weight: bold; */
  font-family: GentiumBookBasic;
}
.card:hover {
  z-index: 100;
  cursor: pointer;
}

.base-card-wrapper {
  left: 0px;
  position: absolute;
  top: 37px;
  width: 100%;
  height: 427px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  transform: translateZ(20px);
}

</style>