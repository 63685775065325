import { defineStore } from "pinia";

export const useFeatureFlagsStore = defineStore("featureFlags", {
    state: () => {
        return {
            maintenance: false || process.env.VUE_APP_MAINTENANCE === 'true',
            login: true,
            inventory: true || process.env.VUE_APP_FF_INVENTORY === 'true',
            trades: true || process.env.VUE_APP_FF_TRADES === 'true',
            season2: true || process.env.VUE_APP_FF_SEASON2 === 'true',
            achievements: true || process.env.VUE_APP_FF_ACHIEVEMENTS === 'true',
            cosmicRare: true || process.env.VUE_APP_FF_COSMICRARE === 'true',  
        }
    },
    actions: {},
    getters: {}
});