import { defineStore } from "pinia";
import { twitchService } from "../services/TwitchService";
import axios from "axios";
import jwt_decode from "jwt-decode";


export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            token: null,
            profile: null,
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchToken(code) {
            this.fetching = true
            
            try {
                // Fetch the data
                let response = await twitchService.fetchToken(code)
                
                // Set the token in state
                this.token = response.data
                axios.defaults.headers.common['Authorization'] = `Bearer ${this.token.access_token}`;

            } catch (error) {
                // Set and Log Errors
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async fetchProfile() {
            this.fetching = true
            
            try {
                // Fetch the data
                let response = await twitchService.fetchProfile()
                // Set the token in state
                this.profile = response.data
            } catch (error) {
                // Set and Log Errors
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        getLoginFrom(){
            let route = localStorage.loginFrom
            localStorage.loginFrom = null
            return route
        },
        login() {
            twitchService.login()
        },

        logout(){
            this.token = null;
            this.profile = null;
            delete axios.defaults.headers.common['Authorization']
        }

    },
    getters: {
       decodedToken: (state) => state.token ? jwt_decode(state.token.id_token) : null,
       isLoggedIn: (state) => state.token !== null && state.profile !== null
    }
});