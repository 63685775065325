<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <div v-if="adminPreviewStore.cards.length > 0">
        <h1>Admin Preview</h1>
        <hr />
        <div class="btn-grid">
          <Button
            @click="changeSelected(title)"
            :disabled="selectedCard === title && !allCards"
            class="p-button-info"
            v-for="title of adminPreviewStore.getUniqueCards"
            :key="title"
          >{{ title }}</Button>
          <Button
            @click="allCards = true; selectedCard = null"
            :disabled="allCards"
            class="p-button-info"
          >All Cards (Warning: Very Slow)</Button>
        </div>
        <hr />

        <div v-if="!allCards" class="cardGrid">
          <div v-for="(card, index) in adminPreviewStore.getCards(selectedCard)" :key="card.cardId">
            <DangCard :card="card" :showActions="!!card" :key="index" />
            <div>
              Card ID:
              <a @click="selectId(card.cardId)">{{ card.cardId }}</a> (
              <a @click="selectPack(card.packid)">{{ card.packid }}</a>
              )
            </div>
          </div>
        </div>
        <div v-else class="cardGrid">
          <div v-for="(card, index) in adminPreviewStore.getAllCards()" :key="card.cardId">
            <DangCard :card="card" :showActions="!!card" :key="index" />
            <div>
              Card ID:
              <a @click="selectId(card.cardId)">{{ card.cardId }}</a> (
              <a @click="selectPack(card.packid)">{{ card.packid }}</a>
              )
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h1>No Season {{ season }} Cards have been Minted Yet - Standby!</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { useAdminPreviewStore } from "../stores/admin_preview";
import { mapStores } from 'pinia'
import DangCard from "../components/DangCard.vue";

import LoadingIndicator from "../components/LoadingIndicator.vue";
import Button from "primevue/button";

export default {
  name: "AdminPreview",
  components: {
    DangCard,
    LoadingIndicator,
    Button
  },
  data() {
    return {
      season: null,
      loading: true,
      selectedCard: "Garden of Gamba",
      selectedIds: [],
      selectedPacks: [],
      allCards: false
    };
  },
  async created() {
    await this.adminPreviewStore.fetchSeasonCards()
    this.selectedCard = this.adminPreviewStore.getUniqueCards[0]
    this.loading = false
  },
  computed: {
    ...mapStores(useAdminPreviewStore)
  },
  methods: {
    changeSelected(title) {
      this.allCards = false
      this.selectedCard = title
    },
    selectId(id) {
      this.selectedIds.push(id);
    },
    selectPack(pack) {
      this.selectedPacks.push(pack);
    }

  }
};
</script>

<style scoped>
.btn-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.groupTitle {
  text-transform: capitalize;
}
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}
</style>
