<template>
    <div>
        <div class="header-text">History</div>
        <div v-if="!loading && trades.length === 0">
            No Trade History
            <br />Go close some deals!
        </div>
        <div v-else>
            <DataTable :value="trades" :scrollable="true" scrollHeight="555px" :loading="loading">
                <Column header="From">
                    <template #body="slotProps">
                        <div class="profile">{{ slotProps.data.from_display_name }}</div>
                    </template>
                </Column>

                <Column header="To">
                    <template #body="slotProps">
                        <div class="profile">{{ slotProps.data.to_display_name }}</div>
                    </template>
                </Column>
                <Column header="Status" field="status"></Column>
                <Column header="Date">
                    <template
                        #body="slotProps"
                    >{{ new Date(slotProps.data.updated_at).toDateString() }}</template>
                </Column>

                <Column header="Actions">
                    <template #body="slotProps">
                        <Button @click="onReviewClick(slotProps.data.tradeId)">Review</Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

export default {
    name: 'TradesOverviewHistory',
    props: {
        trades: Array,
        loading: Boolean,
        user: String,
    },
    components: {
        Button,
        DataTable,
        Column,
    },
    methods: {
        onReviewClick(tradeId) {
            this.$router.push(`/users/${this.user}/trades/${tradeId}`);
        }
    }


}
</script>

<style scoped>
</style>