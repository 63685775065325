<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <SubHeader header="Card Builder">
        <template #right>
          <a href="/CardTemplates.zip">
            <Button @click="downloadTemplates" icon="pi pi-download" title="Download Templates"></Button>
          </a>
        </template>
      </SubHeader>
      <hr />
      <div class="build-area">
        <div class="configure">
          <Accordion>
            <AccordionTab header="Upload Images">
              <FileUpload
                name="demo[]"
                :customUpload="true"
                @uploader="myUploader"
                accept="image/*"
                :showUploadButton="false"
                :showCancelButton="false"
                :multiple="true"
                :auto="true"
                @remove="updateAfterRemove"
              >
                <template #empty>
                  <p>Drag and drop files to here to upload.</p>
                </template>
              </FileUpload>
            </AccordionTab>
            <AccordionTab header="Select Image">
              <div class="form-line">
                <label for="image">Image</label>
                <Dropdown
                  id="image"
                  v-model="selectedImage"
                  :options="files"
                  optionLabel="name"
                  placeholder="Select an image"
                  @change="updateImage"
                  style="width: 100%;"
                ></Dropdown>
              </div>
            </AccordionTab>
            <AccordionTab header="Card Rarities">
              <div class="card-rarities-line">
                <label>Standard Rarities</label>

                <div
                  style="display:flex; flex-direction:column; gap:10px; align-items: flex-start;"
                >
                  <div
                    v-for="rarity of standardRarities"
                    :key="rarity.value"
                    class="field-radiobutton"
                  >
                    <RadioButton
                      :id="rarity.value"
                      :value="rarity.value"
                      v-model="card.rarity"
                      @change="updateCard"
                    />
                    <label :for="rarity.value">{{ rarity.name }}</label>
                  </div>
                </div>
                <label>Special Rarities</label>

                <div
                  style="display:flex; flex-direction:column; gap:10px; align-items: flex-start;"
                >
                  <div
                    v-for="rarity of specialRarities"
                    :key="rarity.value"
                    class="field-radiobutton"
                  >
                    <RadioButton
                      :id="rarity.value"
                      :value="rarity.value"
                      v-model="card.rarity"
                      @change="updateCard"
                    />
                    <label :for="rarity.value">{{ rarity.name }}</label>
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Card Text">
              <div class="form-line">
                <label for="title">Title</label>
                <InputText id="title" type="text" v-model="card.title" />
                <label for="minter">Minter</label>
                <InputText id="minter" type="text" v-model="card.minter" />
                <label for="author">Author</label>
                <InputText id="author" type="text" v-model="card.author" />
                <label for="flavor">Flavor Text</label>
                <Textarea id="flavor" type="text" v-model="card.flavor" rows="3" cols="30" />
              </div>
            </AccordionTab>
            <AccordionTab header="Card Features">
              <div class="form-line">
                <label for="stamped">Wax Stamped</label>
                <Checkbox id="stamped" v-model="card.stamped" :binary="true" />
                <label>Cases</label>
                <div
                  style="display:flex; flex-direction:column; gap:10px; align-items: flex-start;"
                >
                  <div
                    v-for="caseType of caseTypes"
                    :key="caseType.value"
                    class="field-radiobutton"
                  >
                    <RadioButton
                      :id="caseType.name"
                      :value="caseType.value"
                      v-model="selectedCase"
                      @change="updateCase(caseType.value)"
                    />
                    <label :for="caseType.value">{{ caseType.name }}</label>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        <div class="preview">
          <div class="preview-card">
            <DangCard :card="card" v-if="card.image" :showActions="false"></DangCard>
            <DangCard :card="null" :showActions="false" v-else></DangCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { mapStores } from 'pinia'


import Button from "primevue/button";
import RadioButton from "primevue/radiobutton"
import Checkbox from "primevue/checkbox"
import InputText from "primevue/inputtext";
import Textarea from 'primevue/textarea';
import Dropdown from "primevue/dropdown"
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import LoadingIndicator from "../components/LoadingIndicator.vue";
import SubHeader from "../components/SubHeader.vue";
import FileUpload from 'primevue/fileupload';
import DangCard from "../components/DangCard.vue";
export default {
  name: "adminCardsBuilder",
  components: {
    Button,
    RadioButton,
    Checkbox,
    InputText,
    Textarea,
    Dropdown,
    Accordion,
    AccordionTab,
    LoadingIndicator,
    SubHeader,
    FileUpload,
    DangCard
  },
  data() {
    return {
      card: {
        "cardId": 14067,
        "rarity": "fullart",
        "image": null,
        "series": "Character",
        "filter": "Y",
        "owner": "catsensei",
        "author": "dang_devs",
        "title": "Protype Card 1",
        "flavor": "We choose to create DangCards, not because they are easy, but because they are POG",
        "rarerank": 70,
        "packid": 2553,
        "season": null,
        "cardnum": 1,
        "seriesmax": 3,
        "minter": "DanGheesling",
        "minter_id": "00000000",
        "author_id": "00000000",
        "owner_id": "00000000",
        "case": null,
        "grade_overall": 8,
        "grade_corners": 8,
        "grade_surface": 8,
        "grade_edges": 8,
        "grade_centering": 8,
        "stamped": false
      },
      files: [],
      selectedImage: null,
      standardRarities: [
        { name: 'Fullart', value: 'fullart' },
        { name: 'Black', value: 'black' },
        { name: 'Rainbow', value: 'rainbow' },
        { name: 'Gold', value: 'gold' },
        { name: 'Red', value: 'red' },
        { name: 'Silver', value: 'silver' },
        { name: 'Base', value: 'base' },
      ],
      specialRarities: [
        { name: 'Airdrop', value: 'promo' },
        { name: 'Monochrome', value: 'monochrome' },
        { name: 'Pink', value: 'pink' },
      ],
      caseTypes: [
        { name: 'None', value: null },
        { name: 'Sleeve', value: 'sleeve' },
        { name: 'Centerpiece', value: 'centerpiece' },
        { name: 'Graded (8)', value: 'graded8' },
        { name: 'Graded (10)', value: 'graded10' },

      ],
      selectedCase: null,
      loading: true,
    };
  },
  async created() {
    await this.$router.isReady();
    // If we're not logged in, start the login flow
    if (!this.authStore.isLoggedIn || !['dan', 'artist', 'admin', 'dev'].includes(this.authStore.profile.role)) {
      this.$router.replace({path:'/'})
      return
    }

    this.loading = false
  },
  computed: {
    ...mapStores(useAuthStore),

  },
  methods: {
    updateCase(caseType) {
      console.log(caseType)
      if (caseType === 'graded10') {
        this.card.case = 'graded'
        this.card.grade_overall = this.card.grade_corners = this.card.grade_surface = this.card.grade_edges = this.card.grade_centering = 10
      }
      else if (caseType === 'graded8') {
        this.card.case = 'graded'
        this.card.grade_overall = this.card.grade_corners = this.card.grade_surface = this.card.grade_edges = this.card.grade_centering = 8
      }
      else this.card.case = caseType
    },
    updateCard() {
      if (this.card.rarity === 'fullart') { this.card.seriesmax = 3 }
      if (this.card.rarity === 'black') { this.card.seriesmax = 1 }
      if (this.card.rarity === 'rainbow') { this.card.seriesmax = 5 }
      if (this.card.rarity === 'gold') { this.card.seriesmax = 10 }
      if (this.card.rarity === 'red') { this.card.seriesmax = 25 }
      if (this.card.rarity === 'silver') { this.card.seriesmax = 50 }
      if (this.card.rarity === 'base') { this.card.seriesmax = 150 }
      if (this.card.rarity === 'promo') { this.card.seriesmax = 500 }
      if (this.card.rarity === 'monochrome') { this.card.seriesmax = 5 }
      if (this.card.rarity === 'pink') { this.card.seriesmax = 1 }
    },
    updateImage() {
      let reader = new FileReader();
      reader.readAsDataURL(this.selectedImage);
      reader.onloadend = (event) => { console.log(this.card.image = event.target.result) }
    },
    updateAfterRemove(event) {
      console.log('removed', event)
      this.files = event.files
      if (event.file.name === this.selectedImage.name) {
        if (this.files.length > 0) {
          this.selectedImage = this.files[0]
          this.updateCardImage()
        } else {
          this.selectedImage = null
          this.card.image = null
        }
      }
    },
    myUploader(event) {
      this.files = event.files
      if (this.selectedImage === null) {
        this.selectedImage = this.files[0]
        this.updateCardImage()
      }
    },
    updateCardImage() {
      let reader = new FileReader();
      reader.readAsDataURL(this.selectedImage);
      reader.onloadend = (event) => { this.card.image = event.target.result }
    }
  }
};
</script>

<style scoped>
.build-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center;
  align-items: center; */
}

.configure {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form-line {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: flex-start;
  gap: 20px;
}

.card-rarities-line {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  justify-content: flex-start;
  gap: 20px;
}
.preview {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.preview-card {
}
</style>
