<template>
  <div>
    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }"></ConfirmDialog>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <div class="inventory-header">
        <div>
          <h1>Select Card to {{ caseVerb }}</h1>
        </div>
      </div>
      <hr />

      <div class="inventory">
        <div class="cardGrid">
          <DangCard
            :card="card"
            :showActions="false"
            @click="confirmUse(card)"
            v-for="card in cardsStore.ownedCardsByUserId(user.id)"
            :key="card.cardId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { useInventoryStore } from "../stores/inventory";
import { useCardsStore } from "../stores/cards";
import { mapStores } from 'pinia'

import DangCard from "../components/DangCard.vue";
import ConfirmDialog from 'primevue/confirmdialog';
import LoadingIndicator from "../components/LoadingIndicator.vue";

export default {
  name: "UseInventoryCase",
  components: {
    ConfirmDialog,
    DangCard,
    LoadingIndicator
  },
  data() {
    return {
      user: null,
      itemId: null,
      item:null,
      loading: true,
    };
  },
  async created() {
    await this.$router.isReady();

    // If we're not logged in, start the login flow
    if (!this.authStore.isLoggedIn || isNaN(this.$route.params.itemId)) {
      this.$router.replace({ path: `/users/${this.$route.params.username}/inventory` })
      return;
    }

    this.user = this.authStore.profile
    this.itemId = parseInt(this.$route.params.itemId);

    await Promise.all([
      this.cardsStore.getUserCards(this.authStore.profile.id, 'owner', true),
      this.inventoryStore.getUserInventory(this.authStore.profile.id, true)
    ])


    this.item = this.inventoryStore.items.get(this.itemId)

    this.loading = false;

  },
  async mounted() { },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useInventoryStore),
    ...mapStores(useCardsStore),

    caseVerb() {
      let type = this.item.value.type

      if (type === "sleeve") {
        return "Sleeve"
      } else if (type === "centerpiece") {
        return "Centerpiece Mount"
      } else if (type === "graded") {
        return "Grade"
      } else {
        return ""
      }
    },
  },
  methods: {
    confirmUse(card) {
      this.$confirm.require({
        message: `The item cannot be reused & any existing cases on the selected card will be lost (including Grading).
        Are you sure you want to proceed?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: "reject",
        accept: async () => {
          this.loading = true
          await this.inventoryStore.useInventoryItem(this.itemId, this.item.type, card.cardId)
          this.$toast.add({ severity: 'success', summary: 'Success!', detail: `Case Applied`, life: 3000 });
          await this.cardsStore.getUserCards(this.authStore.profile.id, 'owner', true)
          this.$router.replace(`/users/${this.authStore.profile.display_name}`);
        },
        reject: () => {
        }
      });
    },
    sortCards(a, b) {
      return (
        this.compareRarity(a, b) ||
        this.compareSeries(a, b) ||
        this.compareTitle(a, b) ||
        this.comparePrint(a, b)
      );
    },
    compareSeries(card1, card2) {
      if (card1.series === card2.series) return 0;
      else if (card1.series === "ultra") return -1;
      else if (card2.series === "ultra") return 1;
      else return card1.series > card2.series ? 1 : -1;
    },
    compareTitle(card1, card2) {
      if (card1.title === card2.title) return 0;
      else return card1.title > card2.title ? 1 : -1;
    },
    compareRarity(card1, card2) {
      return card2.rarerank - card1.rarerank;
    },
    comparePrint(card1, card2) {
      return card1.cardnum - card2.cardnum;
    },
  },
};
</script>

<style scoped>
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}
</style>
