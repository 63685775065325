<template>
  <div>This Page Intentionally Left Blank</div>
</template>

<script>

export default {
  name: "404Error",
  components: {
  },
  async created() { },
};
</script>

<style scoped>
</style>
