<template>
  <div>
    <OverlayPanel ref="avatar">
      <div class="avatarPanel">
        <Button
          type="button"
          class="p-button-outlined p-button-primary navButton"
          @click="login"
          v-if="!authStore.isLoggedIn"
          label="Small"
        >
          <span>Login</span>
        </Button>

        <Button
          type="button"
          class="p-button-outlined p-button-primary navButton"
          @click="myProfileClicked"
          v-if="!featureFlagsStore.maintenance && !configStore.maintenance_mode && authStore.isLoggedIn"
          label="Small"
        >
          <span>My Profile</span>
        </Button>

        <Button
          type="button"
          class="p-button-outlined p-button-primary navButton"
          @click="authStore.logout()"
          v-if="authStore.isLoggedIn"
          label="Small"
        >
          <span>Logout</span>
        </Button>

        <div class="darkModeToggle">
          <span :class="{ darkModeActive: preferencesStore.darkMode }">☀️</span>
          <InputSwitch v-model="darkMode" @change="toggleTheme" class="darkModeSwitch" />
          <span :class="{ darkModeInactive: !preferencesStore.darkMode }">🌙</span>
        </div>

        <span @click="toggleAudio" class="pointer">{{ `${preferencesStore.muted ? `🔇` : `🔊`}` }}</span>
      </div>
    </OverlayPanel>

    <OverlayPanel ref="notifications">
      <NotificationsPanel />
    </OverlayPanel>

    <a
      :href="feedbackUrl"
      target="_blank"
      rel="noopener noreferrer"
      v-if="feedbackUrl !== null && $route.name !== 'adminPackOpening'"
    >
      <div>
        <Button icon="pi pi-comment" class="p-button-rounded p-button-info feedbackButton" />
      </div>
    </a>

    <div class="headerContainer">
      <router-link to="/" class="logoContainer">
        <img class="logoImg" alt="DangD" src="../assets/dangd.gif" />
      </router-link>

      <Autocomplete
        v-model="searchValue"
        :suggestions="searchedUsers"
        @complete="searchForUser()"
        @item-select="goToUserPage()"
        placeholder="Search User"
        class="header-searchBar"
        :completeOnFocus="searchValue?.length > 0"
        :inputStyle="{ width: '100%', textAlign: 'center' }"
        v-if="!featureFlagsStore.maintenance && !configStore.maintenance_mode"
        autoHighlight
      />

      <div class="rightSideHeader">
        <i
          class="pi pi-bell p-text-secondary pointer"
          style="font-size: 1.5rem"
          v-badge="notificationsStore.unread.length"
          @click="toggleNotifications"
          v-if="authStore.isLoggedIn && notificationsStore.unread.length > 0"
        ></i>
        <i
          class="pi pi-bell p-text-secondary pointer"
          style="font-size: 1.5rem"
          @click="toggleNotifications"
          v-else-if="authStore.isLoggedIn && notificationsStore.unread.length === 0"
        ></i>

        <Avatar
          @click="toggleAvatar"
          :image="authStore.profile.profile_image_url"
          size="large"
          shape="circle"
          v-if="authStore.isLoggedIn"
        />

        <Button @click="toggleAvatar" label="Large" v-else>
          <font-awesome-icon :icon="['fab', 'twitch']" />
        </Button>
      </div>
    </div>
    <div class="mainContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { useNotificationsStore } from "../stores/notifications";
import { useFeatureFlagsStore } from "../stores/feature_flags";
import { mapStores } from 'pinia'

import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Autocomplete from "primevue/autocomplete";
import Avatar from 'primevue/avatar';
import OverlayPanel from 'primevue/overlaypanel';

import { dangPacksService } from "../services/DangPacksService";
import NotificationsPanel from "./NotificationsPanel.vue";
import { useUsersStore } from '../stores/users';
import { usePreferencesStore } from "../stores/preferences";
import { useConfigStore } from "../stores/config";

export default {
  name: "Layout",
  components: {
    Button,
    InputSwitch,
    Autocomplete,
    Avatar,
    OverlayPanel,
    NotificationsPanel,
  },
  async created() {
    this.dangPacksService = dangPacksService
    this.darkMode = this.preferencesStore.darkMode
  },
  mounted() { },
  data() {
    return {
      visibleRight: false,
      darkMode: null,
      feedbackUrl: process.env.VUE_APP_FEEDBACK_URL || null,
      searchedUsers: [],
      searchValue: "",
      pollHandle: null,
      muted: true

    };
  },
  computed: {
    ...mapStores(useConfigStore),
    ...mapStores(useAuthStore),
    ...mapStores(useUsersStore),
    ...mapStores(useNotificationsStore),
    ...mapStores(usePreferencesStore),
    ...mapStores(useFeatureFlagsStore),
  },
  methods: {
    toggleAvatar(event) {
      this.$refs.avatar.toggle(event);
    },
    toggleNotifications(event) {
      this.$refs.notifications.toggle(event);
    },
    toggleTheme() {
      this.preferencesStore.toggleTheme()
      this.darkMode = this.preferencesStore.darkMode
    },
    toggleAudio() {
      this.preferencesStore.toggleAudio()
      this.muted = this.preferencesStore.muted
    },
    searchForUser() {
      this.dangPacksService.users.get(`${this.searchValue}%`).then(results => this.searchedUsers = results.data?.map(user => user.display_name));
    },
    goToUserPage() {
      this.$router.push(`/users/${this.searchValue}`);
    },
    login(){
      console.log(this.$route.path)
      localStorage.loginFrom = this.$route.path
      this.authStore.login()
    },
    myProfileClicked() {
      this.$router.push(`/users/${this.authStore.profile.display_name}`)
      this.toggleAvatar()
    },
  }
}
</script>

<style scoped>
.navButton {
  display: inline-flex;
  justify-content: space-between;
  font-size: 2rem;
  gap: 10px;
}

.feedbackButton {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
}

.headerContainer {
  padding: 10px 20px;
  height: 75px;
  display: flex;
  justify-content: space-between;
  background-color: var(--surface-b);
}
.header-searchBar {
  margin: 10px 0px;
  width: 50%;
}
.logoContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
}

.logoImg {
  height: 100%;
  margin-right: 10px;
}

.rightSideHeader {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px;
}

.avatarPanel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.darkModeToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.darkModeSwitch {
  margin: 0 10px;
  height: 25px;
  padding: 0.5rem;
}

.mainContainer {
  background-color: var(--background-color-secondary);
  /* min-height: calc(100vh - 75px); */
  /* padding-bottom: 100px; */
}

.darkModeInactive {
  filter: opacity(20%) grayscale(100%) invert(100%);
}

.darkModeActive {
  filter: opacity(20%) grayscale(100%);
}

.header-searchBar {
  margin-right: 20px;
}
</style>
