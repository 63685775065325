<template>
  <div class="trade">
    <div>
      <h1>New Trade</h1>
    </div>
    <div class="tradingBlock">
      <div class="cardBlock">
        <div class="cardPalet">
          <div class="detailsButton"><CardSummary :cards="trade[0].selected" icon="pi-info-circle" tooltip="Trade summary" /></div>
          <div v-if="trade[0].selected.length !== 0" class="card-line">
            <div class="card-container" v-for="card in trade[0].selected" :key="card.cardId">
              <div class="card">
                <DangCard
                  :card="card"
                  :showActions="false"
                  @click="removeFromTrade(card, 0)"
                />
              </div>
              <DuplicateIndicator :duplicates="getFromDuplicateCards(card)" />
            </div>
          </div>
          <div v-else class="noCards">Offer Up a Request</div>
          <Button 
            class="p-button-rounded p-button-text duplicate-settings-button"
            icon="pi pi-cog"
            @click="(e) => $refs.fromDuplicateSettingsOP.toggle(e)"
            v-if="showSettings"
          />
          <OverlayPanel ref="fromDuplicateSettingsOP">
            <h5 class="settings-label">Show duplicates for:</h5>
            <SelectButton v-model="fromSelectedDuplicatesUser" :options="userOptions" />
          </OverlayPanel>
        </div>
        <div style="margin: 20px; padding: 5px">{{ trade[0].user?.display_name }}</div>
        <Button
          @click="changeSelected(0)"
          :disabled="selectedUserIndex === 0"
          class="p-button-info select-cards-btn"
        >Show Cards</Button>
      </div>

      <div class="cardBlock">
        <div class="cardPalet">
          <div class="detailsButton"><CardSummary :cards="trade[1].selected" icon="pi-info-circle" tooltip="Trade summary" /></div>
          <div v-if="trade[1].selected.length !== 0" class="card-line">
            <div class="card-container" v-for="card in trade[1].selected" :key="card.cardId">
              <div class="card">
                <DangCard
                  :card="card"
                  :showActions="false"
                  @click="removeFromTrade(card, 1)"
                />
              </div>
              <DuplicateIndicator :duplicates="getToDuplicateCards(card)" />
            </div>
          </div>
          <div v-else class="noCards">What do ya want?</div>
          <Button 
            class="p-button-rounded p-button-text duplicate-settings-button"
            icon="pi pi-cog" 
            @click="(e) => $refs.toDuplicateSettingsOP.toggle(e)"
            v-if="showSettings"
          />
          <OverlayPanel ref="toDuplicateSettingsOP">
            <h5 class="settings-label">Show duplicates for:</h5>
            <SelectButton v-model="toSelectedDuplicatesUser" :options="userOptions" />
          </OverlayPanel>
        </div>
        <div>
          <div v-if="trade[1].cards.length > 0" class="tradeWithUser">
            <div style="margin-right: 10px">{{ trade[1].user?.display_name }}</div>
            <Button
              @click="resetTrade()"
              icon="pi pi-times"
              class="p-button-text p-button-rounded"
            />
          </div>
          <div v-else>
            <Autocomplete
              v-model="searchValue"
              :suggestions="searchedUsers"
              @complete="searchForUser(searchValue)"
              @item-select="selectUser(searchValue, 1)"
              @clear="resetTrade()"
              placeholder="Search User"
              class="searchUser"
              field="display_name"
            />
          </div>
        </div>
        <Button
          @click="changeSelected(1)"
          :disabled="selectedUserIndex === 1 || trade[1].user === null"
          class="p-button-info select-cards-btn"
        >Show Cards</Button>
      </div>
    </div>


    <div class="buttons">
      <Button
        @click="submitTrade"
        :disabled="trade[1].user === null || trade[0].selected.length === 0 || trade[1].selected.length === 0"
        :loading="tradeSubmitting"
        class="p-button tradeButton"
        label="Trade"
      />
      <Chat
        v-if="authStore.isLoggedIn && configStore.getConfig('ff_trade_messages', true)"
        :messages="messages" 
        :to="{
          id: authStore.profile.id,
          image: authStore.profile.profile_image_url,
        }"
        @on-message="addMessage" 
      />
    </div>


    <LoadingIndicator v-if="loading" />

    <div class="cards" v-else>
      <div class="cardsTitle">{{ this.trade[selectedUserIndex]?.user?.display_name }}'s Cards</div>

      <div
        v-if="selectedUserIndex === 0 && this.trade[selectedUserIndex].cards.length === 0"
      >You don't have cards to trade... sadge</div>
      <div
        v-else-if="selectedUserIndex === 1 && this.trade[selectedUserIndex].cards.length === 0"
      >{{ this.trade[selectedUserIndex]?.user?.display_name || 'This user' }} doesn't have cards to trade... sadge</div>
      <div v-else-if="selectedUserIndex === 0">Click Card to Add to Offer</div>
      <div v-else>Click Card to Request a Trade</div>

      <UserCardsFlat 
        :cards="trade[selectedUserIndex]?.cards"
        @card-clicked="(card) => addToTrade(card, selectedUserIndex)"
        disableActions 
      />
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import Button from "primevue/button";
import Autocomplete from "primevue/autocomplete";
import OverlayPanel from 'primevue/overlaypanel';
import SelectButton from 'primevue/selectbutton';

import { useTradesStore } from '@/stores/trades';
import { useAuthStore } from "@/stores/auth";
import { useFeatureFlagsStore } from "@/stores/feature_flags";
import { dangPacksService } from "@/services/DangPacksService";
import DangCard from "@/components/DangCard.vue";
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import DuplicateIndicator from "@/components/DuplicateIndicator.vue";
import CardSummary from "@/components/CardSummary.vue";
import { useUsersStore } from '../../stores/users';
import { useCardsStore } from '../../stores/cards';
import UserCardsFlat from '../../components/UserCardsFlat.vue';
import Chat from '../../components/Chat.vue';
import { useConfigStore } from '../../stores/config';

export default {
  name: "TradesNew",
  components: {
    DangCard,
    Autocomplete,
    Button,
    OverlayPanel,
    SelectButton,
    LoadingIndicator,
    CardSummary,
    DuplicateIndicator,
    UserCardsFlat,
    Chat,
},
  data() {
    return {
      // Services
      dangPacksService: null,

      // AutoComplete
      searchedUsers: null,
      searchValue: null,

      // Toggle display
      selectedUserIndex: 0,

      // Offer
      trade: [
        { user: null, cards: [], selected: [] },
        { user: null, cards: [], selected: [] },
      ],

      messages: [],

      toSelectedDuplicatesUser: null,
      fromSelectedDuplicatesUser: null,

      loading: true,
      tradeSubmitting: false,
    };
  },
  async created() {
    await this.$router.isReady();
    // If we're not logged in, start the login flow
    if (!this.authStore.isLoggedIn) {
      this.$router.replace({path:'/'})
      return
    }

    this.dangPacksService = dangPacksService
    this.selectedUserIndex = 0
    

    // Initiate what we know - Our stuff
    this.trade[0].user = await this.usersStore.getUserById(this.authStore.profile.id, 'owner', true);
    this.trade[0].cards = await this.cardsStore.getUserCards(this.authStore.profile.id, 'owner', true);
    this.trade[0].cards.sort(this.sortCards)


    let username = this.$route.query.user;
    let cardId = parseInt(this.$route.query.cardId);
    if (username) {
      this.searchValue = username

      this.trade[1].user = await this.usersStore.getUserByName(username);
      this.trade[1].cards = await this.cardsStore.getUserCards(this.trade[1].user.id, 'owner', true),
      this.trade[1].cards.sort(this.sortCards)

      if (cardId) {
        let card = this.trade[1].cards.find(card => card.cardId === cardId)
        this.addToTrade(card, 1)
      }
    }

    this.loading = false;
  },
  async mounted() { },
  computed: {
    ...mapStores(useConfigStore),
    ...mapStores(useAuthStore),
    ...mapStores(useUsersStore),
    ...mapStores(useCardsStore),
    ...mapStores(useTradesStore),
    ...mapStores(useFeatureFlagsStore),
    userOptions() {
      return [
        this.trade[0].user.display_name,
        this.trade[1].user?.display_name,
      ]
    },
    showSettings() {
      return this.trade[1].user !== null && this.trade[0].user !== null
    }
  },
  methods: {
    async searchForUser(user) {
      this.loading = true;
      this.searchedUsers = (await this.dangPacksService.users.get(`${user}%`)).data;
      this.loading = false;
    },
    async selectUser(user, userIndex) {
      this.loading = true;

      this.selectedUserIndex = userIndex
      this.trade[this.selectedUserIndex].user = user;
      this.toSelectedDuplicatesUser = this.trade[1].user.display_name;
      this.fromSelectedDuplicatesUser = this.trade[0].user.display_name;
      let cards = await dangPacksService.getUserCards(user.id)
      this.trade[this.selectedUserIndex].cards = !this.featureFlagsStore.season2 ? cards.data.filter(card => card.season !== 2) : cards.data;

      this.loading = false;
    },
    changeSelected(index) {
      this.selectedUserIndex = index
    },
    addToTrade(card, index) {
      this.trade[index].cards.filter(cardObj => cardObj.cardId === card.cardId)
      this.trade[index].cards = this.trade[index].cards.filter(cardObj => cardObj.cardId !== card.cardId)
      this.trade[index].selected = [...this.trade[index].selected, card]
      this.trade[index].cards.sort(this.sortCards)
      this.trade[index].selected.sort(this.sortCards)
    },
    removeFromTrade(card, index) {
      this.trade[index].selected = this.trade[index].selected.filter(cardObj => cardObj.cardId !== card.cardId)
      this.trade[index].cards = [...this.trade[index].cards, card]
      this.trade[index].cards.sort(this.sortCards)
      this.trade[index].selected.sort(this.sortCards)
    },
    resetTrade() {
      this.trade[1] = { user: null, cards: [], selected: [] }
      this.searchValue = null
      this.cardsSelected = [];
      this.selectedUserIndex = 0;
    },
    getFromDuplicateCards(card) {
      const cardsIndex = this.fromSelectedDuplicatesUser === this.trade[1].user?.display_name ? 1 : 0;
      return this.trade[cardsIndex].cards.filter(c => c.title === card.title);
    },
    getToDuplicateCards(card) {
      const cardsIndex = this.toSelectedDuplicatesUser === this.trade[1].user?.display_name ? 1 : 0;
      return this.trade[cardsIndex].cards.filter(c => c.title === card.title);
    },
    async submitTrade() {
      let from = this.trade[0].user;
      let to = this.trade[1].user;
      let offer = this.trade[0].selected
      let request = this.trade[1].selected
      this.tradeSubmitting = true
      await this.tradesStore.offerTrade(from, to, offer, request, this.messages);
      this.tradeSubmitting = false
      this.$router.push(`/users/${this.authStore.profile.display_name}/trades`);
    },
    sortCards(a, b) {
      return (
        this.compareRarity(a, b) ||
        this.compareSeries(a, b) ||
        this.compareTitle(a, b) ||
        this.comparePrint(a, b)
      );
    },
    compareSeries(card1, card2) {
      if (card1.series === card2.series) return 0;
      else if (card1.series === "ultra") return -1;
      else if (card2.series === "ultra") return 1;
      else return card1.series > card2.series ? 1 : -1;
    },
    compareTitle(card1, card2) {
      if (card1.title === card2.title) return 0;
      else return card1.title > card2.title ? 1 : -1;
    },
    compareRarity(card1, card2) {
      return card2.rarerank - card1.rarerank;
    },
    comparePrint(card1, card2) {
      return card1.cardnum - card2.cardnum;
    },
    addMessage(message) {
      this.messages.push({
        message,
        date: new Date(),
        from: this.authStore.profile.id,
      });
    }
  },
};
</script>

<style scoped>
.trade {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
}

.tradingBlock {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 20px;
  gap: 20px;
}

.cards {
  width: 100%;
}

.select-cards-btn {
  flex-basis: 0;
  padding: 20px;
}

.searchUser {
  margin: 20px;
}

.cardBlock {
  display: flex;
  width: 45%;
  min-width: 370px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardPalet {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  border: 1px solid var(--surface-border);
  border-radius: 5px;
  padding: 80px 20px;
  background-color: var(--surface-b);
  position: relative;
}

.card-line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 25px;
}

.noCards {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cardsTitle {
  margin-top: 60px;
  margin-bottom: 10px;
  font-size: 40px;
  border-bottom: 1px solid lightgray;
}

.tradeButton {
  display: flex;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 30px;
  text-align: center;
  border-radius: 5px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.tradeWithUser {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 5px;
}

.detailsButton {
  position: absolute;
  top: 5px;
  left: 5px;
}

.card-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.card {
  z-index: 2;
}

.duplicate-settings-button {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.settings-label {
  margin: 0.5rem 0;
  text-align: left;
  font-weight: normal;
}

</style>
