<template>
    <LoadingIndicator v-if="cardMintsStore.fetching" />
    <div v-else>
      <div v-if="cardMintsStore.getCardPrints(season, cardTitle).length > 0">
        <h1>Minted "{{ cardTitle }}"s</h1>
        <hr />
        <div v-for="(group, index) in currentPage" :key="index">
          <h1 class="groupTitle">
            {{ group.group.title }}
            <br />
            Minted {{ group.group.found }}/{{ group.group.max }}
          </h1>
          <hr />
          <div class="cardGrid" >
            <DangCard
              :card="card"
              :showActions="card ? true : false"
              v-for="(card, index) in group.items"
              :key="`${cardTitle}-${group.title}-${index}`"
            />
          </div>
        </div>
        <Paginator v-model:first="start" v-model:rows="rows" :totalRecords="cardData.length" @page="onPage($event)" :rowsPerPageOptions="[25,50,100,200,300]"></Paginator>
      </div>
      <div v-else>
        <h1>This card hasn't been minted yet - You still have a chance!</h1>
        <router-link to="/season/1/cards">Back to Season Cards</router-link>
      </div>
    </div>
</template>

<script>
import { useCardMintsStore } from "../stores/card_mints";
import { mapStores } from 'pinia'
import LoadingIndicator from "../components/LoadingIndicator.vue";
import DangCard from "../components/DangCard.vue";
import Paginator from 'primevue/paginator'

export default {
  name: "UserCards",
  components: {
    LoadingIndicator,
    DangCard,
    Paginator
},
  data() {
    return {
      season: null,
      cardTitle: null,
      raritiesArray:null,
      cardData:[],
      currentPage:[],
      lastGroup:null,

      start:0,
      end:100,
      rows:100,
      displayOffset:0,
    };
  },
  async created() {
    this.season = this.$route.params.seasonId;
    this.cardTitle = this.$route.params.cardTitle;

    if (!this.cardMintsStore.cardTitles.has(this.cardTitle)) {
      await this.cardMintsStore.fetchCardMints(this.season, this.cardTitle);
    }

    this.raritiesArray = await this.cardMintsStore.getRaritiesArray(this.season, this.cardTitle);
    await this.flattenArray();
    this.getSlice();
  },
  computed: {
    ...mapStores(useCardMintsStore)
  },
  methods: {
    async flattenArray() {
      this.raritiesArray.forEach(group => {
        this.cardData.push({type:'display', data: group});
        group.cards.forEach(card => {
          this.cardData.push({type: 'card', data: card});
        });
      });
    },
    getSlice() {
      let secondSlice;
      let firstSlice = this.cardData.slice(this.start, this.end);

      let displayItems = firstSlice.filter(object => {
        return object.type == 'display';
      });

      if (displayItems.length) {
        this.displayOffset = displayItems.length;
        secondSlice = this.cardData.slice(this.start, (this.end + this.displayOffset));
      } else {
        secondSlice = firstSlice;
      }

      this.currentPage = [];
      let groupCount = 0;

      secondSlice.forEach(item => {
        if (item.type == 'display') {
          if (!this.currentPage.length) {
            this.currentPage.push({group: item.data, items: []})
          } else {
            this.currentPage.push({group: item.data, items: []})
            groupCount ++;
          }

          this.lastGroup = item.data;
        } else {
          if (this.currentPage[groupCount]) {
            this.currentPage[groupCount].items.push(item.data);
          } else {
            this.currentPage.push({group: this.lastGroup, items: []})
            this.currentPage[groupCount].items.push(item.data);
          }
        }
      })
    },
    onPage(event) {
      if (event.page == 0) {
        this.start = event.page * this.rows;
      } else {
        this.start = (event.page * this.rows) + this.displayOffset;
      }

      this.end = this.start + this.rows;

      this.getSlice();
    }
  }
};
</script>

<style scoped>
.groupTitle {
  text-transform: capitalize;
}
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}
</style>
