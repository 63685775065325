import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useTradesStore = defineStore("trades", {
    state: () => {
        return {
            trades: new Map(),
            users: new Set(),
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchUserTrades(userId) {
            this.fetching = true
            try {
                let [incoming, outgoing] = await Promise.all([
                    dangPacksService.trades.get(null, userId),
                    dangPacksService.trades.get(null, null, userId)
                ])

                const trades = [...incoming.data, ...outgoing.data]
                for (let trade of trades) {
                    this.trades.set(trade.tradeId, trade)
                }
                this.users.add(userId)
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async getUserTrades(userId, force_refresh = false) {
            if (!this.users.has(userId) || force_refresh) { await this.fetchUserTrades(userId) }
            return [...this.trades.values()].filter(trade => trade.to_id === userId || trade.from_id === userId)
        },
        async fetchTrade(tradeId) {
            this.fetching = true
            try {
                const response = await dangPacksService.trades.get(tradeId)

                const trades = response.data
                for (let trade of trades) {
                    this.trades.set(trade.tradeId, trade)
                }
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async getTrade(tradeId, force_refresh = false) {
            if (!this.trades.has(tradeId) || force_refresh) { await this.fetchTrade(tradeId) }
            return this.trades.get(tradeId)
        },
        async offerTrade(from, to, offer_cards, request_cards, messages) {
            this.fetching = true
            try {
                let response = await dangPacksService.trades.send(from.id, to.id, offer_cards, request_cards, messages);
                this.trades.set(response.data.tradeId, response.data, messages)

                this.$toast.add({ severity: 'success', summary: 'Trade Sent', detail: `Trade offer sent to ${to.display_name}`, life: 3000 });
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async completeTrade(tradeId, status) {
            this.fetching = true
            try {
                let response = await dangPacksService.trades.complete(tradeId, status)
                let updated_trade = response.data.complete_trade
                this.trades.set(updated_trade.tradeId, updated_trade)

                if (status === 'accepted') {
                    this.$toast.add({ severity: 'success', summary: 'Accepted!', detail: 'Trade Accepted', life: 3000 });
                } else if (status === 'declined') {
                    this.$toast.add({ severity: 'success', summary: 'Declined!', detail: 'Trade Declined', life: 3000 });
                } else if (status === 'withdrawn') {
                    this.$toast.add({ severity: 'success', summary: 'Withdrawn!', detail: 'Trade Withdrawn', life: 3000 });
                }

            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async sendMessage(tradeId, message) {
            this.fetching = true
            try {
                await dangPacksService.trades.sendMessage(tradeId, message);
            } catch (error) {
                console.log(error)
                this.error = error
                throw error
            } finally {
                this.fetching = false
            }
        }
    },
    getters: {
        tradesByUserId: (state) => {
            return (userId) => [...state.trades.values()].filter(trade => trade.to_id === userId || trade.from_id === userId)
        },
        successfulTradesByUserId: (state) => {
            return (userId) => state.tradesByUserId(userId).filter(trade=> trade.status === 'accepted')
        },
    }
});