<template>
  <div>
    <div>
      <div class="header-text">Self Autos</div>
      <div>* Cards that are minted and authored by the same individual</div>
    </div>
    <DataTable
      :value="counterfeiters"
      responsiveLayout="stack"
      breakpoint="500px"
      :scrollable="true"
      scrollHeight="555px"
      v-model:filters="tableFilter"
      filterDisplay="menu"
      :loading="loading"
    >
      <template #loading>Loading records, please wait...</template>
      <Column field="minter" header="User" :showFilterMatchModes="false" :sortable="true">
        <template #body="slotProps">
          <router-link :to="`/users/${slotProps.data.minter}`">
            {{
              slotProps.data.minter
            }}
          </router-link>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column field="cards" header="# of Cards"></Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";

export default {
  name: 'LeaderboardsCounterfeitersSummary',
  props: {
    counterfeiters: Array,
    loading: Boolean,
  },
  components: {
    DataTable,
    Column,
    InputText,
  },
  data: () => ({
    tableFilter: {
      minter: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
  }),
}
</script>

<style scoped>
</style>