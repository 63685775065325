<template>
  <div class="open-packs-view">
    <Toast position="top-right" group="achievement" class="achievement-toast">
      <template #message="slotProps">
        <div
          style="display: flex; flex-direction: column; gap: 10px; width: 100%"
        >
          <h3 style="margin: 0">{{ slotProps.message.summary }}</h3>
          <div class="achievement-layout">
            <img
              :src="`/images/achievements/${slotProps.message.image}.png`"
              class="achievement-image"
            />
            <div>
              <h4 style="margin: 0">{{ slotProps.message.detail }}</h4>
            </div>
          </div>
        </div>
      </template>
    </Toast>
    <LoadingIndicator v-if="loading" />
    <div v-else class="open-packs-view-main">
      <div v-if="pageState !== 'video'" class="open-packs-top">
        <ul style="list-style: none; padding: 0">
          <div class="pack-list-actions">
            <div></div>
            <h3 style="margin: 0">
              {{ `Packs (${packOpeningStore.getPacks.length})` }}
            </h3>
            <div>
              <Button
                @click="packOpeningStore.shufflePacks()"
                icon="pi pi-refresh"
                title="Shuffle"
                style="margin-right: 10px"
              ></Button>
              <Button
                @click="updatePacks"
                icon="pi pi-download"
                title="Grab Latest"
                style="margin-right: 10px"
                :disabled="updatingPacks"
              ></Button>
              <Button
                @click="onlineOnly"
                icon="pi pi-comment"
                title="Show in-chat packs"
                :disabled="updatingPacks"
              ></Button>
            </div>
          </div>
          <hr />
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
            "
          >
            <InputText
              type="text"
              v-model="filterPacks"
              placeholder="Filter List"
            />
            <i @click="clearFilter" class="pi pi-times-circle pointer"></i>
          </div>
          <li
            v-for="pack in packOpeningStore.getFilteredPacksToOpen(filterPacks)"
            :key="`${pack.packId}`"
            style="display: grid; grid-template-columns: 6fr 0.5fr; width: 100%"
          >
            <div>
            <i v-if="pack.online" class="pi pi-circle-fill online"></i>
            {{ pack.minter }}
            </div>
            <div style="display: flex">
              <i
                class="pi pi-angle-double-up pointer"
                @click="packOpeningStore.moveTop(pack)"
              ></i>
              <i
                class="pi pi-angle-up pointer"
                @click="packOpeningStore.moveUp(pack)"
              ></i>
              <i
                class="pi pi-angle-down pointer"
                @click="packOpeningStore.moveDown(pack)"
              ></i>
              <i
                class="pi pi-angle-double-down pointer"
                @click="packOpeningStore.moveBottom(pack)"
              ></i>
            </div>
          </li>
        </ul>
        <div>
          <h2>
            {{
              packOpeningStore.getCurrentPack[0] &&
              `Pack for : ${packOpeningStore.getCurrentPack[0]?.minter}`
            }}
          </h2>
        </div>
        <div>
          <h3>Variants</h3>
          <hr />
          <div class="variants-list">
            <div>Base</div>
            <div># / 150</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "base") -
                  leaderboardsStore.getMinted(currentSeason, "base")
                } Left`
              }}
            </div>
            <div>Silver</div>
            <div># / 50</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "silver") -
                  leaderboardsStore.getMinted(currentSeason, "silver")
                } Left`
              }}
            </div>

            <div>Red</div>
            <div># / 25</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "red") -
                  leaderboardsStore.getMinted(currentSeason, "red")
                } Left`
              }}
            </div>

            <div>Gold</div>
            <div># / 10</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "gold") -
                  leaderboardsStore.getMinted(currentSeason, "gold")
                } Left`
              }}
            </div>

            <div>Rainbow</div>
            <div># / 5</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "rainbow") -
                  leaderboardsStore.getMinted(currentSeason, "rainbow")
                } Left`
              }}
            </div>

            <div>Fullart</div>
            <div># / 3</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "fullart") -
                  leaderboardsStore.getMinted(currentSeason, "fullart")
                } Left`
              }}
            </div>

            <div>Black</div>
            <div># / 1</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "black") -
                  leaderboardsStore.getMinted(currentSeason, "black")
                } Left`
              }}
            </div>

            <div style="grid-column: 1 / span 2">5 Logo Cards</div>
            <div>
              {{
                `${
                  leaderboardsStore.getTotal(currentSeason, "logo") -
                  leaderboardsStore.getMinted(currentSeason, "logo")
                } Left`
              }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="pageState == 'newpack'" class="new-pack-stage">
        <DangCard
          :card="null"
          class="open-pack-card"
          @click="switchToVideo()"
        />
      </div>
      <div v-if="pageState == 'video'" class="video-container">
        <video
          ref="videoRef"
          preload="auto"
          src="../assets/pack-opening/opening-video.webm"
          id="video-container"
          width="100%"
          @ended="switchToCards"
        ></video>
      </div>
      <div v-if="pageState == 'cards'" class="flip-cards-stage">
        <div class="cards-grid">
          <DangCard
            class="pack-card"
            :card="card"
            :class="[card.revealed ? 'flipped' : '']"
            :hideRarity="!card.revealed"
            :showActions="packOpeningStore.cardsRevealed"
            @click="showCard(card)"
            v-for="card in packOpeningStore.getCurrentPack"
            :key="card.cardId"
            :renderQuality="true"
          />
        </div>

        <Button
          @click="switchToVideo"
          :disabled="!packOpeningStore.cardsRevealed"
          >{{ `Next Pack` }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { usePackOpeningStore } from "../stores/pack_opening";
import { useLeaderboardsStore } from "../stores/leaderboards";
import { useAchievementsStore } from "../stores/achievements";
import { useFeatureFlagsStore } from "../stores/feature_flags";

import { mapStores } from "pinia";
import DangCard from "../components/DangCard.vue";

import LoadingIndicator from "../components/LoadingIndicator.vue";
import Button from "primevue/button";
import Toast from "primevue/toast";
import InputText from "primevue/inputtext";
import { usePreferencesStore } from "../stores/preferences";
import { useCardsStore } from "../stores/cards";

import { ChatClient } from "twitch-chat-client";

export default {
  name: "AdminPreview",
  components: {
    DangCard,
    LoadingIndicator,
    Button,
    Toast,
    InputText,
  },
  data() {
    return {
      pageState: "newpack",
      loading: true,
      updatingPacks: false,
      achievements: [],
      filterPacks: "",
      currentSeason: "2.5",
    };
  },
  async created() {
    await this.$router.isReady();

    // If we're not logged in, start the login flow
    if (!this.authStore.isLoggedIn || !['dan', 'dev'].includes(this.authStore.profile.role)) {
      this.$router.replace({path:'/'})
      return
    }
  

    if (this.packOpeningStore.getPacks.length === 0) {
      this.leaderboardsStore.fetchSeasonCardsSummary();
      await this.packOpeningStore.fetchPacksToOpen();
      this.packOpeningStore.shufflePacks();
    }
    if (this.packOpeningStore.haveOpenPack) {
      this.pageState = "cards";
    }
    this.loading = false;
  },

  async mounted() {
    const channel = "dangheesling";
    const chatClient = ChatClient.anonymous({
      channels: [channel],
      requestMembershipEvents: false
    });

    chatClient.onMessage((channel, user, message, msg) => {
      this.packOpeningStore.addChatter(user.toLowerCase(),msg.userInfo.userId);
    });

    chatClient.connect();
  },

  computed: {
    ...mapStores(useFeatureFlagsStore),
    ...mapStores(useAuthStore),
    ...mapStores(usePackOpeningStore),
    ...mapStores(useLeaderboardsStore),
    ...mapStores(useAchievementsStore),
    ...mapStores(usePreferencesStore),
    ...mapStores(useCardsStore),
  },
  methods: {
    async switchToVideo() {
      this.achievements = [];
      this.pageState = "video";
      this.$nextTick(() => this.$refs.videoRef.play());
      await this.leaderboardsStore.fetchSeasonCardsSummary();
      await this.packOpeningStore.openPack();
      await this.achievementsStore.fetchUserAchievements(this.packOpeningStore.currentPackOwnerId);
      this.cardsStore.fetchUserCards(this.packOpeningStore.currentPackOwner);
    },
    switchToCards() {
      this.pageState = "cards";
    },
    async showCard(card) {
      // Mark current Card As Revealed
      card.revealed = true;

      // If all are revealed, show any achievements
      if (this.packOpeningStore.cardsRevealed) {
        //get user's achievements
        let achievements = await this.achievementsStore.getUserAchievements(this.packOpeningStore.currentPackOwnerId);

        // Play a sound if associated with the current pack id
        if (
          achievements
            .map((achievement) => achievement.achpack)
            .includes(this.packOpeningStore.currentPackId)
        ) {
          this.preferencesStore.playSound("/audio/trophy-sound.mp3", 1, true);
        }

        // Generate toasts for each
        for (let achievement of achievements) {
          if (achievement.achpack === this.packOpeningStore.currentPackId) {
            this.$toast.add({
              severity: "success",
              summary: "Achievement Earned!",
              detail: achievement.title,
              image: achievement.image,
              group: "achievement",
              life: 3000,
            });
          }
        }
      }
    },
    async updatePacks() {
      this.updatingPacks = true;
      await this.packOpeningStore.updatePacksToOpen();
      this.updatingPacks = false;
      this.$toast.add({
        severity: "success",
        summary: "Packs Updated",
        detail: `Pack list Updated!`,
        life: 3000,
      });
    },
    async onlineOnly(){
      this.packOpeningStore.filterOnlineOnly();
    },
    clearFilter() {
      this.filterPacks = "";
    },
  },
};
</script>

<style scoped>
.pack-card {
  transform: rotateY(180deg);
}
.flipped {
  transform: rotateY(0deg);
  transition: transform 0.5s;
}
.open-packs-view {
  height: 100%;
}

.open-packs-view-main {
  height: 100%;
}

.open-packs-top {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
}

.variants-list {
  display: grid;
  grid-template: auto / 1fr 1fr 1fr;
}
.pack-list-actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.video-container {
  width: 100%;
  height: calc(100vh - 75px);
  padding: 0px 50px;
}

.video-container > video {
  height: inherit;
  width: inherit;
  object-fit: cover;
}
.new-pack-stage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flip-cards-stage {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: flex-start;
  align-items: center;
}
.cards-grid {
  width: 100%;
  padding: 0px 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}

.flip-enter-active,
.flip-leave-active {
  transition: all 0.4s;
  transform-style: preserve-3d;
}
.flip-enter, .flip-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}

.achievement-layout {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.achievement-image {
  height: 100px;
  width: 100px;
}

.pointer {
  cursor: pointer;
}

.online {
  color: #37de20;
  
}
</style>

