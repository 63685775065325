import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useConfigStore = defineStore("config", {
    state: () => {
        return {
            config: null,
            fetching: false,
            error:null
        }
    },
    actions: {
        /**
         * Fetch App Config
         */
        async fetchConfig() {
            this.fetching = true
            try {
                let response = await dangPacksService.config.get()
                this.config = response.data
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        /**
         * Toggle maintenance
         */
        async toggleMaintenanceMode() {
            this.fetching = true
            try {
                let response = await dangPacksService.config.update('maintenance_mode', !this.config.maintenance_mode)
                this.config = response.data
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        /**
         * Set Active Season
         */
        async setActiveSeason(season) {
            this.fetching = true
            try {
                let response = await dangPacksService.config.update('active_season', season)
                this.config = response.data
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
                /**
         * Set Active Season
         */
        async updateProp(propName, value) {
            this.fetching = true
            try {
                let response = await dangPacksService.config.update(propName, value)
                this.config = response.data
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
    },
    getters: {
        maintenance_mode: (state) => { return state.config !== null ? state.config.maintenance_mode : false },
        active_season: (state) => { return state.config !== null ? state.config.active_season : 1 },
        getConfig: (state) => {
            return (configName, defaultValue) => configName in state.config ? state.config[configName] : defaultValue;
        },
        feature_flags: (state)=> {
            if(state.config === null){return {}}
            return Object.keys(state.config)
            .reduce((out,prop) => {
              const prefixes = ['ff_']
              if(prefixes.some(prefix => prop.startsWith(prefix)))
                out[prop] = state.config[prop]
              return out
            }, {})
        }
    }
});