import { createRouter, createWebHistory } from 'vue-router'
import { useFeatureFlagsStore } from '../stores/feature_flags'
import { useConfigStore } from '../stores/config'

// General
import Dashboard from '../views/Dashboard.vue'
import Leaderboards from '../views/Leaderboards.vue'
import FAQ from '../views/FAQ.vue'
// User Items
import UserProfile from '../views/UserProfile.vue'
import UserCards from '../views/UserCards.vue'
import UserInventory from '../views/UserInventory.vue'
import UseInventoryCase from '../views/UseInventoryCase.vue'
import UserAchievements from '../views/UserAchievements.vue'
import TradesNew from '../views/trades/TradesNew.vue'
import UserTrades from '../views/trades/UserTrades.vue'
import TradesReview from '../views/trades/TradesReview.vue'
// Season
import SeasonCards from '../views/SeasonCards.vue'
import CardMints from '../views/CardMints.vue'
import SeasonCardsPreview from '../views/SeasonCardsPreview.vue'
// Admin
import AdminSeasonCardsPreview from '../views/AdminSeasonCardsPreview.vue'
import AdminPackOpening from '../views/AdminPackOpening.vue'
import AdminCardsBuilder from '../views/CardBuilder.vue'
import AdminControlCentre from '../views/AdminControlCentre.vue'
// trades
import Maintenance from '../views/Maintenance.vue'
import Error404 from '../views/Error404.vue'

function createRoutes() {
  const featureFlags = useFeatureFlagsStore()
  const config = useConfigStore()

  let routes = []

  routes.push({ path: '/', redirect: { name: 'dashboard' } })
  routes.push({ path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { title: "Packs Dashboard" } })
  routes.push({ path: '/leaderboards', name: 'leaderboards', component: Leaderboards, meta: { title: "Leaderboards" } })
  routes.push({ path: '/faq', name: 'Frequently Asked Questions', component: FAQ, meta: { title: "Review Trade" } })
  routes.push({ path: '/season/:seasonId/cards', name: 'seasonCards', component: SeasonCards, meta: { title: "Packs Dashboard" } })
  routes.push({ path: '/season/:seasonId/cards/:cardTitle', name: 'seasonCard', component: CardMints, meta: { title: "Packs Dashboard" } })
  routes.push({ path: '/season/:seasonId/preview', name: 'seasonCardsPreview', component: SeasonCardsPreview, meta: { title: "Packs Dashboard" } })
  routes.push({ path: '/users/:username', name: 'userProfile', component: UserProfile, meta: { title: "User Profile" } })
  routes.push({ path: '/users/:username/cards', name: 'userCards', component: UserCards, meta: { title: "Packs Dashboard" } })
  routes.push({ path: '/users/:username/achievements', name: 'UserAchievements', component: UserAchievements, meta: { title: "Achievements" } })
  routes.push({ path: '/users/:username/inventory', name: 'userInventory', component: UserInventory, meta: { title: "Inventory" } })
  routes.push({ path: '/users/:username/inventory/use/case/:itemId', name: 'useInventory', component: UseInventoryCase, meta: { title: "Apply Case" } })
  routes.push({ path: '/users/:username/trades', name: 'UserTrades', component: UserTrades, meta: { title: "Trades" } })
  routes.push({ path: '/users/:username/trades/new', name: 'TradesNew', component: TradesNew, meta: { title: "New Trade" } })
  routes.push({ path: '/users/:username/trades/:tradeId', name: 'TradesReview', component: TradesReview, meta: { title: "Review Trade" } })
  routes.push({ path: '/admin/cards/builder', name: 'adminCardsBuilder', component: AdminCardsBuilder, meta: { title: "Admin Cards Builder" } })
  routes.push({ path: '/admin/season/preview', name: 'adminSeasonCardsPreview', component: AdminSeasonCardsPreview, meta: { title: "Admin Packs Dashboard" } })
  routes.push({ path: '/admin/open-packs', name: 'adminPackOpening', component: AdminPackOpening, meta: { title: "DangPacks" } })
  routes.push({ path: '/admin/controlcentre', name: 'adminControlCentre', component: AdminControlCentre, meta: { title: "Admin - Control Centre" } })
  routes.push({ path: '/404', name: 'error404', component: Error404, meta: { title: "Page Not Found" } })
  routes.push({ path: '/maintenance', name: 'maintenance', component: Maintenance, meta: { title: "Under Maintenance" } })
  routes.push({ path: "/:catchAll(.*)", redirect: { name: 'error404' } })

  if (featureFlags.maintenance || config.maintenance_mode) {
    routes = [
      { path: '/', name: 'maintenance', component: Maintenance, meta: { title: "Under Maintenance" } },
      { path: '/admin/controlcentre', name: 'adminControlCentre', component: AdminControlCentre, meta: { title: "Admin - Control Centre" } },
      { path: "/:catchAll(.*)", redirect: { name: 'maintenance' } }
    ]
  }

  const router = new createRouter({
    history: createWebHistory(),
    routes: routes
  })

  return router
}


export { createRoutes }
