<template>
    <div class="dang-base-card">
        <div class="card-back"></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DangCardBack",
    components: {

    },
    props: {
        card: Object,
    },
    data: () => ({
        imagePath: null,

    }),
    created() {

    },
    mounted() { },
    methods: {},
};
</script>

<style scoped>
.dang-base-card {
    position: relative;
    height: 430px;
    width: 315px;
    transform-style: preserve-3d;
}

.card-back {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 11px 11px 10px 11px;
    background-image: url("../assets/CardBack.png");
    backface-visibility: hidden;
}
</style>

