<template>
    <div>
        <LoadingIndicator v-if="loading" />
        <div class="profile" v-else>
            <SubHeader :header="`${user.display_name}'s Profile`">
                <template #left>
                    <Button @click="backToDashboard" icon="pi pi-chevron-left"></Button>
                </template>
                <template #right>
                    <Button @click="refreshProfile" icon="pi pi-refresh"></Button>
                </template>
            </SubHeader>

            <div class="profileStats">
                <div class="profileStat">
                    <div>Owned Cards</div>
                    <div class="statNumber" v-if="cardsStore.fetching">
                        <LoadingIndicator image="spinner" />
                    </div>
                    <div
                        class="statNumber"
                        v-else
                    >{{ cardsStore.ownedCardsByUserId(user.id).length }}</div>
                    <router-link
                        class="statLink"
                        :to="`/users/${user.display_name}/cards`"
                    >View Cards</router-link>
                </div>

                <div class="profileStat">
                    <div>Successful trades</div>
                    <div class="statNumber" v-if="tradesStore.fetching">
                        <LoadingIndicator image="spinner" />
                    </div>
                    <div
                        class="statNumber"
                        v-else
                    >{{ tradesStore.successfulTradesByUserId(user.id).length }}</div>
                    <router-link
                        class="statLink"
                        :to="`/users/${user.display_name}/trades`"
                    >View Trades</router-link>
                </div>
                <div class="profileStat">
                    <div>Owned Items</div>
                    <div class="statNumber" v-if="inventoryStore.fetching">
                        <LoadingIndicator image="spinner" />
                    </div>
                    <div
                        class="statNumber"
                        v-else
                    >{{ inventoryStore.unusedItemsByUserId(user.id).length }}</div>
                    <router-link
                        class="statLink"
                        :to="`/users/${user.display_name}/inventory`"
                    >View Items</router-link>
                </div>

                <div class="profileStat">
                    <div>Achievements</div>
                    <div class="statNumber" v-if="achievementsStore.fetching">
                        <LoadingIndicator image="spinner" />
                    </div>
                    <div
                        class="statNumber"
                        v-else
                    >{{ achievementsStore.achievementsByUserId(user.id).length }}</div>
                    <router-link
                        class="statLink"
                        :to="`/users/${user.display_name}/achievements`"
                    >View Badges</router-link>
                </div>

                <div class="profileStat">
                    <div>Packs Available</div>
                    <div class="statNumber" v-if="packsStore.fetching">
                        <LoadingIndicator image="spinner" />
                    </div>
                    <div
                        class="statNumber"
                        v-else
                    >{{ this.packsStore.sealedPacksByUserId(this.user.id).length }}</div>
                    <div class="comingSoon">Sealed Packs</div>
                </div>
            </div>
            <div class="widgets">
                <div class="inventory">
                    Inventory
                    <div v-if="inventoryStore.fetching" class="inventoryItems">
                        <LoadingIndicator />
                    </div>
                    <div v-else>
                        <div
                            v-if="inventoryStore.unusedItemsByUserId(user.id).length > 0"
                            class="inventoryItems"
                        >
                            <div
                                v-for="item in inventoryStore.unusedItemsByUserId(user.id)"
                                :key="item.itemId"
                            >
                                <div
                                    class="item hvr-float"
                                    :class="this.authStore.isLoggedIn && this.user.id === this.authStore.profile.id ? 'pointer' : ''"
                                    @click="useItem(item.itemId, item.type)"
                                >
                                    <img width="150" :src="getItemAsset(item.value.type)" />
                                    <div style="text-transform: capitalize">{{ item.value.type }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>No Inventory Items</div>
                    </div>
                </div>
                <div>
                    <TradesOverviewIncoming
                        :trades="incomingTrades"
                        :user="user.display_name"
                        :loading="tradesStore.fetching"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia'
import LoadingIndicator from '../components/LoadingIndicator.vue';
import TradesOverviewIncoming from '../components/TradesOverviewIncoming.vue';
import SubHeader from "../components/SubHeader.vue";
import Button from "primevue/button"
import { useAuthStore } from "../stores/auth";
import { useTradesStore } from '../stores/trades';
import { useUsersStore } from '../stores/users';
import { useCardsStore } from "../stores/cards";
import { useInventoryStore } from '../stores/inventory';
import { useAchievementsStore } from '../stores/achievements';
import { usePacksStore } from '../stores/packs';
import { useFeatureFlagsStore } from '../stores/feature_flags';

export default {
    name: "UserProfile",
    components: {
        LoadingIndicator,
        TradesOverviewIncoming,
        SubHeader,
        Button
    },
    data() {
        return {
            user: null,
            loading: true
        };
    },
    async created() {
        await this.$router.isReady()

        this.user = await this.usersStore.getUserByName(this.$route.params.username)
        this.loading = false

        this.cardsStore.getUserCards(this.user.id)
        this.tradesStore.getUserTrades(this.user.id)
        this.inventoryStore.getUserInventory(this.user.id)
        this.packsStore.getUserPacks(this.user.id)
        this.achievementsStore.getUserAchievements(this.user.id)
    },
    computed: {
        ...mapStores(useFeatureFlagsStore),
        ...mapStores(useAuthStore),
        ...mapStores(useInventoryStore),
        ...mapStores(useTradesStore),
        ...mapStores(useCardsStore),
        ...mapStores(usePacksStore),
        ...mapStores(useAchievementsStore),
        ...mapStores(useUsersStore),
        incomingTrades() { return this.tradesStore.tradesByUserId(this.user.id).filter(trade => trade.to_id === this.user.id && trade.status === 'pending').sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) },
        acceptedTrades() { return this.tradesStore.tradesByUserId(this.user.id).filter(trade => trade.status === 'accepted').sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) },

    },
    methods: {
        async refreshProfile() {

            this.inventoryStore.getUserInventory(this.user.id, true).then(data => this.inventory = data)
            this.tradesStore.getUserTrades(this.user.id, true).then(data => this.trades = data)
            this.cardsStore.getUserCards(this.user.id, true).then(data => this.cards = data)
            this.packsStore.getUserPacks(this.user.id, true).then(data => this.packs = data)
            if (this.featureFlagsStore.achievements) {
                this.achievementsStore.getUserAchievements(this.user.id, true).then(data => this.achievements = data)
            }

        },
        getItemAsset(itemType) {
            if (itemType === 'sleeve') {
                return require('@/assets/card-cases/sleeve-lc.png');
            } else if (itemType === 'centerpiece') {
                return require('@/assets/card-cases/centerpiece.png')
            } else if (itemType === 'graded') {
                return require('@/assets/card-cases/graded.png')
            }
        },
        useItem(itemId, type) {
            if (this.authStore.isLoggedIn && this.user.id === this.authStore.profile.id)
                this.$router.push(`/users/${this.authStore.profile.display_name}/inventory/use/${type}/${itemId}`);
        },
        backToDashboard() {
            this.$router.push("/");
        },
    },
};
</script>

<style scoped>
.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.spotlightCards {
    display: flex;
    width: fit-content;
    justify-content: space-around;
    animation: card-rotate 5s linear infinite;
    transform: rotate(45deg);
}

.cardsToTrade {
    display: flex;
    width: fit-content;
    justify-content: space-around;
}

@keyframes card-rotate {
    0% {
        transform: rotateY(0) rotate(15deg);
    }
    100% {
        transform: rotateY(360deg) rotate(15deg);
    }
}

.spotlightCardsContent {
    display: flex;
    transform: scale(1.2);
    margin: 70px 0px 100px 0px;
    justify-content: center;
    align-items: center;
    width: 20%;
    flex-direction: column;
}

.cardsToOwnContent {
    display: flex;
    margin: 20px 0px 100px 0px;
    justify-content: center;
    align-items: center;
    width: 360px;
    flex-direction: column;
    background-color: var(--surface-b);
    padding: 20px;
    border-radius: 5px;
}

.spotlightCardsTitle {
    margin-bottom: 100px;
    font-size: 40px;
    font-family: "Indie Flower", Helvetica, Arial, sans-serif;
}

.cardToTradeTitle {
    margin-top: 20px;
    font-size: 40px;
    font-family: "Indie Flower", Helvetica, Arial, sans-serif;
}

.widgets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    justify-content: center;
    gap: 40px;
    width: 80%;
}

.inventory {
    font-size: 2.5rem;
    font-family: "Indie Flower", Helvetica, Arial, sans-serif;
    min-height: 50%;
}

.inventoryItems {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    min-height: 350px;
    max-height: 50vh;
    padding: 20px;
    background-color: var(--surface-b);
    justify-content: center;
    margin-bottom: 20px;
}

.item {
    margin: 20px;
    padding: 10px;
}

.hvr-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
}

.profileStats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.profileStat {
    background-color: var(--surface-b);
    width: 250px;
    height: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.statLink {
    color: #ba68c8;
    text-decoration: none;
    padding: 10px 0px;
    border-top: 1px solid var(--text-color);
}

.topSection {
    display: flex;
    width: 80%;
    justify-content: space-around;
    align-items: center;
    margin-top: 60px;
}

.comingSoon {
    color: var(--text-color);
    text-decoration: none;
    padding: 10px 0px;
    border-top: 1px solid var(--text-color);
}

.statNumber {
    font-size: 50px;
}
</style>