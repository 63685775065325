import { defineStore } from "pinia";
import { useFeatureFlagsStore } from './feature_flags'
import { dangPacksService } from "../services/DangPacksService";

export const useLeaderboardsStore = defineStore("leaderboards", {
    state: () => {
        return {
            cards: [],
            owners: [],
            authors: [],
            counterfeiters: [],
            cosmicBrownies: [],
            cosmicRares: [],
        }
    },
    actions: {
        async fetchSeasonCardsSummary(season = 1) {
            const featureFlagsStore = useFeatureFlagsStore()

            try {
                let response = await dangPacksService.getSeasonCardsSummary(season)
                let summary = response.data
                if(!featureFlagsStore.season2){summary = summary.filter(item => item.season !== 2)}
                this.cards = summary
            } catch (error) {
                console.log(error)
            }
        },
        async fetchSeasonOwnerSummary() {
            try {
                let response = await dangPacksService.getSeasonOwnerSummary()
                this.owners = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async fetchSeasonAuthorSummary() {
            try {
                let response = await dangPacksService.getSeasonAuthorSummary()
                this.authors = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async fetchCounterfeiters() {
            try {
                let response = await dangPacksService.getCounterfeiters()
                this.counterfeiters = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async fetchCosmicBrownies() {
            try {
                let response = await dangPacksService.getCosmicBrownies()
                this.cosmicBrownies = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async fetchCosmicRares() {
            try {
                let response = await dangPacksService.getCosmicRares()
                this.cosmicRares = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
    getters: {
        filteredAuthors(state){
            return state.authors.filter(authorObj => authorObj.author !== "" && authorObj.author !== "DG")
        },
        getMinted(state){
            return (season, rarity)=>{
                let card = state.cards.find(card => card.season === season && card.rarity === rarity)
                return card ? card.minted : null
            }
        },
        getTotal(state){
            return (season, rarity)=>{
                let card = state.cards.find(card => card.season === season && card.rarity === rarity)
                return card ? card.total : null
            }
        }
    }
});