<template>
    <div class="dangcard-wrapper" :class="renderQuality? 'quality-card': 'performant-card'">
        <Tilt
            v-if="card"
            :class="[!hideRarity ? card?.rarity : 'base']"
            :options="tiltOptions"
            class="tilt-card"
            @click="toggle"
        >
            <DangCardGraded :card="card" v-if="card?.case === 'graded'"></DangCardGraded>
            <DangCardCenterpiece :card="card" v-else-if="card?.case === 'centerpiece'"></DangCardCenterpiece>
            <DangCardSleeved :card="card" v-else-if="card?.case === 'sleeve'"></DangCardSleeved>
            <DangCardBase :card="card" v-else></DangCardBase>
            <slot></slot>
        </Tilt>
        <div v-else>
            <Tilt :options="tiltOptions" class="tilt-card">
                <DangCardBack>
                    <slot></slot>
                </DangCardBack>
            </Tilt>
        </div>
        <OverlayPanel ref="op" :dismissable="true">
            <div>Go To</div>
            <Button label="Card" @click="goToCard()" :disabled="onCard()" />
            <Button label="Owner" @click="goToOwner()" :disabled="onOwner()" v-if="card.owner" />
            <Button label="Author" @click="goToAuthor()" :disabled="onAuthor()" v-if="card.author" />
            <Button label="Trade" @click="tradeForCard()" v-if="card.owner" />
        </OverlayPanel>
    </div>
</template>
<script>
import DangCardGraded from "./DangCardGraded.vue";
import DangCardCenterpiece from "./DangCardCenterpiece.vue";
import DangCardSleeved from "./DangCardSleeved.vue";
import DangCardBase from "./DangCardBase.vue";
import DangCardBack from "./DangCardBack.vue";
import OverlayPanel from "primevue/overlaypanel";
import Button from "primevue/button";
import Tilt from "./Tilt.vue";

export default {
    name: "DangCard",
    components: {
        Tilt,
        OverlayPanel,
        Button,
        DangCardBack,
        DangCardBase,
        DangCardSleeved,
        DangCardCenterpiece,
        DangCardGraded
    },
    props: {
        card: Object,
        showActions: { type: Boolean, default: true },
        hideRarity: { type: Boolean, default: false },
        renderQuality: { type: Boolean, default: false }
    },
    data: function () {
        return {
            tiltOptions: {
                glare: true,
                "max-glare": 0.5,
                scale: window.innerWidth >= 760 ? 1.2 : 1,
            },
        };
    },
    mounted() { },
    methods: {
        toggle(event) {
            if (this.showActions === true) {
                this.$refs.op.toggle(event);
            }
        },
        onCard() {
            if (!this.card) {
                return null;
            }
            return (
                this.$router.currentRoute.value.path.toLowerCase() ===
                `/season/${this.card.season}/cards/${this.card.title}`.toLowerCase()
            );
        },
        onOwner() {
            if (!this.card) {
                return null;
            }
            return (
                this.$router.currentRoute.value.path.toLowerCase() === `/users/${this.card.owner}`.toLowerCase()
            );
        },
        onAuthor() {
            if (!this.card) {
                return null;
            }
            return (
                this.$router.currentRoute.value.path.toLowerCase() === `/users/${this.card.author}`.toLowerCase()
            );
        },
        goToCard() {
            this.$router.push(`/season/${this.card.season}/cards/${this.card.title}`);
        },
        goToOwner() {
            this.$router.push(`/users/${this.card.owner}/cards`);
        },
        goToAuthor() {
            this.$router.push(`/users/${this.card.author}/cards?view=authored`);
        },
        tradeForCard() {
            this.$router.push(`/users/${this.card.owner}/trades/new?user=${this.card.owner}&cardId=${this.card.cardId}`);
        },
    },
};
</script>


<style scoped>
.quality-card{
    will-change:auto;
}
.performant-card{
    will-change: transform, opacity;
}
.dangcard-wrapper,
.tilt-card {
    transform-style: preserve-3d;
    width: fit-content;
}

.rainbow::after {
    content: "";
    background: linear-gradient(
            45deg,
            #ff0000 0%,
            #ff9a00 10%,
            #d0de21 20%,
            #4fdc4a 30%,
            #3fdad8 40%,
            #2fc9e2 50%,
            #1c7fee 60%,
            #5f15f2 70%,
            #ba0cf8 80%,
            #fb07d9 90%,
            #ff0000 100%
        )
        repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

.gold::after {
    content: "";
    background: linear-gradient(
            45deg,
            #bf953f 0%,
            #fcf6ba 25%,
            #b38728 50%,
            #aa771c 75%
        )
        repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

.black::after {
    content: "";
    background: linear-gradient(45deg, #040404 0%, #000000 50%, #656565 75%)
        repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

.silver::after {
    content: "";
    background: linear-gradient(
            45deg,
            #70706f 0%,
            #7d7d7a 20%,
            #8e8d8d 40%,
            #a1a2a3 60%,
            #b3b6b5 80%,
            #bec0c2 100%
        )
        repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

.red::after {
    content: "";
    background: linear-gradient(45deg, #3f0d12 0%, #a71d31 75%) repeat 0% 0% /
        300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

.pink::after {
    content: "";
    background: linear-gradient(45deg, #b54a7a 0%, #e290bc 50%, #ffacf4 75%)
        repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

.promo::after {
    content: "";
    background: linear-gradient(45deg, #006095 0%, #06afb1 50%, #7bc7df 75%)
        repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: 1px;
    filter: blur(8px);
    z-index: -1;
    animation: rgb 6s linear infinite;
}

@keyframes rgb {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>