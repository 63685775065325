<template>
  <div class="control-centre">
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <TabView :activeIndex="activeIndex">
        <TabPanel header="Grant Items">
          <div>
            <div class="general-settings">
              <label>Select User</label>
              <Autocomplete
                v-model="searchValue"
                :suggestions="searchedUsers?.map((user) => user.display_name)"
                @complete="searchForUser()"
                @item-select="getInventory()"
                placeholder="Search User"
                class="header-searchBar"
                :completeOnFocus="searchValue?.length > 0"
                :inputStyle="{ width: '100%', textAlign: 'center' }"
                autoHighlight
                :disabled="inventoryStore.fetching"
              />
            </div>
            <div v-if="selectedUser">
              <hr />
              <div class="user-header">
                <h1>Selected User: {{ selectedUser.display_name }}</h1>
                <Button @click="refreshInventory" icon="pi pi-refresh"></Button>
              </div>

              <div class="general-settings">
                <div>
                  <div class="general-settings">
                    <label>Select Item</label>
                    <Dropdown
                      v-model="selectedItem"
                      :options="itemOptions"
                      :disabled="inventoryStore.fetching"
                    />
                  </div>
                  <Button
                    style="margin-top: 1rem"
                    @click="grantItem"
                    :disabled="!selectedItem || inventoryStore.fetching"
                    >Grant Item</Button
                  >
                  <h6>* After granting, await confirmation toast</h6>
                </div>
                <div>
                  <div
                    class="inventory-table-container"
                    v-if="inventoryStore.unusedItemsByUserId(selectedUser.id)"
                  >
                    <DataTable
                      :value="
                        inventoryStore.unusedItemsByUserId(selectedUser.id)
                      "
                      responsiveLayout="scroll"
                      class="inventory-table"
                    >
                      <Column header="Item">
                        <template #body="slotProps">
                          <div class="inventory-item">
                            {{ slotProps.data.type }}
                          </div>
                        </template>
                      </Column>
                      <Column header="Type">
                        <template #body="slotProps">
                          <div class="inventory-type">
                            {{ slotProps.data.value.type }}
                          </div>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel header="Feature Flags">
          <div>
            <div
              v-if="Object.keys(configStore.feature_flags).length > 0"
              class="feature-flags"
            >
              <div
                v-for="(value, key) in configStore.feature_flags"
                :key="key"
                class="feature-flag-toggles"
              >
                <label>{{ key }}</label>
                <InputSwitch
                  v-model="featureFlags[key]"
                  @click="toggleFeature(key)"
                  :disabled="configStore.fetching"
                />
              </div>
            </div>
            <div v-else>No features to be toggled at this time</div>
          </div>
        </TabPanel>

        <TabPanel header="Other Settings">
          <div class="general-settings">
            <label>Maintenance Mode</label>
            <InputSwitch
              v-model="maintenanceModeToggle"
              @click="toggleMaintenance"
              :disabled="configStore.fetching"
            />
            <label>Active Series</label>
            <Dropdown
              v-model="selectedSeries"
              :options="series"
              @change="updateActiveSeason"
              :disabled="configStore.fetching"
            />
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import { useConfigStore } from "../stores/config";
import { useAuthStore } from "../stores/auth";
import { useInventoryStore } from "../stores/inventory";
import Autocomplete from "primevue/autocomplete";
import { dangPacksService } from "../services/DangPacksService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { usePacksStore } from "../stores/packs";
export default {
  name: "AdminControlCentre",
  components: {
    LoadingIndicator,
    Button,
    TabView,
    TabPanel,
    InputSwitch,
    Dropdown,
    Autocomplete,
    DataTable,
    Column,
  },
  data() {
    return {
      loading: true,
      activeIndex: 0,
      maintenanceModeToggle: false,
      selectedSeries: 2,
      series: [1, 2, 2.5],
      selectedItem: "",
      itemOptions: ["Sleeve", "Centerpiece", "Graded"],
      featureFlags: {},
      searchedUsers: [],
      searchValue: "",
      selectedUser: null,
    };
  },
  async created() {
    await this.$router.isReady();

    // If we're not logged in, start the login flow
    if (
      !this.authStore.isLoggedIn ||
      !["dan", "dev", "admin"].includes(this.authStore.profile.role)
    ) {
      this.$router.replace({ path: "/" });
      return;
    }
    this.dangPacksService = dangPacksService;

    this.maintenanceModeToggle = this.configStore.maintenance_mode;
    this.selectedSeries = this.configStore.active_season;
    Object.keys(this.configStore.feature_flags).forEach(
      (key) => (this.featureFlags[key] = this.configStore.feature_flags[key])
    );

    this.loading = false;
  },
  async mounted() {},
  computed: {
    ...mapStores(useConfigStore),
    ...mapStores(useAuthStore),
    ...mapStores(useInventoryStore),
    ...mapStores(usePacksStore),
  },
  methods: {
    async toggleMaintenance() {
      await this.configStore.toggleMaintenanceMode();

      if (this.configStore.error) {
        this.$toast.add({
          severity: "error",
          summary: "Configuration Update Failed",
          detail: this.configStore.error,
          life: 5000,
        });
        this.configStore.error = null;
        return;
      }

      this.maintenanceModeToggle = this.configStore.maintenance_mode;
      this.$toast.add({
        severity: "success",
        summary: "Configuration Updated",
        detail: `Maintenance Mode Toggled`,
        life: 3000,
      });
    },
    async updateActiveSeason() {
      if (this.selectedSeries === this.configStore.active_season) {
        return;
      }
      await this.configStore.setActiveSeason(this.selectedSeries);

      if (this.configStore.error) {
        this.$toast.add({
          severity: "error",
          summary: "Configuration Update Failed",
          detail: this.configStore.error,
          life: 5000,
        });
        this.configStore.error = null;
        return;
      }

      this.selectedSeries = this.configStore.active_season;
      this.$toast.add({
        severity: "success",
        summary: "Configuration Updated",
        detail: `Active Season Updated`,
        life: 3000,
      });
    },
    async toggleFeature(feature) {
      await this.configStore.updateProp(
        feature,
        !this.configStore.feature_flags[feature]
      );

      if (this.configStore.error) {
        this.$toast.add({
          severity: "error",
          summary: "Configuration Update Failed",
          detail: this.configStore.error,
          life: 5000,
        });
        this.configStore.error = null;
        return;
      }

      Object.keys(this.configStore.feature_flags).forEach(
        (key) => (this.featureFlags[key] = this.configStore.feature_flags[key])
      );
      this.$toast.add({
        severity: "success",
        summary: "Configuration Updated",
        detail: `${feature} Toggle Updated`,
        life: 3000,
      });
    },
    searchForUser() {
      this.dangPacksService.users
        .get(`${this.searchValue}%`)
        .then((results) => (this.searchedUsers = results.data));
    },
    async getInventory() {
      console.log("fetch user inventory");
      this.selectedUser = this.searchedUsers.find(
        (user) => user.display_name === this.searchValue
      );

      await this.inventoryStore.getUserInventory(this.selectedUser.id, true);
      await this.packsStore.getUserPacks(this.selectedUser.id, true);
    },
    async grantItem() {
      let itemType = null;
      let item = this.selectedItem;
      let user = this.selectedUser;
      if (this.selectedItem === "Sleeve") {
        itemType = { type: "sleeve" };
      } else if (this.selectedItem === "Centerpiece") {
        itemType = { type: "centerpiece" };
      } else if (this.selectedItem === "Graded") {
        itemType = { type: "graded" };
      }

      await this.inventoryStore.grantInventoryItem(
        this.selectedUser.id,
        "case",
        itemType
      );
      await this.inventoryStore.getUserInventory(this.selectedUser.id, true);

      if (this.inventoryStore.error) {
        this.$toast.add({
          severity: "error",
          summary: `Inventory Item Grant Failed`,
          detail: this.inventoryStore.error,
          life: 5000,
        });
        this.inventoryStore.error = null;
        return;
      }

      this.$toast.add({
        severity: "success",
        summary: `Inventory Item Granted`,
        detail: `${item} added to ${user.display_name}'s Inventory`,
        life: 3000,
      });
    },
    async refreshInventory() {
      this.inventory = await this.inventoryStore.getUserInventory(
        this.selectedUser.id,
        true
      );
      this.packs = await this.packsStore.getUserPacks(
        this.selectedUser.id,
        true
      );

      this.$toast.add({
        severity: "success",
        summary: `Inventory Refreshed`,
        detail: `Updated ${this.selectedUser.display_name}'s Inventory`,
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
.control-centre {
  padding: 3rem;
}

.general-settings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.feature-flags {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-flag-toggles {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.user-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}
</style>
