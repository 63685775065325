<template>
  <h2>{{ `(${packsPages.length} ${packsPages.length === 1 ? 'pack' : 'packs'})` }}</h2>
  <span>{{ headerText }}</span>
  <div class="fill-height">
    <div v-for="(pack, index) in slicedPacks" :key="index">
      <div class="pack-header">
        <h3>{{ `Pack ${index + 1 + start}` }}</h3>
        <a v-if="configStore.getConfig('ff_stream_links', true) && pack?.[0]?.url" :href="pack?.[0]?.url" target="_blank" rel="noopener noreferrer">
          <Button class="p-button-rounded p-button-text twitch-button">
            <font-awesome-icon :icon="['fab', 'twitch']" />
          </Button>
        </a>
      </div>
      <div class="cardGrid">
        <div v-for="card in pack" :key="card.cardId">
          <DangCard :card="card" :showActions="!!card" />
        </div>
      </div>
    </div>
  </div>
  <Paginator v-model:first="start" v-model:rows="rows" :totalRecords="packsPages.length" @page="onPage($event)"
    :rowsPerPageOptions="[25, 50, 100, 200, 300]" :alwaysShow="false" />
</template>
<script>
import { mapStores } from "pinia";
import DangCard from "./DangCard.vue";
import Paginator from "primevue/paginator";
import Button from "primevue/button";
import { useConfigStore } from "../stores/config";
export default {
  name: "UserCardsPacks",
  components: {
    DangCard,
    Paginator,
    Button
  },
  props: {
    cards: Array,
    allPacks: Map
  },
  data: function () {
    return {
      packsPages: [],
      slicedPacks: [],
      start: 0,
      end: 20,
      rows: 25,
      headerText: ''
    };
  },
  created() {
    let sortedCards = [...this.cards];
    sortedCards.sort(this.sortCards);
    this.packsPages = [];

    let currentPack = null;
    let packObj = null;
    for (let card of sortedCards) {
      if (currentPack !== card.packid) {
        this.packsPages.push([]);
        packObj = this.allPacks.get(card.packid)
      }
      currentPack = card.packid;
      let currentPackIndex = this.packsPages.length - 1;
      if(packObj){card.url = packObj.url}
      this.packsPages[currentPackIndex].push(card);
    }
    this.slicePacks();
  },
  mounted() { },
  watch: {
    cards: function () {
      let sortedCards = [...this.cards];
      sortedCards.sort(this.sortCards);
      this.packsPages = [];

      let currentPack = null;
      let packObj = null
      for (let card of sortedCards) {
        if (currentPack !== card.packid) {
          this.packsPages.push([]);
          packObj = this.allPacks.get(card.packid)
        }
        currentPack = card.packid;
        let currentPackIndex = this.packsPages.length - 1;
        if(packObj){card.url = packObj.url}
        this.packsPages[currentPackIndex].push(card);
      }
      this.slicePacks();
    },
  },
  methods: {
    sortCards(a, b) {
      return this.comparePack(a, b) || -this.compareRarity(a, b);
    },
    compareRarity(card1, card2) {
      return card2.rarerank - card1.rarerank;
    },
    comparePack(card1, card2) {
      return card1.packid - card2.packid;
    },
    slicePacks() {
      this.slicedPacks = this.packsPages.slice(this.start, this.end);
      this.headerText = "Showing " + (this.start + 1) + " to " + (this.end > this.packsPages.length ? this.packsPages.length : this.end);
    },
    onPage(event) {
      this.start = event.page * this.rows;
      this.end = this.start + this.rows;

      this.slicePacks();
    },
  },
  computed:{
    ...mapStores(useConfigStore)
  }
};
</script>

<style scoped>
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}

.pack-header {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.twitch-button {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 3px;
}

.fill-height {
  margin-top: 20px;
  min-height: calc(100vh - 509px);
}

h3 {
  margin: 10px 0;
}
</style>