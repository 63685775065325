<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <SubHeader header="Inventory">
        <template #left>
          <Button @click="backToProfile" icon="pi pi-chevron-left"></Button>
          <div class="unopenedPacks">Unopened Packs: {{ packsStore.sealedPacksByUserId(user.id).length }}</div>
        </template>
        <template #right>
          <Button @click="refreshInventory" icon="pi pi-refresh"></Button>
        </template>
      </SubHeader>
      <hr />
      <div class="inventory">
        <div class="inventory-table-container" v-if="inventoryStore.unusedItemsByUserId(user.id)">
          <DataTable :value="inventoryStore.unusedItemsByUserId(user.id)" responsiveLayout="scroll" class="inventory-table">
            <Column header="Item">
              <template #body="slotProps">
                <div class="inventory-item">{{ slotProps.data.type }}</div>
              </template>
            </Column>
            <Column header="Type">
              <template #body="slotProps">
                <div class="inventory-type">{{ slotProps.data.value.type }}</div>
              </template>
            </Column>
            <Column
              header="Actions"
              v-if="authStore.isLoggedIn && user.id === authStore.profile.id"
            >
              <template #body="slotProps">
                <Button @click="useItem(slotProps.data.itemId, slotProps.data.type)">Use</Button>
              </template>
            </Column>
          </DataTable>
        </div>
        <div v-else>
          You have no Inventory Items
          <br />You can get some by redeeming channel points!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { mapStores } from 'pinia'

import Button from "primevue/button";
import DataTable from "primevue/datatable"
import Column from "primevue/column";

import LoadingIndicator from "../components/LoadingIndicator.vue";
import SubHeader from "../components/SubHeader.vue";
import { usePacksStore } from '../stores/packs';
import { useUsersStore } from '../stores/users';
import { useInventoryStore } from "../stores/inventory";

export default {
  name: "UserInventory",
  components: {
    Button,
    DataTable,
    Column,
    LoadingIndicator,
    SubHeader
  },
  data() {
    return {
      user: null,
      loading: true,
    };
  },
  async created() {
    await this.$router.isReady();

    this.user = await this.usersStore.getUserByName(this.$route.params.username)
    await this.inventoryStore.getUserInventory(this.user.id)
    await this.packsStore.getUserPacks(this.user.id)

    this.loading = false;
  },

  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useUsersStore),
    ...mapStores(useInventoryStore),
    ...mapStores(usePacksStore),
  },
  methods: {
    useItem(itemId, type) {
      this.$router.push(`/users/${this.authStore.profile.display_name}/inventory/use/${type}/${itemId}`);
    },
    backToProfile() {
      this.$router.push(`/users/${this.user.display_name}`)
    },
    async refreshInventory() {
      this.loading = true;
      this.inventory = await this.inventoryStore.getUserInventory(this.user.id, true)
      this.packs = await this.packsStore.getUserPacks(this.user.id, true)
      this.loading = false;
    },
  }
};
</script>

<style scoped>
.inventory {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.inventory-header {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  grid-template-areas: "header-left inventory-header-text inventory-header-actions";
}

.inventory-header-actions {
  grid-area: inventory-header-actions;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inventory-header-text {
  grid-area: inventory-header-text;
}

.inventory-table {
  width: 60vw;
}
.inventory-item {
  text-transform: capitalize;
}
.inventory-type {
  text-transform: capitalize;
}
.unopenedPacks {
  font-family: "Indie Flower", Helvetica, Arial, sans-serif;
  font-size: 34px;
}
</style>
