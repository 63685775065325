<template>
  {{headerText}}
    <div class="cardGrid">
      <div v-for="card in slicedCards" :key="card.cardId" @click="cardClicked(card)">
        <DangCard :card="card" :showActions="!!card && !disableActions" />
      </div>
    </div>
  <Paginator 
    v-model:first="start" 
    v-model:rows="rows" 
    :totalRecords="sortedCards.length" 
    @page="onPage($event)" 
    :rowsPerPageOptions="[25,50,100,200,300]"
    :alwaysShow="false"
  />
</template>
<script>
import DangCard from "./DangCard.vue";
import Paginator from 'primevue/paginator'
export default {
  name: "FlatUserCards",
  components: {
    DangCard,
    Paginator
  },
  props: {
    cards: Array,
    disableActions: Boolean,
  },
  emits: ["card-clicked"],
  data: function () {
    return {
      sortedCards: [],
      slicedCards:[],
      start: 0,
      end: 100,
      rows: 100,
      headerText: ''
    };
  },
  created() {
    this.sortedCards = this.cards;
    this.sortedCards.sort(this.sortCards);
    this.sliceCards();
  },
  mounted() { },
  watch: {
    cards: function () {
      this.sortedCards = this.cards;
      this.sortedCards.sort(this.sortCards);
      this.sliceCards();
    },
  },
  methods: {
    sortCards(a, b) {
      return (
        this.compareRarity(a, b) ||
        this.compareSeries(a, b) ||
        this.compareTitle(a, b) ||
        this.comparePrint(a, b)
      );
    },
    compareSeries(card1, card2) {
      if (card1.series === card2.series) return 0;
      else if (card1.series === "ultra") return -1;
      else if (card2.series === "ultra") return 1;
      else return card1.series > card2.series ? 1 : -1;
    },
    compareTitle(card1, card2) {
      if (card1.title === card2.title) return 0;
      else return card1.title > card2.title ? 1 : -1;
    },
    compareRarity(card1, card2) {
      return card2.rarerank - card1.rarerank;
    },
    comparePrint(card1, card2) {
      return card1.cardnum - card2.cardnum;
    },
    onPage(event) {
      this.start = event.page * this.rows;
      this.end = this.start + this.rows;

      this.sliceCards();
    },
    sliceCards() {
      this.slicedCards = this.sortedCards.slice(this.start, this.end);
      this.headerText = "Showing " + (this.start + 1) + " to " + (this.end > this.sortedCards.length ? this.sortedCards.length : this.end);
    },
    cardClicked(card) {
      this.$emit('card-clicked', card);
    },
  },
};
</script>

<style scoped>
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}
</style>