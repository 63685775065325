<template>
  <div class="subheader">
    <div class="subheader-left">
      <slot name="left"></slot>
    </div>
    <div class="subheader-middle">
      <h1 v-if="header !== undefined && header !== null">{{ header }}</h1>
      <slot name="middle"></slot>
    </div>
    <div class="subheader-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "SubHeader",
  props: {
    header: String,
  },
};
</script>

<style scoped>
.subheader {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  grid-template-areas: "subheader-left subheader-middle subheader-right";
  padding-top: 12px;
}

.subheader-left, .subheader-middle, .subheader-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>