<template>
  <div class="graded-card">
    <div class="base-card-wrapper">
      <DangCardBase :card="card" class="base-card" />
    </div>

    <img src="../assets/card-cases/graded.png" class="graded-base" />

    <div class="grade-label" :class="card?.grade_overall && parseInt(card.grade_overall) === 10 ? 'gold-label': undefined">
      <div class="grade-certbody">DPOG DangPacks Official Grading</div>
      <div :class="['grade-details', perfectTen(card) && 'black-label']">
        <div class="label-logo">
          <img
            :src="card?.grade_overall === '10' ? '/images/gold-logo.png' : '/images/red-logo.png'"
            class="label-logo-img"
          />
        </div>
        <div class="grade-summary">
          <div class="grade-summary-label">Overall</div>
          <div class="grade-summary-score">{{ card.grade_overall }}</div>
        </div>
        <div class="grade-details-right">
          <div class="grade-details-card">
            <div class="card-title">{{ card.title }}</div>
            <div class="card-season">Season {{ card.season }}</div>
            <div class="card-rarity">Rarity: {{ card.rarity }}</div>
          </div>
          <div class="qualities">
            <div class="qualities-label">Centering</div>
            <div class="qualities-label">Surface</div>
            <div class="qualities-label">Corners</div>
            <div class="qualities-label">Edges</div>
            <div class="qualities-score">{{ card.grade_centering }}</div>
            <div class="qualities-score">{{ card.grade_surface }}</div>
            <div class="qualities-score">{{ card.grade_corners }}</div>
            <div class="qualities-score">{{ card.grade_edges }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DangCardBase from "./DangCardBase.vue";

export default {
  name: "DangCardGraded",
  components: {
    DangCardBase
  },
  props: {
    card: Object,
  },
  methods: {
    perfectTen: (card) => {
      return (card?.grade_centering === '10' && card?.grade_surface === '10' && card?.grade_corners === '10' && card?.grade_edges === '10')
    }
  }
};
</script>

<style scoped>
.graded-card {
  border-radius: 50px;
  position: relative;
  width: 393px;
  height: 663px;
  transform-style: preserve-3d;
}
.graded-base {
  position: absolute;
  left: 0;
  top: 0;
  height: 650px;
  width: auto;
  transform: translateZ(20px);
}
.grade-label {
  position: absolute;
  top: 36px;
  left: 40px;
  transform: translateZ(20px);
  background-color: white;
  width: 306px;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.black-label {
  background: black !important;
  color: #c09f57 !important;
  opacity: .99;
}

.gold-label {
  background: linear-gradient(
      45deg,
      #f0ba50 0%,
      #fcf6ba 25%,
      #c09f57 50%,
      #fbf5b7 75%
    )
    repeat 0% 0% / 300% 300%;
  color: black;
  animation: rgb 30s linear infinite;
}

.grade-certbody {
  background-color: #e90020;
  color: white;
  width: 100%;
  font-size: 1.2rem;
  padding: 2px 0px;
  font-weight: bold;
  text-align: center;
  mix-blend-mode: multiply;
}

.grade-details {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0px 5px;
}
.grade-summary {
  border: 2px black solid;
  border-radius: 10px;
  padding: 6px;
}
.grade-summary-label {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0px;
}
.grade-summary-score {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -1px;
}
.grade-details-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.grade-details-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.card-title {
  font-weight: bold;
  font-size: 1.3rem;
}
.card-rarity {
  font-size: 15px;
  text-transform: capitalize;
}
.card-season {
  font-size: 15px;
}

.qualities {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0px 5px;
  margin: 5px 0px;
}
.qualities-label {
  font-size: 12px;
  font-weight: bold;
}
.qualities-score {
  font-size: 12px;
  font-weight: bold;
}
.label-logo {
  position: absolute;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  margin: auto;
  z-index: -1;
}

.label-logo-img {
  height: 100%;
  width: auto;
}

.base-card-wrapper {
  left: 0px;
  position: absolute;
  top: 188px;
  width: 100%;
  height: 427px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 9px;
  transform-style: preserve-3d;
  transform: perspective(1000px);
}
</style>