<template>
  <div class="trades-view">
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <SubHeader header="Trades">
        <template #left>
          <Button @click="backToProfile" icon="pi pi-chevron-left"></Button>
        </template>
        <template #right>
          <Button @click="newTrade" v-if="authStore.isLoggedIn">Start Trade</Button>
          <Button @click="refreshTrades" icon="pi pi-refresh"></Button>
        </template>
      </SubHeader>
      <hr />
      <div class="trades">
        <TradesOverviewIncoming
          :trades="incomingTrades"
          :user="user.display_name"
          :loading="tradesStore.fetching"
        />
        <TradesOverviewOutgoing
          :trades="outgoingTrades"
          :user="user.display_name"
          :loading="tradesStore.fetching"
        />
      </div>
      <TradesOverviewHistory
        class="history"
        :trades="completedTrades"
        :user="user.display_name"
        :loading="tradesStore.fetching"
      />
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import Button from "primevue/button";


import { useAuthStore } from "@/stores/auth";
import { useTradesStore } from "@/stores/trades";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import TradesOverviewHistory from "@/components/TradesOverviewHistory.vue";
import TradesOverviewIncoming from "@/components/TradesOverviewIncoming.vue";
import TradesOverviewOutgoing from "@/components/TradesOverviewOutgoing.vue";
import SubHeader from "@/components/SubHeader.vue";
import { useUsersStore } from '../../stores/users';

export default {
  name: "UserTrades",
  components: {
    Button,
    LoadingIndicator,
    TradesOverviewHistory,
    TradesOverviewIncoming,
    TradesOverviewOutgoing,
    SubHeader
  },
  data: () => ({
    user: null,
    trades: [],
    loading: true
  }),
  async created() {
    await this.$router.isReady();
    this.user = await this.usersStore.getUserByName(this.$route.params.username)
    await this.tradesStore.getUserTrades(this.user.id)
    this.loading = false
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useUsersStore),
    ...mapStores(useTradesStore),
    incomingTrades() { return this.tradesStore.tradesByUserId(this.user.id).filter(trade => trade.to_id === this.user.id && trade.status === 'pending').sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) },
    outgoingTrades() { return this.tradesStore.tradesByUserId(this.user.id).filter(trade => trade.from_id === this.user.id && trade.status === 'pending').sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) },
    completedTrades() { return this.tradesStore.tradesByUserId(this.user.id).filter(trade => trade.status !== 'pending').sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) },
  },
  methods: {
    newTrade() {
      this.$router.push(`/users/${this.authStore.profile.display_name}/trades/new`);
    },
    backToProfile() {
      this.$router.push(`/users/${this.user.display_name}`)
    },
    async refreshTrades() {
      this.loading = true
      await this.tradesStore.fetchUserTrades(this.user.id)
      this.loading = false
    }
  },

};
</script>

<style scoped>
.trades-header {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  grid-template-areas: "header-left header-text header-actions";
}

.trades-header-actions {
  grid-area: header-actions;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.trades-header-text {
  grid-area: header-text;
}
.trades {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
