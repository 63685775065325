import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useAdminPreviewStore = defineStore("adminPreview", {
    state: () => {
        return {
            cards: new Map(),
        }
    },
    actions: {
        /**
         * Fetch Unique Cards within a Season
         */
        async fetchSeasonCards() {
            try {
                let response = await dangPacksService.getSeasonCardPreviewPrints()
                this.cards = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
    getters: {
        getUniqueCards: (state) => {
            return Array.from(
                Array.from(state.cards.values())
                    .reduce((titles, card) => {
                        titles.add(card.title);
                        return titles
                    }, new Set())
            ).sort((a, b) => {
                return a > b ? 1 : -1;
            })
        },
        getCards: (state) => {
            return (title) => state.cards.filter(card => card.title === title).sort((a,b) => b.rarerank - a.rarerank)
        },
        getAllCards: (state) => {
            return () => state.cards.sort((a,b) => b.packid - a.packid)
        },
    }
});