<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <div v-if="seasonPreviewStore.cards.length > 0">
        <h1>Series 2 Kick Off</h1>
        <hr />
        <div class="btn-grid">
          <Button
            @click="changeSelected(title)"
            :disabled="selectedCard === title"
            class="p-button-info"
            v-for="title of seasonPreviewStore.getUniqueCards"
            :key="title"
          >{{ title }}</Button>
        </div>
        <hr />

        <div class="cardGrid">
          <DangCard
            :card="card"
            :showActions="!!card"
            v-for="(card, index) in seasonPreviewStore.getCards(selectedCard)"
            :key="`${card.title}-${index}`"
          />
        </div>
      </div>
      <div v-else>
        <h1>No Season {{ season }} Cards have been Minted Yet - Standby!</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { useSeasonPreviewStore } from "../stores/season_preview";
import { mapStores } from 'pinia'
import DangCard from "../components/DangCard.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";

import Button from "primevue/button";

export default {
  name: "CardMintsPreview",
  components: {
    DangCard,
    LoadingIndicator,
    Button
  },
  data() {
    return {
      season: null,
      loading: true,
      selectedCard: "Garden of Gamba",
    };
  },
  async created() {
    this.season = this.$route.params.seasonId;
    await this.seasonPreviewStore.fetchSeasonCards(this.season)
    this.selectedCard = this.seasonPreviewStore.getUniqueCards[0]
    this.loading = false
  },
  computed: {
    ...mapStores(useSeasonPreviewStore)
  },
  methods: {
    changeSelected(title) {
      this.selectedCard = title
    }
  }
};
</script>

<style scoped>
.btn-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.groupTitle {
  text-transform: capitalize;
}
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  grid-gap: 40px;
  padding: 40px;
  align-content: center;
  justify-items: center;
}
</style>
