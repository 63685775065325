import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useSeasonCardsStore = defineStore("seasonCards", {
    state: () => {
        return {
            cards: new Map(),
            seasons: new Set(),
        }
    },
    actions: {
        /**
         * Fetch Unique Cards within a Season
         */
        async fetchSeasonCards(season = 1) {
            try {
                let response = await dangPacksService.getSeasonCards(season)
                this.cards.set(season, response.data)
                this.seasons.add(season)
            } catch (error) {
                console.log(error)
            }
        },
    },
    getters: {
        /**
         * Grabs the highest rarity blank for each card
         */
        getMaxBlanks: (state) => {
            return (season) => state.cards.get(season).map(entry => entry.blanks[0])
        },
        /**
         * Grabs a random blank for each card
         */
        getRandomBlanks: (state) => {
            return (season) => state.cards.get(season).map(entry => entry.blanks[Math.floor(Math.random() * entry.blanks.length)])
        },
    }
});