<template>
    <div>
        <div>
            <div class="header-text">Minted Cards</div>
            <div>{{ `${totalFound} of ${totalMax} Minted` }}</div>
            <div>{{ `${totalMax - totalFound} Remain (${((totalMax - totalFound) / totalMax * 100).toFixed(2)}%)` }}</div>
        </div>
        <DataTable
            :value="cards"
            responsiveLayout="stack"
            breakpoint="500px"
            :scrollable="true"
            scrollHeight="555px"
            :loading="loading"
        >
            <template #loading>Loading records, please wait...</template>
            <Column field="season" header="Season" :sortable="true"></Column>
            <Column field="title" header="Rarity" :sortable="true">
                <template #body="slotProps">{{ slotProps.data.rarity }}</template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="p-column-filter"
                    />
                </template>
            </Column>
            <Column field="minted" header="Minted"></Column>
            <Column field="total" header="Total"></Column>
            <Column field="pct" header="Percent">
                <template
                    #body="slotProps"
                >{{ `${Math.floor(parseInt(slotProps.data.minted) / parseInt(slotProps.data.total) * 100)}%` }}</template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";

export default {
    name: 'LeaderboardsSeasonCardsSummary',
    props: {
        cards: Array,
        loading: Boolean,
    },
    components: {
        DataTable,
        Column,
        InputText,
    },
    data: () => ({
        tableFilter: {
            season: { value: null, matchMode: FilterMatchMode.CONTAINS },
            rarity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    }),
    computed: {
        totalMax() {
            return this.cards.reduce((sum, rank) => (sum += parseInt(rank.total)), 0)
        },
        totalFound() {
            return this.cards.reduce((sum, rank) => (sum += parseInt(rank.minted)), 0)
        },
    }
}
</script>

<style scoped>
</style>