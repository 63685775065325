import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useSeasonPreviewStore = defineStore("seasonPreview", {
    state: () => {
        return {
            cards: new Map(),
        }
    },
    actions: {
        /**
         * Fetch Unique Cards within a Season
         */
        async fetchSeasonCards(season = 1) {
            try {
                let response = await dangPacksService.getSeasonCardPrints(season, '%')
                this.cards = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
    getters: {
        getUniqueCards: (state) => {
            return Array.from(
                Array.from(state.cards.values())
                    .reduce((titles, card) => {
                        titles.add(card.title);
                        return titles
                    }, new Set())
            ).sort((a, b) => {
                return a > b ? 1 : -1;
            })
        },
        getCards: (state) => {
            return (title) => state.cards.filter(card => card.title === title)
        },
    }
});