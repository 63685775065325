<template>
    <Button
        :icon="`pi ${icon}`"
        class="p-button-rounded p-button-text"
        @click="toggle"
        v-tooltip="tooltip"
        v-if="cards?.length > 0"
    />
    <OverlayPanel ref="op">
        <div class="rarity-list">
            <div
                class="rarity"
                v-for="cardType in cardSummary"
                :key="cardType.rarity"
                v-tooltip.left="getTooltipForCardType(cardType)"
            >
                <img
                    class="rarity-image"
                    :src="getRarityImage(cardType.rarity)"
                />
                x {{ cardType.count }}
                <img
                    class="stamp"
                    v-if="cardType.stamped === 'gold'"
                    src="@/assets/card-misc/stamped.png"
                />
                <img
                    class="stamp"
                    v-if="cardType.stamped === 'red'"
                    src="@/assets/card-misc/redStamp.png"
                />
                <img
                    class="stamp"
                    v-if="cardType.stamped === 'blue'"
                    src="@/assets/card-misc/blueStamp.png"
                />
                <img
                    class="graded"
                    src="@/assets/card-cases/graded.png"
                    v-if="cardType.case === 'graded'"
                />
                <span class="grade" v-if="cardType.grade_overall">{{
                    cardType.grade_overall
                }}</span>
                <img
                    class="centerpiece"
                    src="@/assets/card-cases/centerpiece.png"
                    v-if="cardType.case === 'centerpiece'"
                />
                <img
                    class="sleeve"
                    src="@/assets/card-cases/sleeve-lc.png"
                    v-if="cardType.case === 'sleeve'"
                />
            </div>
        </div>
    </OverlayPanel>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import Button from "primevue/button";

import { getRarityImage, rarityLabels } from "@/services/RarityService";

export default {
    name: "CardSummary",
    components: {
        OverlayPanel,
        Button,
    },
    props: {
        cards: {
            type: Array,
            required: true,
        },
        icon: {
            type: String,
            default: "pi-info-circle",
        },
        tooltip: {
            type: String,
        },
    },
    computed: {
        cardSummary() {
            const cardSummary = [];
            this.cards.forEach((card) => {
                const rarityType = cardSummary.find((c) =>
                    this.isSameRarity(c, card)
                );

                if (rarityType) {
                    rarityType.count++;
                    return;
                }

                cardSummary.push({
                    rarity: card.rarity,
                    case: card.case,
                    grade_overall: card.grade_overall,
                    stamped: card.stamped,
                    rarerank: card.rarerank,
                    count: 1,
                });
            });

            const cardSummarySorted = cardSummary.sort(
                (c1, c2) => c2.rarerank - c1.rarerank
            );
            return cardSummarySorted;
        },
    },
    async created() {},
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        getRarityImage,
        isSameRarity(c1, c2) {
            return (
                c1.rarity === c2.rarity &&
                c1.case === c2.case &&
                c1.grade_overall === c2.grade_overall &&
                c1.stamped === c2.stamped
            );
        },
        getTooltipForCardType(cardType) {
            let tooltip = "";

            tooltip += rarityLabels[cardType.rarity];

            if (cardType.stamped) {
                tooltip += ", ";
                tooltip += cardType.stamped[0].toUpperCase() + cardType.stamped.slice(1);
                tooltip += " Stamped";
            }

            if (cardType.case === "graded") {
                tooltip += `, Graded (${cardType.grade_overall})`;
            }

            if (cardType.case === "centerpiece") {
                tooltip += ", Centerpieced";
            }

            if (cardType.case === "sleeve") {
                tooltip += ", Sleeved";
            }

            return tooltip;
        },
    },
};
</script>

<style scoped>
.rarity-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.rarity {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.rarity-image {
    width: 25px;
    margin-right: 5px;
}

.stamp {
    width: 18px;
    position: absolute;
    left: 7px;
    top: 0;
}

.graded {
    width: 25px;
    position: absolute;
    left: 0;
    top: -8px;
}

.grade {
    position: absolute;
    left: 4px;
    top: -9.5px;
    font-size: 10px;
}

.sleeve {
    width: 25px;
    position: absolute;
    left: 0;
}

.centerpiece {
    width: 25px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
</style>
