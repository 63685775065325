<template>
  <div class="dashboard">
    <LoadingIndicator v-if="authStore.fetching" />

    <div class="dashboard-grid" v-else>
            <div class="dashboard-option" v-if="authStore.isLoggedIn">
        <router-link :to="`/users/${authStore.profile.display_name}`">
          <DangCard>
            <h1 class="overlay-text">My Profile</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option" v-if="authStore.isLoggedIn">
        <router-link :to="`/users/${authStore.profile.display_name}/cards`">
          <DangCard>
            <h1 class="overlay-text">My Library</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option" v-if="authStore.isLoggedIn && featureFlagsStore.trades">
        <router-link :to="`/users/${authStore.profile.display_name}/trades`">
          <DangCard>
            <h1 class="overlay-text">Trade</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option" v-if="authStore.isLoggedIn && featureFlagsStore.inventory">
        <router-link :to="`/users/${authStore.profile.display_name}/inventory`">
          <DangCard>
            <h1 class="overlay-text">Inventory</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option" v-if="authStore.isLoggedIn && featureFlagsStore.achievements">
        <router-link :to="`/users/${authStore.profile.display_name}/achievements`">
          <DangCard>
            <h1 class="overlay-text">Achievements</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option" v-if="!authStore.isLoggedIn && featureFlagsStore.login">
        <DangCard @click="authStore.login">
          <h1 class="overlay-text">Log In</h1>
        </DangCard>
      </div>

      <div class="dashboard-option">
        <router-link to="/season/1/cards">
          <DangCard>
            <h1 class="overlay-text">Series 1 Cards</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option">
        <router-link to="/season/2/cards">
          <DangCard>
            <h1 class="overlay-text">Series 2 Cards</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option">
        <router-link to="/season/2.5/cards">
          <DangCard>
            <h1 class="overlay-text">Red vs Blue Cards</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option">
        <router-link to="/leaderboards">
          <DangCard>
            <h1 class="overlay-text">Give me the statistics!</h1>
          </DangCard>
        </router-link>
      </div>

      <div class="dashboard-option">
        <router-link to="/faq">
          <DangCard>
            <h1 class="overlay-text">FAQ</h1>
          </DangCard>
        </router-link>
      </div>

      <div
        class="dashboard-option"
        v-if="authStore.isLoggedIn && ['dan', 'artist', 'dev', 'admin'].includes(authStore.profile.role)"
      >
        <router-link :to="`/admin/cards/builder`">
          <DangCard>
            <h1 class="overlay-text">Card Builder</h1>
          </DangCard>
        </router-link>
      </div>

      <div
        class="dashboard-option"
        v-if="authStore.isLoggedIn && ['dan', 'admin', 'dev'].includes(authStore.profile.role)"
      >
        <router-link :to="`/admin/controlcentre`">
          <DangCard>
            <h1 class="overlay-text">Admin Control Centre</h1>
          </DangCard>
        </router-link>
      </div>
      <div
        class="dashboard-option"
        v-if="authStore.isLoggedIn && ['dan', 'dev'].includes(authStore.profile.role)"
      >
        <router-link :to="`/admin/open-packs`">
          <DangCard>
            <h1 class="overlay-text">Open Packs!</h1>
          </DangCard>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { useFeatureFlagsStore } from "../stores/feature_flags";

import { mapStores } from 'pinia'

import DangCard from "../components/DangCard.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";

export default {
  name: "Dashboard",
  components: {
    DangCard,
    LoadingIndicator
  },
  methods: {
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useFeatureFlagsStore)

  },
};
</script>

<style scoped>
.dashboard {
  height: 100%;
}
.dashboard-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-between;
  gap: 20px;
  margin: 50px;
}
.dashboard-option {
  position: relative;
}
.overlay-text {
  position: absolute;
  left: 0;
  top: 250px;
  width: 100%;
  padding: 0px 20px;
  color: white;
  text-shadow: 2px 2px 0px #000000;
}

</style>
