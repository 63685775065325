import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const useAchievementsStore = defineStore("achievements", {
    state: () => {
        return {
            userAchievements: new Map(),
            achievements: [],
            fetching: false,
            error: null,
        }
    },
    actions: {
        async fetchAchievements() {
            this.fetching = true
            try {
                let response = await dangPacksService.achievements.getAll()
                this.achievements = response.data
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async fetchUserAchievements(userId) {
            this.fetching = true
            try {
                let response = await dangPacksService.achievements.get(userId)
                let achievements = response.data
                this.userAchievements.set(userId, achievements)
            } catch (error) {
                console.log(error)
                this.error = error
            } finally {
                this.fetching = false
            }
        },
        async getAchievements(force_refresh = false) {
            if (!this.achievements.length > 0 || force_refresh) { await this.fetchAchievements() }
            return this.achievements
        },
        async getUserAchievements(userId, force_refresh = false) {
            if (!this.userAchievements.has(userId) || force_refresh) { await this.fetchUserAchievements(userId) }
            return this.userAchievements.get(userId)
        },
    },
    getters: {
        achievementsByUserId: (state) => {
            return (userId) => state.userAchievements.has(userId) ? state.userAchievements.get(userId) : []
        },
        fullAchievementsByUserId: (state) => {
            return (userId) => {
                let userAchievements = state.userAchievements.has(userId) ? state.userAchievements.get(userId) : []
                let userFoundMap = new Map()
                for(let ach of userAchievements){
                    userFoundMap.set(ach.achievement, ach)
                }
                
                let response = []
                state.achievements.forEach((ach)=>{
                    if(userFoundMap.has(parseInt(ach.achievementid))){
                        let userAch = userFoundMap.get(parseInt(ach.achievementid))
                        response.push({
                            earned:true,
                            image: userAch.image,
                            title: userAch.title,
                            id: userAch.achievement
                        })
                    } else {
                        response.push({
                            earned:false,
                            image: ach.silhouette,
                            title: null,
                            id: ach.achievementid
                        })
                    }
                })

                return response
            }
        },    
    }
});