<template>
  <div>
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <SubHeader header="Achievements">
        <template #left>
          <Button @click="backToProfile" icon="pi pi-chevron-left"></Button>
        </template>
        <template #right>
          <Button @click="refreshAchievements" icon="pi pi-refresh"></Button>
        </template>
      </SubHeader>
      <hr />
      <div class="achievements">
        <div class="achievements-grid" v-if="!configStore.getConfig('ff_achievement_shadows', false) && achievementsStore.achievementsByUserId(user.id).length">
          <img v-for="achievement in achievementsStore.achievementsByUserId(user.id)" :src="badgeUrl(achievement)"
            :key="achievement.id" :title="achievement.title" :alt="achievement.title" class="achievement-badge" />
        </div>
        <div class="achievements-grid" v-else-if="configStore.getConfig('ff_achievement_shadows', false) && achievementsStore.fullAchievementsByUserId(user.id).length">
          <img v-for="achievement in achievementsStore.fullAchievementsByUserId(user.id)" :src="badgeUrl(achievement)"
            :key="achievement.id" :title="achievement.title" :alt="achievement.title" class="achievement-badge" />
        </div>
        <div v-else>It seems you don't have any achievements yet - Go explore and discover!</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { useAchievementsStore } from "../stores/achievements";
import { mapStores } from 'pinia'
import Button from "primevue/button";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import SubHeader from "../components/SubHeader.vue";
import { useUsersStore } from '../stores/users';
import { useConfigStore } from "../stores/config";

export default {
  name: "UserAchievements",
  components: {
    Button,
    LoadingIndicator,
    SubHeader
  },
  data() {
    return {
      user: null,
      achievements: [],
      loading: true,
    };
  },
  async created() {
    this.user = await this.usersStore.getUserByName(this.$route.params.username)
    await Promise.all([
      this.achievementsStore.getAchievements(),
      this.achievementsStore.getUserAchievements(this.user.id)
    ])
    console.log(this.achievementsStore.fullAchievementsByUserId(this.user.id))
    this.loading = false;
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useConfigStore),
    ...mapStores(useUsersStore),
    ...mapStores(useAchievementsStore),
  },
  methods: {
    async refreshAchievements() {
      this.loading = true;
      await this.achievementsStore.fetchUserAchievements(this.user.id)
      this.loading = false;
    },
    badgeUrl(achievement) {
      return `/images/achievements/${achievement.image}.png`
    },
    backToProfile() {
      this.$router.push(`/users/${this.user.display_name}`)
    },

  }
};
</script>

<style scoped>
.inventory {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.inventory-header {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  grid-template-areas: "header-left inventory-header-text inventory-header-actions";
}

.inventory-header-actions {
  grid-area: inventory-header-actions;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inventory-header-text {
  grid-area: inventory-header-text;
}

.achievements-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px;
}

.achievement-badge {
  /* width: 100px;
  height: 100px; */
}
</style>
