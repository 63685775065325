<template>
    <div>
        <div>
            <div class="header-text">Cosmic Rares</div>
            <div>* Packs containing only rare cards</div>
        </div>
        <DataTable
            :value="cbSorted"
            responsiveLayout="stack"
            breakpoint="500px"
            :scrollable="true"
            scrollHeight="555px"
            v-model:filters="tableFilter"
            filterDisplay="menu"
            :loading="loading"
        >
            <template #loading>Loading records, please wait...</template>
            <Column
                field="minter"
                header="User"
                :showFilterMatchModes="false"
                :sortable="true"
            >
                <template #body="slotProps">
                    <router-link :to="`/users/${slotProps.data.minter}`">
                        {{ slotProps.data.minter }}
                    </router-link>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="p-column-filter"
                    />
                </template>
            </Column>
            <Column field="cosmicrares" header="Packs">
                <template #body="slotProps">{{
                    slotProps.data.cosmicrares
                }}</template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";

export default {
    name: "LeaderboardsCosmicRaresSummary",
    props: {
        cosmicRares: Array,
        loading: Boolean,
    },
    components: {
        DataTable,
        Column,
        InputText,
    },
    data: () => ({
        tableFilter: {
            minter: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    }),
    computed: {
        cbSorted() {
            return [...this.cosmicRares].sort(
                (a, b) => b.cosmicrares - a.cosmicrares
            );
        },
    },
};
</script>

<style scoped></style>
