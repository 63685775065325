<template>
  <div class="notifications-panel">
    <Button label="Clear All" @click="markAllRead" />
    <div
      class="notification-list"
      v-if="notificationsStore.unread.length === 0"
    >No New Notifications</div>
    <TransitionGroup tag="div" name="list" class="notification-list" v-else>
      <div
        class="notification-line"
        v-for="notification in notificationsStore.unread"
        :key="notification.id"
      >
        <div v-if="notification.type === 'achievement'" class="notification-content">
          <b>New Achievement Earned</b>
          <br />
          <router-link
            :to="`/users/${authStore.profile.display_name}/achievements`"
            @click="markRead(notification)"
          >
            <i>{{ notification.data.title }}</i>
          </router-link>
        </div>
        <div v-else-if="notification.type === 'trade'" class="notification-content">
          <div v-if="notification.data.status === 'pending'">
            <b>New Trade Offer!</b>
            <br />
            <router-link
              :to="`/users/${authStore.profile.display_name}/trades/${notification.data.tradeId}`"
              @click="markRead(notification)"
            >
              <i>{{ `${notification.data.from_display_name} sent you an offer!` }}</i>
            </router-link>
          </div>
          <div v-if="notification.data.status === 'withdrawn'">
            <b>Trade Withdrawn!</b>
            <br />
            <router-link
              :to="`/users/${authStore.profile.display_name}/trades/${notification.data.tradeId}`"
              @click="markRead(notification)"
            >
              <i>{{ `${notification.data.from_display_name} withdrew their trade!` }}</i>
            </router-link>
          </div>
          <div v-else-if="notification.data.status === 'accepted'">
            <b>Trade Accepted!</b>
            <br />
            <router-link
              :to="`/users/${authStore.profile.display_name}/trades/${notification.data.tradeId}`"
              @click="markRead(notification)"
            >
              <i>{{ `${notification.data.to_display_name} accepted your offer!` }}</i>
            </router-link>
          </div>
          <div v-else-if="notification.data.status === 'declined'">
            <b>Trade Declined!</b>
            <br />
            <router-link
              :to="`/users/${authStore.profile.display_name}/trades/${notification.data.tradeId}`"
              @click="markRead(notification)"
            >
              <i>{{ `${notification.data.to_display_name} declined your offer!` }}</i>
            </router-link>
          </div>

          <div v-else-if="notification.data.status === 'canceled'">
            <b>Trade Canceled!</b>
            <br />
            <router-link
              :to="`/users/${authStore.profile.display_name}/trades/${notification.data.tradeId}`"
              @click="markRead(notification)"
            >
              <i>{{ `A trade was canceled!` }}</i>
            </router-link>
          </div>
        </div>
        <div v-else-if="notification.type === 'tradeMessage'" class="notification-content">
          <div>
            <b>New Trade Message!</b>
            <br />
            <router-link
              :to="`/users/${authStore.profile.display_name}/trades/${notification.data.tradeId}`"
              @click="markRead(notification)"
            >
              <i>{{ `${authStore.profile.id === notification.data.from ? notification.data.to_display_name : notification.data.from_display_name} sent you a message!` }}</i>
            </router-link>
          </div>
        </div>
        <div @click="markRead(notification)">
          <i class="pi pi-times-circle"></i>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>

import { useNotificationsStore } from "../stores/notifications";
import { mapStores } from 'pinia'
import Button from "primevue/button";
import { useAuthStore } from '../stores/auth';
export default {
  name: "NotificationsPanel",
  components: {
    Button
  },
  props: {},
  data: () => ({

  }),
  created() {

  },
  methods: {
    markAllRead() {
      this.notificationsStore.readAll()
    },
    markRead(notification) {
      this.notificationsStore.readOne(notification.id)
    },
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useNotificationsStore),
  },
};
</script>

<style scoped>
.notifications-panel {
  width: 380px;
  max-height: 450px;
  font-size: 1rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.notifications-panel::-webkit-scrollbar {
  display: none;
}
.notification-list {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.notification-line {
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
  background-color: var(--green-200);
  color: black;
  padding: 10px 10px;
}

.notification-content {
  width: 100%;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>