<template>
    <div class="duplicate-button">
        <CardSummary
            :cards="duplicates"
            icon="pi-tags"
            v-if="duplicates?.length > 0"
            tooltip="View Duplicates"
        />
    </div>
</template>

<script>
import CardSummary from "./CardSummary.vue";

export default {
    name: "DuplicateIndicator",
    components: {
        CardSummary,
    },
    props: {
        duplicates: {
            type: Array,
            required: true,
        },
    },
    async created() {},
};
</script>

<style scoped>
.duplicate-button {
    position: absolute;
    bottom: -50px;
    z-index: 1;
}
</style>
