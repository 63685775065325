import { defineStore } from "pinia";
import { dangPacksService } from "../services/DangPacksService";

export const usePackOpeningStore = defineStore("packOpening", {
    state: () => {
        return {
            packs: [],
            currentPack: [],
            currentPackObj: null,
            chatters:{},
            packOwners:{}
        }
    },
    actions: {
        /**
         * Fetch packs to open
         */
        async fetchPacksToOpen() {
            try {
                let response = await dangPacksService.getPacksToOpen();
                this.packs = response.data;
                this.packs.forEach(pack => {
                    pack.online = false;
                    this.packOwners[pack.minter.toLowerCase()] = pack.packId;
                });
            } catch (error) {
                console.log(error)
            }
        },
        async updatePacksToOpen() {
            try {
                let response = await dangPacksService.getPacksToOpen();
                let newPacks = response.data.filter(pack => !this.getPackIds.includes(pack.packId));

                //Update new packs for online view
                newPacks.forEach(pack => {
                    pack.online = false;
                    this.packOwners[pack.minter.toLowerCase()] = pack.packId;
                });

                let currentIndex = newPacks.length
                let randomIndex;
                while (currentIndex != 0) {
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;
                    [newPacks[currentIndex], newPacks[randomIndex]] = [newPacks[randomIndex], newPacks[currentIndex]];
                }

                this.packs = this.packs.concat(newPacks);
            } catch (error) {
                console.log(error)
            }
        },
        // Open a pack and return the pack into a current pack obj, also removes the last pack from the packs to open object
        async openPack() {
            try {
                this.currentPackObj = this.packs.shift();
                this.currentPack = []
                let response = await dangPacksService.openPack(this.currentPackObj);
                this.currentPack = response.data;
                this.currentPack.forEach((card) => { card.revealed = false });
            } catch (error) {
                console.log(error)
            }
        },
        shufflePacks() {
            let currentIndex = this.packs.length, randomIndex;

            // While there remain elements to shuffle.
            while (currentIndex != 0) {

                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [this.packs[currentIndex], this.packs[randomIndex]] = [
                    this.packs[randomIndex], this.packs[currentIndex]];
            }
        },
        getFilteredPacksToOpen(string) {
            if (string === null || string === '') { return this.getPacksToOpen }
            return this.packs.filter(pack => pack.minter.toLowerCase().includes(string.toLowerCase())).slice(0, 10)
        },
        moveTop(pack) {
            this.packs = this.packs.filter(p => p.packId !== pack.packId)
            this.packs.unshift(pack)
        },
        moveBottom(pack) {
            this.packs = this.packs.filter(p => p.packId !== pack.packId)
            this.packs.push(pack)
        },
        moveUp(pack) {
            let index = this.packs.findIndex(p => p.packId === pack.packId)
            if (index !== -1 && index - 1 >= 0) {
                [this.packs[index - 1], this.packs[index]] = [this.packs[index], this.packs[index - 1]];
            }
        },
        moveDown(pack) {
            let index = this.packs.findIndex(p => p.packId === pack.packId)
            if (index !== -1 && index + 1 < this.packs.length) {
                [this.packs[index], this.packs[index + 1]] = [this.packs[index + 1], this.packs[index]];
            }
        },

        filterOnlineOnly(){
            this.packs.filter(pack => pack.online).forEach(pack=> this.moveTop(pack));

        },

        addChatter(name,id){
            let username = name.toLowerCase();
            if (!this.chatters[username] && this.packOwners[username]) {
                this.chatters[username] = id;
                let chattersPacks = this.packs.filter(pack => pack.minter.toLowerCase()== username);
                chattersPacks.forEach(pack => {pack.online = true});
            }
        }
    },
    getters: {
        getPacks: (state) => {
            return state.packs;
        },
        getPackIds: (state) => {
            return state.packs.map(pack => pack.packId);
        },
        getCurrentPack: (state) => {
            return state.currentPack;
        },
        haveOpenPack: (state) => state.currentPack.length > 0,
        getPacksToOpen: (state) => {
            //this should get the names of the next packs to open.
            return state.packs.slice(0, 10);
        },
        cardsRevealed: (state) => state.currentPack.reduce((finished, card) => finished && card.revealed, true),
        currentPackOwner: (state) => state.currentPackObj?.minter,
        currentPackOwnerId: (state) => state.currentPackObj?.minter_id,
        currentPackId: (state) => state.currentPackObj?.packId,
    }
});