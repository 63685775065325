import { createApp } from 'vue'
import { createPinia } from 'pinia'
import {createRoutes} from './router'
import App from './App.vue'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLayerGroup, faBoxOpen, faGripHorizontal } from "@fortawesome/free-solid-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import BadgeDirective from 'primevue/badgedirective';

import { useConfigStore } from './stores/config'

const app = createApp(App);
app.use(createPinia())

const config = useConfigStore()
config.fetchConfig().then(()=>{

    const routes = createRoutes()
    app.use(routes);
    
    app.use(PrimeVue, {
        ripple: true,
        inputStyle: 'filled'
    });
    app.use(ToastService);
    app.use(ConfirmationService);
    app.directive('badge', BadgeDirective);
    
    
    library.add(faTwitch, faLayerGroup, faBoxOpen, faGripHorizontal);
    app.component('font-awesome-icon', FontAwesomeIcon)
    
    app.directive('tooltip', Tooltip);
    
    app.mount('#app')
    
})

